import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { mergeMap } from 'rxjs/operators';
import { BrokerCallOff, CreateBrokerCallOff, CreateBrokerCallOffDTO } from 'src/app/models/assignment-children/broker-call-off.model';
import { CustomerCallOff } from 'src/app/models/assignment-children/customer-call-off.model';
import { UpdateSupplierTenderStatus } from 'src/app/models/assignment-children/supplier-tender.model';
import { AssignmentChildrenStatus } from 'src/app/models/status/status.enum';
import { BrokerCallOffService } from 'src/app/services/broker-call-off.service';
import { CustomerCallOffService } from 'src/app/services/customer-call-off.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-create-broker-call-off-modal',
  templateUrl: './create-broker-call-off-modal.component.html',
  styles: [
  ]
})
export class CreateBrokerCallOffModalComponent implements OnInit {
  processing: boolean;
  invalidForm: boolean;
  customerCallOffID: number;
  assignmentChild: any;
  customerCallOff: CustomerCallOff;
  form: FormGroup;
  bco: CreateBrokerCallOff;
  bcoFormInput: CreateBrokerCallOffDTO;
  updateCustomerCallOff: UpdateSupplierTenderStatus;
  public mode: string;
  constructor(private formBuilder: FormBuilder,
    private brokerCallOffService: BrokerCallOffService,
    private customerCallOffService: CustomerCallOffService,
    private userService: UserService,
    public config: DynamicDialogConfig,
    public modalRef: DynamicDialogRef,
    private messageService: MessageService) { }

  ngOnInit(): void {
    this.assignmentChild = this.config.data.assignmentChild;
    this.mode = this.config.data.mode;
    this.processing = true;

    this.form = this.formBuilder.group({
      assignmentID: [0],
      brokerUserID: [0],
      statusID: [0],
      header: [],
      assignmentReference: [""],
      assignmentTypeID: [0, [Validators.required]],
      priceTypeID: [0, [Validators.required]],
      assignmentEstimate: ["", [Validators.required]],
      assignmentUnitID: [0, [Validators.required]],
      multipleResources: [null, []],
      assignmentMaxPrice: ["", [Validators.required]],
      startDate: ["", [Validators.required]],
      endDate: ["", [Validators.required]],
      description: ["", [Validators.required]],
      assignmentLocationTypeID: ["", [Validators.required]],
      assignmentLocation: ["", [Validators.required]],
      users: [[]],
      assignmentTemplateID: [1],
    });

    if (this.mode === 'view') {
      this.form.get('assignmentReference')?.disable();
      this.form.get('header')?.disable();
      this.form.get('assignmentTypeID')?.disable();
      this.form.get('priceTypeID')?.disable();
      this.form.get('assignmentEstimate')?.disable();
      this.form.get('multipleResources')?.disable();
      this.form.get('assignmentMaxPrice')?.disable();
      this.form.get('startDate')?.disable();
      this.form.get('endDate')?.disable();
      this.form.get('assignmentLocationTypeID')?.disable();
      this.form.get('assignmentLocation')?.disable();
    }

    if (this.assignmentChild.assignmentChildType == "CustomerCallOff") {
      this.customerCallOffID = this.assignmentChild.assignmentChildID;
      this.customerCallOffService.getCustomerCallOff(this.assignmentChild.assignmentChildID).subscribe(res => {
        this.customerCallOff = res;
        this.setAssignmentToForm(res.assignment, res.assignment.assignmentMaxPrice);
      })
    }
    else if (this.assignmentChild.assignmentChildType == "BrokerCallOff") {
      this.brokerCallOffService.get<BrokerCallOff>(this.assignmentChild.assignmentChildID).subscribe(res => {
        this.setAssignmentToForm(res.assignment, res.brokerMaxPrice);
      })
    }

    this.processing = false;
  }

  setAssignmentToForm(assignment: any, maxPrice: number) {
    this.form.get('header')?.setValue(assignment.header);
    this.form.get('assignmentReference')?.setValue(assignment.assignmentReference);
    this.form.get('assignmentTypeID')?.setValue(assignment.assignmentTypeID);
    this.form.get('priceTypeID')?.setValue(assignment.priceTypeID);
    this.form.get('assignmentMaxPrice')?.setValue(maxPrice);
    this.form.get('assignmentEstimate')?.setValue(assignment.assignmentEstimate);
    this.form.get('assignmentUnitID')?.setValue(assignment.assignmentUnitID);
    this.form.get('multipleResources')?.setValue(assignment.multipleResources);
    this.form.get('startDate')?.setValue(new Date(assignment.startDate));
    this.form.get('endDate')?.setValue(new Date(assignment.endDate));
    this.form.get('description')?.setValue(assignment.description);
    this.form.get('assignmentLocationTypeID')?.setValue(assignment.assignmentLocationTypeID);
    this.form.get('assignmentLocation')?.setValue(assignment.assignmentLocation);
    this.form.get('brokerID')?.setValue(assignment.brokerID);
    this.form.get('assignmentTemplateID')?.setValue(assignment.assignmentTemplateID);

  }

  get assignmentTypeID(): any {
    return this.form.get('assignmentTypeID');
  }

  onSubmit() {

    if (this.form.invalid) {
      this.invalidForm = true;
      return;
    }

    this.bcoFormInput = this.form.value;
    this.bcoFormInput.assignmentID = this.customerCallOff.assignmentID;
    this.bcoFormInput.brokerUserID = JSON.parse(this.userService.getUserFromLocalStorage()).userID;
    this.bcoFormInput.statusID = AssignmentChildrenStatus.BrokerCallOffDraft;
   
    this.bco = {
      assignmentID: this.customerCallOff.assignmentID,
      brokerUserID: JSON.parse(this.userService.getUserFromLocalStorage()).userID,
      statusID: AssignmentChildrenStatus.BrokerCallOffDraft,
      brokerCallOffPrice: 0
    }
    this.brokerCallOffService.create(this.bcoFormInput).pipe(
      mergeMap(res => {
        this.updateCustomerCallOff = {
          statusId: AssignmentChildrenStatus.BrokerCallOffDraft
        }
        return this.customerCallOffService.update(this.customerCallOffID, this.updateCustomerCallOff);
      })
    ).subscribe(res => {
      console.log(res);
      this.showToast();
    }, error => {
      console.log(error);
    })
    this.onCreate();
  }

  onCreate(): void {
    this.modalRef.close(true);
  }

  onCancel(): void {
    this.modalRef.close(false);
  }

  showToast() {
    this.messageService.add({
      key: 'main',
      severity: 'success',
      detail: 'Ditt annonsutkast har skapats'
    })
  }
}
