import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { OrganizationComponent } from './organization/organization.component';
import { OrganizationsComponent } from './organizations/organizations.component';
import { PrimeModule } from 'src/app/prime-module.ts';
import { UserModule } from '../user/user.module';
import { Paginator, PaginatorModule } from 'primeng/paginator';
import { OrganizationUsersComponent } from './organization-users/organization-users.component';
import { CreateOrganizationComponent } from './create-organization/create-organization.component';
import { OrganizationTemplateComponent } from './organization-templates/organization-template.component';
import { OrganizationTemplateCreateComponent } from './organization-templates/organization-template-create.component';
import { TabMenuModule } from 'primeng/tabmenu';
import { OrganizationCompetenciesComponent } from './organization-competencies/organization-competencies.component';
import { OrganizationSettingsComponent } from './organization-settings/organization-settings.component';
import { CustomerBrokerUserModalComponent } from './customer-broker-user-modal/customer-broker-user-modal.component';
import { OrganizationConsultantsComponent } from './organization-consultants/organization-consultants.component';
import { OrganizationConsultantDetailsComponent } from './organization-consultants/organization-consultant-details/organization-consultant-details.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { OrganizationConsultantInfoComponent } from './organization-consultant-info/organization-consultant-info.component';
import { OrganizationConsultantCompetenciesComponent } from './organization-consultant-competencies/organization-consultant-competencies.component';
import { OrganizationConsultantResumesComponent } from './organization-consultant-resumes/organization-consultant-resumes.component';


@NgModule({
    declarations: [
        OrganizationComponent,
        OrganizationsComponent,
        CreateOrganizationComponent,
        OrganizationUsersComponent,
        OrganizationTemplateComponent,
        OrganizationTemplateCreateComponent,
        OrganizationCompetenciesComponent,
        OrganizationSettingsComponent,
        CustomerBrokerUserModalComponent,
        OrganizationConsultantsComponent,
        OrganizationConsultantDetailsComponent,
        OrganizationConsultantInfoComponent,
        OrganizationConsultantCompetenciesComponent,
        OrganizationConsultantResumesComponent
    ],
    imports: [
        CommonModule,
        PrimeModule,
        PaginatorModule,
        UserModule,
        TabMenuModule,
        PdfViewerModule,
        NgxExtendedPdfViewerModule,

    ],
    exports: [
        OrganizationComponent,
        OrganizationsComponent,
        CreateOrganizationComponent
    ]
})

export class OrganizationModule { }
