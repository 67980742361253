import {Injectable} from "@angular/core";
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Observable, throwError} from "rxjs";
import {AuthService} from "../services/auth.service";
import { catchError } from "rxjs/operators";
import { Router } from "@angular/router";
import { Location } from '@angular/common';
import { DialogService } from "primeng/dynamicdialog";
import { ErrorModalComponent } from "../components/shared/error-modal/error-modal/error-modal.component";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(
        private authService: AuthService,
        private router: Router,
        private dialogService: DialogService,
        private location: Location
        ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const token = this.authService.getTokenFromStorage();

        if (token) {
            const cloned = req.clone({
                headers: req.headers.set("Authorization", "Bearer " + token)
            });

            return next.handle(cloned).pipe(catchError(err => {
                if(err.status === 401) {
                    this.authService.logout();
                    let locationPath = this.location.path();
                    if(!locationPath.includes('login')) {
                        this.router.navigate(['login']);
                    }
                }
                const error = err.statusText;
                return throwError(error);
            }) ) as Observable<HttpEvent<any>>;
        }
        else {
            return next.handle(req);
        }
    }
}