<div *ngIf="isBrokerUser">
    <p-dropdown 
    [options]="customers"
    optionLabel="organizationName"
    [(ngModel)]="selectedOrganizationID"
    (onChange)="onChange()"
    optionValue="organizationID"
    [filter]="true">

    </p-dropdown>
</div>
<div *ngIf="selectedOrganizationID > 0">
    <p-confirmDialog #cd header="Confirmation">
        <ng-template pTemplate="header">
            <h3>Stäng och Avsluta Avrop</h3>
        </ng-template>
        <ng-template pTemplate="footer">
            <button type="button" pButton icon="pi pi-times" label="Avbryt" (click)="cd.reject()"></button>
            <button type="button" pButton icon="pi pi-check" label="Stäng Avrop" (click)="cd.accept()"></button>
        </ng-template>
    </p-confirmDialog>
    <p-card>
        <ng-template pTemplate="header">
            <div class="p-d-flex">
                <div class="p-card-title">
                    Statustavla (Avrop)
                </div>
            </div>
            
        </ng-template>
        
        <div class="board">
            <div cdkDropListGroup class="board-container">
                <div class="p-d-flex">
                    <div *ngFor="let lane of lanes;let indexOfelement=index;" class="board-column p-mr-2"
                        [style.opacity]="showLane[indexOfelement] ? '100%' : '40%'">
                        <div class="board-column-header" [style.borderColor]="showLane[indexOfelement] ? 'grey' : 'red'" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis">
                            <div class="p-d-flex p-ai-center card">
                                <span>{{lane.statusInfo.statusLaneName}}</span>
                                <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text"
                                        pTooltip="{{lane.statusInfo.description}}"></i>
                                </span>
                                <span class="p-ml-auto" *ngIf="lane.statusInfo.isNotification">
                                    <i class="pi pi-bell"></i>
                                </span>
                            </div>
                        </div>
                        <div *ngIf="lane.assignmentChildren" cdkDropList [cdkDropListData]="lane.assignmentChildren"
                            (cdkDropListDropped)="onDrop($event, lane.statusInfo.statusID)" class="board-column-content">
                            <div cdkDrag (cdkDragStarted)="onDragStarted(assignmentChild)" *ngFor="
                                    let assignmentChild of lane.assignmentChildren
                                    let index" class="board-column-item" style="cursor: pointer;">
                                <div class="board-column-item-header">
                                    <div class="p-d-flex p-jc-between">
                                        <div class="board-column-item-text">
                                            <i class="pi pi-book"></i>
                                            <span class="p-pl-2" (click)="viewCalloff(assignmentChild)"><b>{{assignmentChild.assignmentChildID}}</b>
                                                {{assignmentChild.header}}</span>
                                        </div>
                                        <div class="board-column-item-text">
                                            <p-menu #menu [popup]="true" [model]="items"></p-menu>                                          
                                            <div class="board-hover-item board-column-contextmenu"
                                                (click)="menu.toggle($event); selectedAssignmentChild=assignmentChild;"><i
                                                    class="pi pi-ellipsis-h"></i></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="board-column-item-content">
                                    <div class="board-column-item-text">
                                        <div class="p-grid">
                                            <div class="p-col-6">Referens: </div>
                                            <div class="p-col" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis">{{assignmentChild.assignmentReference}}</div>
                                        </div>
                                        <div class="p-grid">
                                            <div class="p-col-6">Datum: </div>
                                            <div class="p-col" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis">{{assignmentChild.created | date: 'dd/MM/yyyy'}} </div>
                                        </div>
                                    </div>
                                    <div class="board-column-item-text">
                                    </div>
                                </div>
                                <div class="board-column-item-footer" *ngIf="assignmentChild.showFooter">
                                    <div class="board-column-item-text">
                                        <div class="p-grid">
                                        </div>
                                    </div>
                                    <div *ngIf="assignmentChild.showFooter" class="p-d-flex p-jc-center">
                                        <div class="board-hover-item" (click)="toggleFooter(assignmentChild)">
                                            <i class="pi pi-chevron-up"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </p-card>
</div>
