import { Component, OnInit } from '@angular/core';
import {MenuItem} from 'primeng/api';
import { AuthService } from "../../../services/auth.service";
import { UserAuthenticateResponse } from 'src/app/models/user/user-authenticate-response.model';

@Component({
  selector: 'app-organization',
  templateUrl: './organization-component.html',
})
export class OrganizationComponent implements OnInit {

  items: MenuItem[];
  scrollableItems: MenuItem[];
  activeItem: MenuItem;
  state: String;
  organizationId: number;

  user: UserAuthenticateResponse;

  constructor(public authService: AuthService) { }

  ngOnInit() {

    this.user = this.authService.getUserFromStorage();
    console.log(this.user);

    this.organizationId = Number(this.user.organizationID);
    console.log(this.organizationId);

    this.state = 'editOrg';
    this.items = [
        {
          label: 'Ändra', 
          //icon: 'pi pi-fw pi-pencil',
          command: () => this.state = 'editOrg'
        },
        {
          label: 'Inställningar',
          //icon: 'pi pi-fw pi-cog',
          command: () => this.state = 'settings'

          },
        {
          label: 'Kompetenser',
          //icon: 'pi pi-fw pi-home'
          command: () => this.state = 'competencies'
        },
        /*{label: 'Documentation', icon: 'pi pi-fw pi-file'},
        {label: 'Calendar', icon: 'pi pi-fw pi-calendar'}*/
    ];
    
    this.activeItem = this.items[0];
  }
}
