import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BrokerCallOff } from 'src/app/models/assignment-children/broker-call-off.model';
import { BrokerCallOffService } from 'src/app/services/broker-call-off.service';

@Component({
  selector: 'app-broker-call-off',
  template: '',
  styles: [
  ]
})

export class BrokerCallOffComponent implements OnInit {
brokerCallOffId: number;
brokerCallOff: BrokerCallOff;

  constructor(
    private brokerCallOffService: BrokerCallOffService,
    private route: ActivatedRoute,
    private router: Router
    ) { }

    ngOnInit(): void {
      this.route.params.subscribe(params => {
        this.brokerCallOffId = params.brokerCallOffID;
        this.brokerCallOffService.get<BrokerCallOff>(this.brokerCallOffId).subscribe(res => {
          this.brokerCallOff = res;
          this.createSupplierTenderRedirect();
        });
      });
    }

  createSupplierTenderRedirect() {
    this.router.navigate(['supplier-tender-create/' + this.brokerCallOff.assignmentID+'/' + this.brokerCallOff.brokerCallOffID]);
  }
}
