<div class="p-grid p-justify-center">
    <div class="p-col-12 p-lg-7">
        <p-card [header]="selectedUserId ? 'Redigera Användare' : 'Skapa Användare'">
            <form [formGroup]="userForm" (ngSubmit)="onCreateClicked()">
                <div class="p-mt-4">
                    <p class="p-text-bold">Konto</p>
                    <p-divider></p-divider>
                </div>
                <div *ngIf="isBroker && state ==='create'" class="p-fluid p-formgrid p-grid p-mt-5 p-mb-1">
                    <div class="p-field p-col">
                        <label for="organizationType">Organisationtyp
                            <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px"
                                pTooltip="Välj en organisationstyp för användaren som skapas"></i>
                            </span>
                        </label>
                        <select (change)="onSelectedType($event)" class="p-inputtext" style="appearance: auto">
                                <option selected disabled></option>
                                <option [value]=1>Mäklare</option>
                                <option [value]=2>Kund</option>
                                <option [value]=3>Leverantör</option>
                                <option [value]=4>Kund och Leverantör</option>
                        </select>
                    </div>
                    <div class="p-field p-col">
                        <label for="organizationID">Organisation *
                            <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px"
                                pTooltip="Välj organisation som användaren tillhör"></i>
                            </span>
                        </label>
                        <select (change)="onChange($event)" class="p-inputtext" style="appearance: auto">
                                <option *ngFor="let item of organizations" value={{item.organizationID}}>{{item.organizationName}}</option>
                        </select>
                    </div>
                </div>              
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col">
                        <label for="firstName">Förnamn *
                            <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px"
                                pTooltip="Användarens förnamn"></i>
                            </span>
                        </label>
                        <input id="firstName" formControlName="firstName" type="text" [disableControl]="processing" pInputText>
                    </div>
                    <div class="p-field p-col">
                        <label for="lastName">Efternamn *
                            <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px"
                                pTooltip="Användarens efternamn"></i>
                            </span>
                        </label>
                        <input id="lastName" formControlName="lastName" type="text" [disableControl]="processing" pInputText>
                    </div>
                </div>
                <div class="p-fluid p-grid">
                    <div class="p-field p-col-6">
                        <label for="jobTitle">Titel 
                            <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px"
                                pTooltip="Användarens titel inom organisationen"></i>
                            </span>
                        </label>
                        <input id="title" formControlName="title" type="text" [disableControl]="processing" pInputText>
                    </div>
                </div>
                <div class="p-fluid p-grid">
                    <div class="p-field p-col-6">
                        <label for="email">E-postadress *
                            <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px"
                                pTooltip="Användarens e-postaddress och användarnamn för inloggning"></i>
                            </span>
                        </label>
                        <input id="email" formControlName="email" type="text" [disableControl]="processing" pInputText>
                    </div>
                </div>
                <div class="p-fluid p-grid" *ngIf="state==='create'">
                    <div class="p-field p-col-6">
                        <label for="password">Lösenord *
                            <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px"
                                pTooltip="Ange ett säkert lösenord"></i>
                            </span>
                        </label>
                        <p-password formControlName="password" [disableControl]="processing"
                                    id="password"></p-password>
                    </div>
                </div>
                <div class="p-fluid p-grid" *ngIf="state==='create'">
                    <div class="p-field p-col-6">
                        <label for="passwordConfirm">Bekräfta lösenord *
                            <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px"
                                pTooltip="Bekräfta det ovanstående lösenordet"></i>
                            </span>
                        </label>
                        <p-password formControlName="passwordConfirm" [disableControl]="processing"
                                    id="passwordConfirm"></p-password>
                        <small *ngIf="userForm.hasError('mismatch')" class="p-error">Lösenorden matchar ej</small>
                    </div>
                </div>
                <div class="p-mt-4">
                    <p class="p-text-bold">Övrigt</p>
                    <p-divider></p-divider>
                </div>
                <div class="p-fluid p-grid p-mt-4">
                    <div class="p-field p-col-6">
                        <label for="phoneNumber">Telefonnummer 
                            <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px"
                                pTooltip="Telefonnummer till användaren"></i>
                            </span>
                        </label>
                        <input id="phoneNumber" formControlName="phoneNumber" type="text" [disableControl]="processing" pInputText>
                    </div>
                </div>
                <div class="p-fluid p-grid">
                    <div class="p-field p-col-6">
                        <div class="field-checkbox">
                            <input type="checkbox" value="true" id="isActivated" formControlName="isActivated"
                                [disableControl]="processing">
                            <label for="activated">Aktiverad</label>
                        </div>
                    </div>
                </div>
                <div class="p-mt-2">
                    <small *ngIf="invalidForm" class="p-error">Misslyckades skapa användare. Kontrollera fälten</small>
                </div>
                <div class="p-grid p-mt-4">
                    <div class="p-col-6">
                        <button pButton
                                type="button"
                                icon="pi pi-chevron-left"
                                class="p-button-secondary p-button-raised p-text-uppercase" label="Tillbaka"
                                [disabled]="processing"
                                (click)="onBackClicked()"></button>
                    </div>
                    <div class="p-col-6 p-text-right" *ngIf="state==='create'">
                        <button pButton
                                type="submit"
                                
                                iconPos="right"
                                class="p-button p-button-raised p-text-uppercase"
                                label="Skapa"
                                [disabled]="processing"></button>
                        
                    </div>
                    <div class="p-col-6 p-text-right" *ngIf="state==='edit'">
                    <button pButton
                                type="submit"
                                
                                iconPos="right"
                                class="p-button p-button-raised p-text-uppercase"
                                label="Spara"
                                [disabled]="processing"></button>
                            </div>
                </div>
                <div class="p-mt-4" [hidden]="!processing">
                    <p-progressBar mode="indeterminate"></p-progressBar>
                </div>
            </form>
        </p-card>
    </div>
</div>