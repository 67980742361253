import { HttpBackend, HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class UtilService {

private http: HttpClient

    constructor(
        private handler: HttpBackend
        
        ) {
            this.http = new HttpClient(handler);
        }

    getAccount(): Observable<any>{
        return this.http.get(environment.accountJSON);
    }
} 