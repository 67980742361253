import { NotificationService } from './../../../services/notification.service';
import { NotificationReceiverDTO, Notification } from './../../../models/notification/notification.model';
import { FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { AssignmentChildren, Region } from 'src/app/models/assignment-children/assignment-children.model';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FormGroup } from '@angular/forms';
import { OrganizationService } from 'src/app/services/organization.service';
import { forkJoin, Observable } from 'rxjs';
import { AssignmentService } from 'src/app/services/assignment.service';
import { generateContentString } from 'src/app/utils/template-string-replace';
import { MessageService } from 'primeng/api';
import { ErrorModalComponent } from '../../shared/error-modal/error-modal/error-modal.component';
import { getTemplateFooter } from 'src/app/utils/template-footer';
import { UtilService } from 'src/app/services/util.service';
import { SharedResume } from 'src/app/models/resume/resume.model';
import { Receiver } from 'src/app/models/receiver/receiver.model';
import { mergeMap } from 'rxjs/operators';
import { BrokerCallOffService } from 'src/app/services/broker-call-off.service';
import { UpdateBrokerCallOff } from 'src/app/models/assignment-children/broker-call-off.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-email-modal',
  templateUrl: './email-modal.component.html',
  styles: [
  ]
})

export class EmailModalComponent implements OnInit {
  eventID: number;
  notificationReceivers: any[];
  notificationSubject: string;
  notificationContent: string;
  recipients: any;
  sender: any;
  assignmentChild: AssignmentChildren;
  form: FormGroup;
  processing: boolean;
  nrs: NotificationReceiverDTO[];
  required: boolean;
  account: any;
  sharedResume: SharedResume;
  enterReceivers: boolean;
  supplierContactEmail: string;

  // resumes: Resume[];
  pdfSrc: Blob;
  assignmentChildID: number;
  receivers: Receiver[];
  newItem: string;
  supplierTenderID: number;
  brokerTenderID: number;
  showEmailForm: boolean;
  selectedRegionID: number;
  invalidRegion: boolean;
  newRegionID: any;

  constructor(
    public config: DynamicDialogConfig,
    private formBuilder: FormBuilder,
    public modalRef: DynamicDialogRef,
    private notificationService: NotificationService,
    private organizationService: OrganizationService,
    private assignmentService: AssignmentService,
    private messageService: MessageService,
    private dialogService: DialogService,
    private utilService: UtilService,
    private brokerCallOffService: BrokerCallOffService) {

  }

  ngOnInit(): void {
    this.enterReceivers = false;

    if (this.config.data.sharedResume !== undefined) {
      this.processing = false;
      this.sharedResume = this.config.data.sharedResume;
      this.enterReceivers = true;

      // this.resumes = this.config.data.resumes;
      this.receivers = [];
      // this.assignmentChildID = this.config.data.assignmentChildID;
      // this.brokerTenderID = -1;
      // this.supplierTenderID = this.assignmentChildID;
      // this.showEmailForm = false;

    }

    let requests: Observable<any>[] = [];
    if (this.config.data.eventID !== undefined) {
      requests.push(this.organizationService.getOrganizationTemplateWithEventID(this.config.data.eventID))
    }
    if (this.config.data.assignmentID !== undefined) {
      requests.push(this.assignmentService.getResponsibleUsers(this.config.data.assignmentID))
    }
    forkJoin(requests).subscribe(res => {
      this.utilService.getAccount().subscribe(res => {
        this.account = res;
      })
      this.notificationContent = res[0].contentString;
      if (this.config.data.mailType === 'broker') {
        this.recipients = {
          email: res[1].responsibleBrokerUser.email,
          firstName: res[1].responsibleBrokerUser.fullName,
          lastName: ""
        }
        this.sender = res[1].responsibleCustomerUser
      }
      else if (this.config.data.mailType === 'customer') {
        this.recipients = {
          email: res[1].responsibleCustomerUser.email,
          firstName: res[1].responsibleCustomerUser.fullName,
          lastName: ""
        }
        this.sender = res[1].responsibleBrokerUser
      }
      else if (this.config.data.mailType === 'supplier') {
        this.recipients = {
          email: this.config.data.supplierContactEmail,
          firstName: this.config.data.supplierContactFullName,
          lastName: ""
        }
        this.sender = res[1].responsibleBrokerUser
      }

      var params = [
        { param: 'responsible_person', value: this.sender.fullName },
        { param: 'responsible_email', value: this.sender.email },
        { param: 'responsible_phone', value: this.sender.phoneNumber },
        { param: 'request_customer', value: res[1].responsibleCustomerUser.organizationName },
        { param: 'request_title', value: this.config.data.assignmentChild.header },
        { param: 'offer_supplier', value: this.config.data.assignmentChild.supplierOrganizationName },
        // {param: 'information_text', value:this.form.get('interviewtime')},
        { param: 'website_requests_url', value: `<a href='${environment.publishedCallOffsUrl}'>Uppdrag</a>` },
        { param: 'brm_link', value: `<a href="${environment.clientUrl}Call-off-board/"${res[1].responsibleCustomerUser.organizationID}">Länk</a>` },
        { param: 'brm_banner', value: `<p><img src='https://brmngversion.blob.core.windows.net/images/swcg-email-banner.png'></p>` },
        { param: 'brm_footer', value: getTemplateFooter() }
      ];

      this.notificationContent = generateContentString(this.notificationContent, params);
      var re = 'responsible_person';
      this.notificationContent = this.notificationContent.replace(re, this.sender.email);

      this.required = false;
      this.processing = true;
      this.eventID = this.config.data.eventID;

      var nr: NotificationReceiverDTO = {
        notificationReceiverFirstName: this.recipients.firstName,
        notificationReceiverLastName: this.recipients.lastName,
        notificationReceiverEmail: this.recipients.email
      };
      this.notificationReceivers = [nr];
      this.notificationSubject = this.config.data.notificationSubject;

      this.assignmentChild = this.config.data.assignmentChild;
      if (this.config.data.required == true) {
        this.required = true;
      }

      if (this.required) {
        this.form = this.formBuilder.group({
          recipients: [''],
          notificationSubject: [this.notificationSubject, [Validators.required]],
          notificationContent: [this.notificationContent, [Validators.required]],
          interviewtime: ['', [Validators.required]]
        });
      }

      else {
        this.form = this.formBuilder.group({
          recipients: [''],
          notificationSubject: [this.notificationSubject, [Validators.required]],
          notificationContent: [this.notificationContent, [Validators.required]],
          interviewtime: [''],
        });
      }



      this.processing = false;
    })
  }

  get interviewtime() {
    return this.form.get('interviewtime');
  }

  onSubmit(): void {
    if (this.eventID === 4) {
      if (!this.selectedRegionID) {
        this.dialogService.open(ErrorModalComponent,
          {
            header: "Fel: Region saknas",
            data: {
              message: "Du måste välja region. Vänligen försök igen."
            }
          }
        )
        this.onCancel();
        this.invalidRegion = true;
      }
      else {
        this.newRegionID = {
          "RegionID": Number(this.selectedRegionID)
        };
        this.assignmentService.updateAssignment(this.assignmentChild.assignmentID, this.newRegionID).pipe(
          mergeMap((res: any) => {
            return this.brokerCallOffService.update<UpdateBrokerCallOff>(this.assignmentChild.assignmentChildID, this.newRegionID)
          })
        ).subscribe(res => {

        })
      }
    }

    if (!this.invalidRegion) {
      var subject = this.form.get('notificationSubject');
      var content = this.form.get('notificationContent');
      if (this.required) {
        content?.setValue(content?.value.replace("{information_text}", this.interviewtime?.value))
      }
      var notifications = [];

      for (let i = 0; i < this.notificationReceivers.length; i++) {
        var nr: NotificationReceiverDTO = {
          notificationReceiverFirstName: this.recipients.firstName,
          notificationReceiverLastName: this.recipients.lastName,
          notificationReceiverEmail: this.recipients.email
        };
        notifications.push(nr);
      }

      if (subject && content) {
        const notification: Notification = {
          notificationSubject: subject.value,
          notificationContent: content.value,
          notificationData: {
            notificationContent: "testContent"
          },
          notificationReceivers: notifications,
        }
        // if(this.required && (this.form.get('interviewtime')!=null)){
        //   notification.notificationContent = `${this.form.value.interviewtime} \n ${content.value}`;
        // }else{
        //   alert("Lägg till intervjutider");
        //   return;
        // }

        this.notificationService.postNotification(notification).subscribe(res => {
          if (res == true) {
            this.showEmailSent();
            this.onCreate();
          } else {
            this.dialogService.open(ErrorModalComponent,
              {
                header: "Något gick fel",
                data: {
                  message: "Epostutskick misslyckades. Vänligen försök igen."
                }
              }
            )
            this.onCancel();
          }
        });
      }
    }
  }
  onCreate(): void {
    this.modalRef.close(true);
  }

  onCancel(): void {
    this.modalRef.close(false);
  }

  showEmailSent() {
    this.messageService.add({
      key: 'main',
      severity: 'success',
      detail: "Epost har skickats"
    })
  }
  addItem(email: string) {
  }

}
