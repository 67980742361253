<div class="p-grid p-justify-center">
    <div [class]="passedOrganizationId ? 'p-col-12 p-lg-12' : 'p-col-12 p-lg-7'">
        <p-card [header]="header">
            <form [formGroup]="form" (ngSubmit)="onCreateClicked()">
                <div class="p-mt-4">
                    <p class="p-text-bold">Konto</p>
                    <p-divider></p-divider>
                </div>
                <div class="p-fluid p-grid">
                    <div class="p-field p-col-6">
                        <ng-container *ngIf="!isSupplier && isBrokerUser">
                            <label for="organizationType">
                                Organisationtyp *
                                <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px"
                                    pTooltip="Välj den organisationstyp som ska skapas"></i>
                                </span>                               
                            </label>
                            <select id="organizationType" formControlName="organizationType"
                                [disableControl]="processing" class="p-inputtext" style="appearance: auto">
                                <option></option>
                                <option value="1">Broker</option>
                                <option value="2">Customer</option>
                                <option value="3">Supplier</option>
                                <option value="4">CustomerSupplier</option>
                            </select>
                        </ng-container>
                        <ng-container *ngIf="isSupplier">
                            <label for="organizationType">Organisationtyp *</label>
                            <select id="organizationType" formControlName="organizationType"
                                [disableControl]="processing" class="p-inputtext" style="appearance: auto">
                                <option value="3">Supplier</option>
                            </select>
                        </ng-container>

                        <ng-container *ngIf="isBrokerUser && customerOrganizationType">
                            <label for="responsibleBroker">Ansvarig mäklare: 
                                <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px;"
                                    pTooltip="Användaren hos Swedish Consulting Group som är ansvarig för kund"></i>
                                </span>
                            </label>
                            <input id="responsibleBroker" [attr.disabled]="true" formControlName="responsibleBroker" type="text" [disableControl]="processing" pInputText rea>
                            <div *ngIf="userIsAdmin" class="p-grid p-mt-4">
                                <div class="p-col-4 p-text-left">
                                    <button pButton type="button" label="Ändra"
                                    class="p-button p-button-raised p-text-uppercase"
                                    (click)="onEditResponsibleBrokerClicked()">
                                    </button>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
                <div class="p-fluid p-grid">
                    <div class="p-field p-col-6">
                        <label for="name">
                            Namn *
                            <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px;"
                                pTooltip="Namn på organisationen"></i>
                            </span>
                        </label>
                        <input id="name" formControlName="name" type="text" [disableControl]="processing" pInputText>
                    </div>
                </div>
                <div class="p-fluid p-grid">
                    <div class="p-field p-col-6">
                        <label for="organizationNumber">
                            Organisationnummer *
                            <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px"
                                pTooltip="Identitetsbeteckning för organisationen"></i>
                            </span>
                        </label>
                        <input id="organizationNumber" formControlName="organizationNumber" type="text"
                            [disableControl]="processing" pInputText>
                    </div>
                </div>
                <div class="p-fluid p-grid">
                    <div class="p-field p-col-6">
                        <label for="description">
                            Beskrivning *
                            <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px"
                                pTooltip="Beskrivning av organisationen"></i>
                            </span>
                        </label>
                        <textarea id="description" formControlName="description" [disableControl]="processing"
                            pInputText> </textarea>
                    </div>
                </div>
                <div class="p-fluid p-grid">
                    <div class="p-field p-col-6">
                        <label for="email">E-post *
                            <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px"
                                pTooltip="Organisationens e-postadress"></i>
                            </span>
                        </label>
                        <input id="email" formControlName="email" type="text" [disableControl]="processing" pInputText>
                    </div>
                </div>
                <div class="p-fluid p-grid">
                    <div class="p-field p-col-6">
                        <label for="phoneNumber">Telefonnummer *
                            <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px"
                                pTooltip="Organisationens telefonnummer"></i>
                            </span>
                        </label>
                        <input id="phoneNumber" formControlName="phoneNumber" type="text" [disableControl]="processing"
                            pInputText>
                    </div>
                </div>
                <div class="p-fluid p-grid">
                    <div class="p-field p-col-6">
                        <label for="website">Hemsida *
                            <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px"
                                pTooltip="Organisationens hemsida"></i>
                            </span>
                        </label>
                        <input id="website" formControlName="website" type="text" [disableControl]="processing"
                            pInputText>
                    </div>
                </div>
                <div class="p-fluid p-grid">
                    <div class="p-field p-col-6">
                        <div class="field-checkbox">
                            <input type="checkbox" [value]="true" id="activated" formControlName="activated"
                                [disableControl]="processing">
                            <label for="activated">Aktiverad</label>
                        </div>
                    </div>
                </div>

                <div class="p-mt-2">
                    <small *ngIf="invalidForm" class="p-error">Misslyckades skapa organisation. Kontrollera fälten</small>
                </div>
                <div class="p-grid p-mt-4">
                    <ng-container *ngIf="!isSupplier">
                        <div class="p-col-6">
                            <!-- <button pButton type="button" icon="pi pi-chevron-left"
                                class="p-button-secondary p-button-raised p-text-uppercase" label="Tillbaka"
                                [disabled]="processing" (click)="onBackClicked()"></button> -->
                        </div>
                    </ng-container>
                    <div class="p-col-6 p-text-right">
                        <button pButton type="submit" iconPos="right"
                            class="p-button p-button-raised p-text-uppercase"
                            [label]="buttonLabel"
                            [disabled]="processing"></button>
                    </div>
                </div>
                <div class="p-mt-4" [hidden]="!processing">
                    <p-progressBar mode="indeterminate"></p-progressBar>
                </div>
            </form>
        </p-card>
    </div>
</div>