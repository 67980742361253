import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { PrimeModule } from "src/app/prime-module.ts";
import { OrganizationModule } from "../../organization/organization.module";
import { SharedComponentsModule } from "../../shared/shared-components.module";
import { CreateSupplierTenderComponent } from "./create-supplier-tender/create-supplier-tender.component";
import { SupplierTenderListComponent } from './supplier-tender-list/supplier-tender-list.component';



@NgModule({
  declarations: [
  

    SupplierTenderListComponent
  ],
  imports: [
     PrimeModule,
     CommonModule,
     OrganizationModule,
     SharedComponentsModule
  ],
  exports: [
     
  ]
})

export class SupplierTenderModule {}