import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { DynamicDialogRef } from "primeng/dynamicdialog";
import { DynamicDialogConfig } from "primeng/dynamicdialog";
import { UserService } from "src/app/services/user.service";
import { User } from 'src/app/models/user/user.model';
import { Role } from "src/app/models/role/role.enum";

@Component({
  selector: "app-update-user-role",
  templateUrl: "./update-user-role.component.html",
})
export class UpdateUserRoleComponent implements OnInit {
  @Output() updateSuccess = new EventEmitter<any>();
  user: User | any;
  updatersOwnRole: number;
  roles: Array<BasicRole> = [];
  selectedRole: BasicRole;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private userService: UserService
  ) {}

  ngOnInit() {
    if (!this.config.data) return;
    this.user = this.config.data.user;
    this.updatersOwnRole = this.config.data.updatersOwnRole;
    for (let i = 0; i < Object.values(Role).length; i += 2) {
      if (Object.values(Role)[i] <= this.updatersOwnRole) {
        this.roles.push({ id: Object.values(Role)[i], name: Object.values(Role)[i + 1] } as BasicRole);
      }
    }
    this.selectedRole = { id: this.user.roleIDs, name: Role[this.user.roleIDs] } as BasicRole;
  }

  okClicked() {
    const oldRole = this.user.roleIDs;
    this.user.roleIDs = [];
    this.user.roleIDs[0] = this.selectedRole.id;
    if (oldRole === this.selectedRole.id) {
      this.ref.close(this.user);
      return;
    }
    this.userService.updateUserToRole(this.user.userID, this.selectedRole.id).subscribe(
      (success: any) => {
        this.updateSuccess.emit({ success: true });
        this.ref.close(this.user);
      },
      (error) => {
        this.updateSuccess.emit({ success: false });
        this.ref.close(this.user);
      }
    );
  }
}

interface BasicRole {
  id: number,
  name: string
}