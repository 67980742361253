<div class="p-grid p-justify-center">
    <div class="p-col-12 ">
<p-card header="Konsulter">
    <p-table [value]="consultants" sortMode="multiple" (onLazyLoad)="onLoad($event)" 
    [paginator]="true" [rows]="size" [totalRecords]="records">
        <ng-template pTemplate="header">
            <tr>
                <th pSortableColumn="firstName">
                    Namn
                    <p-sortIcon field="firstName"></p-sortIcon>
                </th>
                <th pSortableColumn="email">
                    Epost
                    <p-sortIcon field="email"></p-sortIcon>
                </th>
                <th pSortableColumn="phoneNumber">
                    Telefonnummer
                    <p-sortIcon field="phoneNumber"></p-sortIcon>
                </th>
                <th></th>
            </tr>
            <tr>
                <th>
                    <p-columnFilter 
                    type="text"
                    field="firstName"
                    [matchModeOptions]="tableSettings.defaultTextMatchModes" [matchMode]="'contains'"></p-columnFilter>
                </th>
                <th>
                    <p-columnFilter 
                    type="text"
                    field="email"
                    [matchModeOptions]="tableSettings.defaultTextMatchMode" [matchMode]="'contains'"></p-columnFilter>
                </th>
                <th>
                    <p-columnFilter 
                    type="text"
                    field="phoneNumber"
                    [matchModeOptions]="tableSettings.defaultTextMatchMode" [matchMode]="'contains'"></p-columnFilter>
                </th>
                <th></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-consultants>
            <tr>
                <td>{{consultants.firstName + " " + consultants.lastName}}</td>
                <td>{{consultants.email}}</td>
                <td>{{consultants.phoneNumber}}</td>
                <td>
                    <button
                        pButton
                        label="Visa Detaljer"
                        (click)="onEditConsultantClicked(consultants.consultantID)"
                    ></button>
                </td>
            </tr>
        </ng-template>
    </p-table>
    




