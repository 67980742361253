import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CreateOrganization } from "../models/organization/create-organization.model";
import { OrganizationList } from "../models/organization/organization-list.model";
import { Organization } from "../models/organization/organization.model";
import { UpdateOrganization } from "../models/organization/update-organization.model";
import { BaseService } from "./base.service";

@Injectable({
  providedIn: "root",
})

export class CustomerService extends BaseService<Organization, OrganizationList, CreateOrganization, UpdateOrganization> {
  
  getCustomer(organizationTypeId: number): Observable<Organization[]> {
    return this.http.get<Organization[]>(
      `organization/dropdown/?userTypeID=${organizationTypeId}`
    );
  }

  updateCustomer(customerID: number,updateCustomer: any): Observable<any> {
    return this.http.put(`customer/${customerID}`, updateCustomer);
  }
}
