import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, retry } from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class BaseService<TEntity, TEntityList, TCreateEntity, TUpdateEntity> {

    public endpointBaseURL = '';
    public retry = 0;

    constructor(public http: HttpClient) { }

    get<TEntity>(id: number): Observable<TEntity> {
        return this.http.get<TEntity>(`${this.endpointBaseURL}/${id}`)
            .pipe(
                retry(this.retry),
                catchError(this.handleError)
            );
    }

    getAll<TEntityList>(pageIndex: number = 1, pageSize: number | null = null): Observable<TEntityList> {
        const pageSizeQuery = `${pageSize != null ? `&pageSize=${pageSize}` : ''}`;
        return this.http.get<TEntityList>(`${this.endpointBaseURL}?pageIndex=${pageIndex}${pageSizeQuery}`)
            .pipe(
                retry(this.retry),
                catchError(this.handleError)
            );
    }

    getAllFiltered<TEntityList>(filters: any, pageIndex: number = 1, pageSize: number | null = null): Observable<TEntityList> {
        const pageSizeQuery = `${pageSize != null ? `&pageSize=${pageSize}` : ''}`;
        return this.http.post<TEntityList>(
            `${this.endpointBaseURL}/filtered?pageIndex=${pageIndex}${pageSizeQuery}`, filters)
            .pipe(
                retry(this.retry),
                catchError(this.handleError)
            );
    }

    create<TCreateEntity>(entity: TCreateEntity): Observable<TEntity> {
        return this.http.post<TEntity>(`${this.endpointBaseURL}`, entity)
            .pipe(
                retry(this.retry),
                catchError(this.handleError)
            );
    }

    update<TUpdateEntity>(id: number, entity: TUpdateEntity): Observable<TEntity> {
        return this.http.put<TEntity>(`${this.endpointBaseURL}/${id}`, entity)
            .pipe(
                retry(this.retry),
                catchError(this.handleError)
            );
    }

    delete(id: number): Observable<any> {
        return this.http.delete(`${this.endpointBaseURL}/${id}`, {})
            .pipe(
                retry(this.retry),
                catchError(this.handleError)
            );
    }

    handleError(error: any) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else if (typeof error.status != 'undefined') {
            // Get server-side error
            errorMessage = `Error code: ${error.status}\nMessage: ${error.message}`;
        }
        else {
            errorMessage = error;
        }
        return throwError(errorMessage);
    }
}