import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {RoleService} from "../../../services/role.service";

@Component({
  selector: 'app-create-role',
  templateUrl: './create-role.component.html',
  styles: [
  ]
})
export class CreateRoleComponent implements OnInit {

  processing: boolean;
  invalidForm: boolean;


  createRoleForm: FormGroup;


  constructor(private router: Router, private formBuilder: FormBuilder, private roleService: RoleService) {

  }

  ngOnInit() {
    this.createRoleForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(2)]]
    });
  }

  onCreateRole() {
    this.invalidForm = false;

    if(this.processing) {
      return;
    }

    this.processing = true;

    if(this.createRoleForm.invalid) {
      this.invalidForm = true;
      this.processing = false;
      return;
    }

    this.processing = true;

    this.roleService.create(this.createRoleForm.value)
        .subscribe(role => {
          this.processing = false;
          this.router.navigate([`/role/edit/${role.id}`])
        }, error => {
          this.processing = false;
        });
  }

    onBackClicked() {
      this.router.navigate(['/roles']);
    }
}
