import { Component,Inject, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html',
  styles: [
    `h2 {
    color: red;
    }`
  ]
})
export class ErrorModalComponent implements OnInit {

  constructor(public config: DynamicDialogConfig, public modalRef: DynamicDialogRef) { }

  ngOnInit(): void {
    console.log(this.config.data);
  }

  onOK(): void {
    this.modalRef.close(true);
  }

}
