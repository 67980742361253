import { Injectable } from "@angular/core";
import { Observable } from "rxjs/internal/Observable";
import { CreateOrganization } from "../models/organization/create-organization.model";
import { OrganizationList } from "../models/organization/organization-list.model";
import { createOrganizationTemplate, OrganizationTemplate, updateOrganizationTemplate } from "../models/organization/organization-template.model";
import { Organization } from "../models/organization/organization.model";
import { UpdateOrganization } from "../models/organization/update-organization.model";
import { BaseService } from "./base.service";

@Injectable({
  providedIn: 'root'
})

export class OrganizationService extends BaseService<Organization, OrganizationList, CreateOrganization, UpdateOrganization>
{
  endpointBaseURL = 'organization'

  getOrganizationsByType(organizationTypeId: number): Observable<Organization[]> {
    return this.http.get<Organization[]>(
      `organization/dropdown/?userTypeID=${organizationTypeId}`
    );
  }

  getCustomerByOrganizationID(organizationId: number): Observable<any> {
    return this.http.get<any>(`organization/customer/${organizationId}`);
  }

  getCustomerIDByOrganization(organizationId: number): Observable<any> {
    return this.http.get<any>(
      `organization/customerID?organizationID=${organizationId}`
    );
  }

  getSupplierIDByOrganization(organizationId: number): Observable<any> {
    return this.http.get<any>(
      `organization/supplierID?organizationID=${organizationId}`
    )
  }

  checkIfSupplierExists(organizationNumber: string): Observable<any> {
    return this.http.get<any>(`application/checkIfSupplierExists?organizationNumber=${organizationNumber}`);
  }

  getOrganizationTemplate(organizationTemplateId: number): Observable<any> {
    return this.http.get<any>(`organizationTemplate/${organizationTemplateId}`);
  }

  getOrganizationTemplateWithEventID(eventId: number): Observable<any> {
    return this.http.get<any>(`organizationtemplate/event/${eventId}`);
  }

  getOrganizationTemplates(organizationId: number | null = null, pageIndex: number | null = null, pageSize: number | null = null): Observable<OrganizationTemplate[]> {
    const pageSizeQUery = `${pageSize != null ? `&pageSize=${pageSize}` : ''}}`;
    return this.http.get<OrganizationTemplate[]>(`organizationtemplate/?organizationID=${organizationId}&pageIndex=${pageIndex}${pageSizeQUery}`);
  }

  getAllTemplates(): Observable<any> {
    return this.http.get<any>(`organizationTemplate`);
  }

  getEvents(): Observable<OrganizationTemplate[]> {
    return this.http.get<OrganizationTemplate[]>("");
  }

  createOrganizationTemplate(organizationTemplate: createOrganizationTemplate): Observable<OrganizationTemplate> {
    return this.http.post<OrganizationTemplate>("organizationTemplate", organizationTemplate);
  }

  updateOrganizationTemplate(organizationTemplateId: number, updateOrganizationTemplate: updateOrganizationTemplate): Observable<any> {
    return this.http.put<any>(`organizationTemplate/${organizationTemplateId}`, updateOrganizationTemplate);
  }

  getOrganizationWithSupplierId(supplierId: number): Observable<any> {
    return this.http.get<any>(`supplier/${supplierId}/organization`);
  }

}