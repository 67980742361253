import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';


@Injectable({
  providedIn: 'root'
})
export class SupplierService {

  endpointBaseURL = "supplier";
  
  constructor(private http: HttpClient) {
  }
  
  getOrganizationBySupplierID(supplierID: number): Observable<any> {
        return this.http.get(`${this.endpointBaseURL}/${supplierID}/organization`);
    }
}
