<div class="p-grid">
  <!-- <div class="p-col-12 p-lg-6"> -->
  <p-card class="card" [header]="header">
    <form class="p-mt-3" [formGroup]="uploadForm" (ngSubmit)=" onFileUpload()">
      <div class="file-upload">
        <p-fileUpload name="file" id="file" [customUpload]="true"
          (uploadHandler)="onFileUpload()" (onSelect)="onFileChange($event)"
          accept="application/pdf" chooseLabel="Välj fil"
          chooseIcon="pi pi-paperclip" uploadLabel="Ladda upp"
          cancelLabel="Avbryt" cancelIcon="pi pi-trash" (onClear)="onDeleteFile()"
        >
          <ng-template let-file pTemplate="file">
            <div>{{uploadedFile.name}}</div>
          </ng-template>
          <ng-template pTemplate="content">
            <div *ngIf="!(isUploaded || isSent)">Ingen fil vald</div>
          </ng-template>
        </p-fileUpload>
        <div *ngIf="!isSent">
          <p>Giltiga filformat: .pdf</p>
        </div>
        <div *ngIf="isSent">
          <p>Din fil har laddats upp!</p>
        </div>
      </div>
      <div class="p-mt-4" [hidden]="!processing">
        <p-progressBar mode="indeterminate"></p-progressBar>
      </div>
    </form>
  </p-card>
  <!-- </div> -->
</div>