<div *ngIf="isBrokerUser">
    <p-dropdown 
    [options]="customers"
    optionLabel="organizationName"
    [(ngModel)]="selectedOrganizationID"
    (onChange)="onChange()"
    optionValue="organizationID"
    [filter]="true">

    </p-dropdown>
</div>
<p-card header="Arkiverade Avrop">
    <p-table [value]="CallOffs" dataKey="brokerCallOffID" sortMode="multiple"
        [globalFilterFields]="['CallOffTitle', 'CallOffModified', 'CallOffReference','CallOffCreated', 'startDate']">
        <ng-template pTemplate="header">
            <tr>
                <th style="width: 20%" pSortableColumn="CallOffTitle">Rubrik <p-sortIcon field="CallOffTitle"></p-sortIcon>
                </th>
                <th style="width: 20%" pSortableColumn="CallOffReference">Referens <p-sortIcon field="CallOffReference"></p-sortIcon>
                </th>
                <th style="width: 20%" pSortableColumn="CallOffCreated">Publiceringsdatum <p-sortIcon field="CallOffCreated"></p-sortIcon>
                </th>
                <!-- <th style="width: 18%" pSortableColumn="brokerUserName">Ansvarig Swedish Consulting Group <p-sortIcon field="brokerUserName"rowspan="2"></p-sortIcon>
                </th> -->
                <th style="width: 20%" pSortableColumn="CallOffModified">Redigerad <p-sortIcon field="CallOffModified"></p-sortIcon>
                </th>
                <th style="width: 8rem" class="p-text-right"></th>
                <th></th>
            </tr>
            <tr>
                <th>
                    <p-columnFilter [matchModeOptions]="tableSettings.defaultTextMatchMode" [matchMode]="'contains'" type="text" field="CallOffTitle">
                    </p-columnFilter>
                </th>
                <th>
                    <p-columnFilter [matchModeOptions]="tableSettings.defaultTextMatchMode" [matchMode]="'contains'" type="text" field="CallOffReference">
                    </p-columnFilter>
                </th>
                <th>
                    <p-columnFilter [matchModeOptions]="tableSettings.defaultTextMatchMode" [matchMode]="'contains'" type="text" field="CallOffCreated">
                    </p-columnFilter>
                </th>
                <th>
                    <p-columnFilter [matchModeOptions]="tableSettings.defaultTextMatchMode" [matchMode]="'contains'" type="text" field="CallOffModified">
                    </p-columnFilter>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-co>
            <tr [pContextMenuRow]="co">
                <td>{{co.CallOffTitle}}</td>
                <td>{{co.CallOffReference}}</td>
                <td>{{co.CallOffCreated | date:'medium'}}</td>
                <td>{{co.CallOffModified | date:'medium'}}</td>
                <td>
                    <button
                        pButton
                        label="Återställ"
                        (click)="onRestoreClicked(co)"
                    ></button>
                </td>
            </tr>
        </ng-template>
    </p-table>
</p-card>