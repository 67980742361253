import { CdkDragDrop, moveItemInArray, transferArrayItem } from "@angular/cdk/drag-drop";
import { Component, OnInit, Input } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MenuItem, ConfirmationService, Confirmation } from "primeng/api";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { AssignmentChildren, AssignmentChildrenResponse, ContactInfo, ResponsibleContactInfo } from "src/app/models/assignment-children/assignment-children.model";
import { LaneModel } from "src/app/models/assignment-children/Board/lane.model";
import { StatusToAllowedStatus } from "src/app/models/assignment-children/Board/status-to-allowed-status.model.ts/status-to-allowed-status.model.ts.component";
import { UpdateStatusDTO } from "src/app/models/assignment-children/Board/update-status-dto";
import { StatusInfo } from "src/app/models/status/status-info.model";
import { Status } from "src/app/models/assignment-children/status.model";
import { BrokerCallOff } from "src/app/models/assignment-children/broker-call-off.model";
import { Resume } from "src/app/models/resume/resume.model";
import { UserAuthenticateResponse } from "src/app/models/user/user-authenticate-response.model";
import { AssignmentChildrenService } from "src/app/services/assignment-children-service";
import { AuthService } from "src/app/services/auth.service";
import { BrokerCallOffService } from "src/app/services/broker-call-off.service";
import { PermissionService } from "src/app/services/permission.service";
import { CommentModalComponent } from "../../shared/comment-modal/comment-modal.component";
import { ResumeModalComponent } from "../resumes/resume-modal/resume-modal.component";
import { TenderBoardHubService } from "src/app/services/hub/tender-board-hub.service";
import { AssignmentChildrenStatus } from "src/app/models/status/status.enum";
import { Board } from "src/app/models/assignment-children/Board/board.enum";
import { OrganizationType } from "src/app/models/organization-type-enum.model";
import { TenderCommentsModalComponent } from "../tender-comments/tender-comments-modal";
import { ConfirmationDialogComponent } from "../../shared/confirmation-dialog/confirmation-dialog.component";
import { Observable, of } from "rxjs";
import { CreateBrokerTenderComponent } from './../../assignment-children/broker-tender/create-broker-tender/create-broker-tender.component';
import { AssignmentService } from "src/app/services/assignment.service";
import { EmailModalComponent } from "../../email-modal/email-modal/email-modal.component";
import { ErrorModalComponent } from "../../shared/error-modal/error-modal/error-modal.component";
import { ViewBrokerCalloffSupplierModalComponent } from "src/app/components/assignment-children/supplier-tender/view-broker-call-off-supplier-modal/view-broker-call-off-supplier-modal.component";
import { SupplierTenderInfoModalComponent } from "../../shared/supplier-tender-info-modal/supplier-tender-info-modal.component";

@Component({
  selector: 'app-tender-board',
  templateUrl: './tender-board.component.html',
  styles: [
  ]
})

export class TenderBoardComponent implements OnInit {
  lanes: LaneModel[];
  assignmentChildren: AssignmentChildren[];
  selectedAssignmentChild: AssignmentChildren;
  statuses: Status[];
  statusInfos: StatusInfo[];
  statusRules: StatusToAllowedStatus[];
  token: string;
  updateStatusDto: UpdateStatusDTO;
  assignmentID: any;
  brokerCallOffID: number;
  resumes: Resume[];
  header: string;
  ref: string;
  changeText: boolean;
  user: UserAuthenticateResponse;
  showLane: boolean[];
  isBrokerUser: boolean = false;
  items: MenuItem[];
  dialogRef: DynamicDialogRef;
  continue: boolean;
  contactInfo: any;
  contactInfoList: ContactInfo[];
  responsibleContact: ResponsibleContactInfo;
  hideNotPublishedBrokerCallOff: boolean;

  brokerOrgType = OrganizationType.broker.toString();
  supplierOrgType = OrganizationType.supplier.toString();
  customerOrgType = OrganizationType.customer.toString();

  constructor(
    private permissionService: PermissionService,
    private authService: AuthService,
    private tenderBoardHub: TenderBoardHubService,
    private dialogService: DialogService,
    private route: ActivatedRoute,
    private router: Router,
    private assignmentChildrenService: AssignmentChildrenService,
    private confirmationService: ConfirmationService,
    private brokerCallOffService: BrokerCallOffService,
    private assignmentService: AssignmentService
  ) { }

  ngOnInit(): void {
    this.token = this.authService.getTokenFromStorage();
    this.user = this.getUserObject();
    this.lanes = [];
    this.showLane = [];
    this.changeText = false;

    this.items = [
      {
        label: 'Visa CV', icon: 'pi pi-fw pi-file-pdf',
        command: () => this.getAssignmentChildDocuments(this.selectedAssignmentChild),
      },
      {
        label: 'Kommentarer', icon: 'pi pi-fw pi-file-pdf',
        command: () => this.openCommentModal(this.selectedAssignmentChild),
      },
      {
        label: 'Kasta', icon: 'pi pi-fw pi-file-pdf',
        command: () => this.archiveAssignmentChild(this.selectedAssignmentChild),
        visible: this.isBrokerUser
      },
      {
        label: 'Skapa avtal', icon: 'pi pi-fw pi-file-pdf',
        command: () => this.createNewContract(this.selectedAssignmentChild),
        visible: this.isBrokerUser
      },
    ];

    this.route.params.subscribe(params => {
      this.brokerCallOffID = params.brokerCallOffID;

      this.brokerCallOffService.get<BrokerCallOff>(this.brokerCallOffID).subscribe(res => {
        this.assignmentID = res.assignmentID;
        this.header = res.assignment.header;
        this.ref = res.assignment.assignmentReference;

        if(res.statusID != AssignmentChildrenStatus.BrokerCallOffPublished) {
          this.hideNotPublishedBrokerCallOff = true;
        }

        this.tenderBoardHub.init(this.token)
          .then(async () => {
            this.tenderBoardHub.subscribeToHubGroup(Number(this.assignmentID), this.token = this.authService.getTokenFromStorage(),
              (AssignmentChildrenResponse: AssignmentChildrenResponse) => this.onAssignmentChildren(AssignmentChildrenResponse.assignmentChildren));
            this.tenderBoardHub.connection.on("NotifyUpdateToHubGroup", data => {
              this.tenderBoardHub.getAssignmentChildren(this.assignmentID,
                this.token, (AssignmentChildrenResponse: AssignmentChildrenResponse) => this.onAssignmentChildren(AssignmentChildrenResponse.assignmentChildren));
            })
          });
      });
    });

    this.permissionService.getStatusRules(parseInt(this.user.organizationTypeID)).subscribe(res => {
      this.statusRules = res;
    })

    this.permissionService.getStatusInfos(Number(this.user.organizationTypeID), Board.Anbud).subscribe(res => {
      this.statusInfos = res;

      this.statusInfos = this.statusInfos.filter((value, index, self) =>
        index === self.findIndex((t) => (
          t.statusLaneName === value.statusLaneName
        ))
      )
      this.statusInfos.forEach(statusInfo => {
        this.lanes.push({ statusInfo });
        this.showLane.push(true);
      });
    })
  }

  getUserObject() {
    const userUpdate = this.authService.getUserFromStorage();

    if (Number(userUpdate.organizationTypeID) === OrganizationType.broker) {
      this.isBrokerUser = true;
    }

    return userUpdate;
  }

  toggleFooter(assignmentChild: AssignmentChildren) {
    assignmentChild.showFooter = !assignmentChild.showFooter;
  }

  isLaneAllowed(currentStatusId: number, laneStatusId: number) {
    return this.statusRules.some(x => x.statusID === currentStatusId && x.allowedStatusID === laneStatusId)
  }

  onDragStarted(assignmentChild: any) {
    this.showLane = [];
    this.lanes.forEach((lane) => {
      this.showLane.push(this.isLaneAllowed(assignmentChild.statusID, lane.statusInfo.statusID));
    });

    this.selectedAssignmentChild = assignmentChild;
  }

  checkFurtherActionRequired(newStatusID: number): Observable<boolean> {
    if (newStatusID === AssignmentChildrenStatus.BrokerTenderDraft) {
      //reject move if status differs from SupplierTenderReview TODO: Add feedback to user "You cant make this move unless in "Granskas"
      if (this.selectedAssignmentChild.statusID !== AssignmentChildrenStatus.SupplierTenderReview) {
        return of(false);
      }

      this.dialogRef = this.dialogService.open(CreateBrokerTenderComponent, {
        header: "Anbud",
        width: '40%',
        data: {
          assignmentChildID: this.selectedAssignmentChild.assignmentChildID
        }
      });
      return this.dialogRef.onClose;
    }

    if (newStatusID === AssignmentChildrenStatus.BrokerTenderSent) {
      this.dialogRef = this.dialogService.open(EmailModalComponent, {
        header: "Skicka email",
        width: '40%',
        data: {
          notificationReceivers: receivers, //hämta mottagare
          notificationSubject: 'Nytt anbud',
          notificationContent: 'Innehåll till Intervju önskas',//add orgTemplate
          assignmentChildID: this.selectedAssignmentChild.assignmentChildID,
          assignmentID: this.selectedAssignmentChild.assignmentID,
          eventID: 12,
          mailType: 'customer',
          assignmentChild: this.selectedAssignmentChild
        }
      });
      return this.dialogRef.onClose;
    }

    if (newStatusID === AssignmentChildrenStatus.BrokerTenderInterviewRequest) {
      var receivers: any = [];
      this.assignmentChildrenService.getUserToAssignmentInfo(this.assignmentID)
        .subscribe(res => {
          for (let i = 0; i < res.userToAssignmentUsers.length; i++) {
            receivers.push(res.userToAssignmentUsers[i]);
          }
        },
          (error) => {
            console.log(error);
          }
        )
      this.dialogRef = this.dialogService.open(EmailModalComponent, {
        header: "Send email",
        width: '60%',
        data: {
          notificationReceivers: receivers, //hämta mottagare
          notificationSubject: `Intervju önskas`,
          notificationContent: 'Innehåll till Intervju önskas',//add orgTemplate
          assignmentChildID: this.selectedAssignmentChild.assignmentChildID,
          assignmentID: this.selectedAssignmentChild.assignmentID,
          eventID: 16,
          mailType: 'broker',
          assignmentChild: this.selectedAssignmentChild,
          required: true
        }
      });
      return of(true);
    }
    if (newStatusID === AssignmentChildrenStatus.BrokerTenderInterview) {
      return of(true);
    }
    if (newStatusID === AssignmentChildrenStatus.BrokerTenderNegotiate) {
      return of(true);
    }
    if (newStatusID === AssignmentChildrenStatus.BrokerTenderAccepted) {
      this.dialogRef = this.dialogService.open(EmailModalComponent, {
        header: "Vill du meddela Swedish Consulting Group att anbudet accepteras?",
        width: '40%',
        data: {
          notificationReceivers: receivers,
          notificationSubject: `Accepterat anbud kopplat till uppdraget ${this.selectedAssignmentChild.header}`,
          test: "Accepterat anbud kopplat till uppdraget request_title",
          assignmentID: this.selectedAssignmentChild.assignmentID,
          assignmentChildID: this.selectedAssignmentChild.assignmentChildID,
          eventID: 18,
          mailType: 'broker',
          assignmentChild: this.selectedAssignmentChild
        }
      });
      return this.dialogRef.onClose;
    }

    if (newStatusID === AssignmentChildrenStatus.BrokerTenderRejectedCustomer) {
      this.dialogRef = this.dialogService.open(EmailModalComponent, {
        header: "Vill du meddela Swedish Consulting Group att anbudet ej är aktuellt?",
        width: '40%',
        data: {
          notificationReceivers: receivers,
          notificationSubject: `${this.selectedAssignmentChild.customerName} - Anbud från ${this.selectedAssignmentChild.supplierOrganizationName} kopplat till uppdraget ${this.selectedAssignmentChild.header} är ej aktuellt`,
          test: "request_customer - Anbud från offer_supplier kopplat till uppdraget request_title är ej aktuellt",
          assignmentID: this.selectedAssignmentChild.assignmentID,
          assignmentChildID: this.selectedAssignmentChild.assignmentChildID,
          eventID: 14,
          mailType: 'broker',
          assignmentChild: this.selectedAssignmentChild
        }
      });
      return this.dialogRef.onClose;
    }
    if (newStatusID === AssignmentChildrenStatus.BrokerTenderRejectedSupplier) {
      this.dialogRef = this.dialogService.open(EmailModalComponent, {
        header: "Vill du meddela leverantören att anbudet ej är aktuellt?",
        width: '40%',
        data: {
          notificationReceivers: receivers,
          notificationSubject: `Er offert till ${this.selectedAssignmentChild.customerName} kopplat till uppdraget ${this.selectedAssignmentChild.header} är ej aktuellt`,
          test: "request_customer - Anbud från offer_supplier kopplat till uppdraget request_title är ej aktuellt",
          assignmentID: this.selectedAssignmentChild.assignmentID,
          assignmentChildID: this.selectedAssignmentChild.assignmentChildID,
          supplierContactEmail: this.selectedAssignmentChild.supplierContactEmail,
          supplierContactFullName: this.selectedAssignmentChild.supplierContactFullName,
          eventID: 15,
          mailType: 'supplier',
          assignmentChild: this.selectedAssignmentChild
        }
      });
      return this.dialogRef.onClose;
    }
    if (newStatusID === AssignmentChildrenStatus.BrokerTenderContractOngoing) {
      this.dialogRef = this.dialogService.open(EmailModalComponent, {
        header: "Avtal skapas",
        width: '40%',
        data: {
          notificationReceivers: receivers,
          notificationSubject: `Avtal skapas för avrop: ${this.selectedAssignmentChild.header}`,
          test: "request_customer - Anbud från offer_supplier kopplat till uppdraget request_title är ej aktuellt",
          assignmentID: this.selectedAssignmentChild.assignmentID,
          assignmentChildID: this.selectedAssignmentChild.assignmentChildID,
          eventID: 20,
          mailType: 'customer',
          assignmentChild: this.selectedAssignmentChild
        }
      });
      return this.dialogRef.onClose;
    }
    if (newStatusID === AssignmentChildrenStatus.AssignmentArchived) {
      this.dialogRef = this.dialogService.open(ConfirmationDialogComponent, {
        data: {
          message: "Är du säker på att du vill stänga avropet, arkivera samtliga anbud och skicka mail till berörda parter?",
        }
      });
      return this.dialogRef.onClose
    }
    else {
      return of(true);
    }
  }

  private resetShowLane(): void {
    this.showLane = [];
    this.statusInfos.forEach(() => {
      this.showLane.push(true)
    });
  }

  async onDrop(event: CdkDragDrop<AssignmentChildren[]>, statusID: number) {
    try {
      if (event.previousContainer === event.container) {
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        this.resetShowLane();
      }
      else {

        //Checks if the onDrop is allowed by the status rules
        if (this.isLaneAllowed(event.previousContainer.data[event.previousIndex].statusID, statusID)) {

          console.log(event.previousContainer.data[event.previousIndex].statusID);
          this.checkFurtherActionRequired(statusID).subscribe((res => {
            if (res) {
              transferArrayItem(event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex);
              this.updateAssignmentChildPosition(event.container.data[event.currentIndex], statusID).then(x => {
                this.resetShowLane();
              });
            }
            this.resetShowLane();
          }))
        }
        else {
          this.resetShowLane();
        }
      }
    }
    catch (error: any) {
      this.resetShowLane();
      throw new Error(error);
    }
  }

  openCommentModal(assignmentChild: AssignmentChildren) {
    this.dialogService.open(TenderCommentsModalComponent, {
      header: "Kommentarer för anbud",
      width: '50%',
      data: {
        assignmentChild: assignmentChild
      }
    })
  }

  confirmCreateComment() {
    this.dialogService.open(CommentModalComponent, {
      header: "Kommentera",
      width: '40%',

    });
  }

  getSupplierContact(supplierTenderId: number) {
    this.assignmentChildrenService.getSupplierContact(supplierTenderId).subscribe(res => {
      this.contactInfo = {
        OrganizationType: res['organizationTypeID'],
        fullName: res['fullName'],
        email: res['email'],
        phoneNumber: res['phoneNumber'],
      };
    });
  }

  getAssignmentResponsible(assignmentId: number) {
    this.assignmentChildrenService.getAssignmentResponsible(assignmentId).subscribe(res => {

      this.responsibleContact = {
        brokerResponsibleContact: res['responsibleBrokerUser'],
        customerReponsibleContact: res['responsibleCustomerUser']
      };
    });
  }
  getUserToAssignment(assignmentId: number) {
    this.assignmentChildrenService.getUserToAssignmentInfo(assignmentId).subscribe(res => {
      this.contactInfoList = res;
    });
  }

  getAssignmentChildDocuments(assignmentChild: AssignmentChildren) {
    this.assignmentChildrenService.getResumesByTenderId(assignmentChild.assignmentChildID, assignmentChild.assignmentChildType).subscribe(res => {
      let assignmentChildResumes: any[];
      this.resumes = [];
      assignmentChildResumes = res[Object.keys(res)[0]];
      assignmentChildResumes.forEach(item => {
        this.resumes.push(item['resume']);
      });

      this.dialogService.open(ResumeModalComponent,
        {
          header: "Anbud CV",
          width: '70%',
          contentStyle: { "max-height": "800px", "overflow": "auto" },
          data: {
            resumes: this.resumes,
            assignmentChildID: assignmentChild.assignmentChildID
          }
        })
    });
  }

  private async archiveAssignmentChild(assignmentChild: AssignmentChildren) {
    this.dialogRef = this.dialogService.open(ConfirmationDialogComponent, {
      data: {
        message: "Är du säker på att du vill kasta kortet?",
      }
    });
    this.dialogRef.onClose.subscribe((res => {
      if (res) {
        this.updateStatusDto = {
          assignmentChildTypeName: assignmentChild.assignmentChildType,
          assignmentChildID: assignmentChild.assignmentChildID,
          currentStatus: assignmentChild.statusID,
          newStatusID: AssignmentChildrenStatus.AssignmentArchived
        };
        this.tenderBoardHub.updateAssignmentChildStatus(this.updateStatusDto, () => { }, this.onUpdateStatus, this.assignmentID);
      }
    }))
  }

  private async updateAssignmentChildPosition(assignmentChildren: AssignmentChildren, statusID: number) {
    this.updateStatusDto = {
      assignmentChildTypeName: assignmentChildren.assignmentChildType,
      assignmentChildID: assignmentChildren.assignmentChildID,
      currentStatus: assignmentChildren.statusID,
      newStatusID: statusID
    };
    await this.tenderBoardHub.updateAssignmentChildStatus(this.updateStatusDto, () => { }, this.onNotifyUpdateToHubGroup, this.assignmentID);
  }

  private async onAssignmentChildren(assignmentChildren: any[]) {
    this.lanes.forEach(l => {
      l.assignmentChildren = assignmentChildren.filter(ac => ac.statusLaneName === l.statusInfo.statusLaneName);
    });
  }

  private async onNotifyUpdateToHubGroup(assignmentChildren: any[]) {
  }

  private async onUpdateStatus() {
  }

  createSupplierTenderModal() {
    this.router.navigate(['supplier-tender-create/' + this.assignmentID + '/' + this.brokerCallOffID], { queryParams: { back: 'broker-tender-board/' + this.brokerCallOffID } });
  }

  createNewContract(assignmentChild: AssignmentChildren) {
    if (assignmentChild.statusID === AssignmentChildrenStatus.BrokerTenderContractOngoing) {
      this.router.navigate([`create-contract/${assignmentChild.assignmentChildID}`]);
    }
    else {
      this.dialogService.open(ErrorModalComponent,
        {
          header: "Något gick fel",
          data: {
            message: "Anbudet måste flyttas till Avtal skapas för att kunna skapa avtal"
          }
        })
    }
  }

  viewConsultantInformation(assignmentChild: AssignmentChildren) {
    const ref = this.dialogService.open(SupplierTenderInfoModalComponent, {
      width: '50%',
      data: { assignmentChild }
    });
  }

  viewCalloff(assignmentChildId: number): void {
    const ref = this.dialogService.open(ViewBrokerCalloffSupplierModalComponent, {
      header: "Avrop",
      width: '50%',
      data: { supplierTenderId: assignmentChildId }
    });
  }

  ngOnDestroy(): void {
    this.tenderBoardHub.disconnect();
  }

  confirm(): void {
    this.confirmationService.confirm({
      message: 'Om du stänger avropet kommer befintlig/a avrop och anbud inte längre synas för kunder och leverantörer',
      accept: () => {
        if (
          this.assignmentID != undefined &&
          !isNaN(this.assignmentID) &&
          parseInt(this.assignmentID) != 0
        ) {
          this.assignmentService.archive(parseInt(this.assignmentID)).subscribe(response => {
            if (response) {
              this.router.navigate(['/broker-call-off-list']);
            }
          })
        }
      }
    });
  }
}