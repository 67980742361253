<div *ngIf="isBrokerUser">
    <p-dropdown 
    [options]="customers"
    optionLabel="organizationName"
    [(ngModel)]="selectedOrganizationID"
    (onChange)="onChange()"
    optionValue="organizationID"
    [filter]="true">

    </p-dropdown>
</div>
<p-card header="Arkiverade Offerter">
    <p-table [value]="Tenders" dataKey="brokerCallOffID"
        [globalFilterFields]="['brokerUserName', 'lastModified', 'assignmentReference','header', 'startDate']">
        <ng-template pTemplate="header">
            <tr>
                <th style="width: 20%" pSortableColumn="header">Konsult <p-sortIcon field="header"></p-sortIcon>
                </th>
                <th style="width: 20%" pSortableColumn="header">Avrop Rubrik <p-sortIcon field="header"></p-sortIcon>
                </th>
                <th style="width: 20%" pSortableColumn="assignmentReference">Avropsnummer <p-sortIcon field="assignmentReference"></p-sortIcon>
                </th>
                <th style="width: 20%" pSortableColumn="startDate">Publiceringsdatum <p-sortIcon field="startDate"></p-sortIcon>
                </th>
                <th style="width: 20%" pSortableColumn="customerName">Redigerad <p-sortIcon field="lastModified"></p-sortIcon>
                </th>
                <th style="width: 8rem" class="p-text-right"></th>
                <th></th>
            </tr>
            
        </ng-template>
        <ng-template pTemplate="body" let-co>
            <tr [pContextMenuRow]="co">
                <td>{{co.consultantName}}</td>
                <td>{{co.tenderTitle}}</td>
                <td>{{co.tenderReference}}</td>
                <td>{{co.created | date:'medium'}}</td>
                <td>{{co.modified | date:'medium'}}</td>
                <td>
                    <button
                        pButton
                        label="Återställ"
                        (click)="onRestoreClicked(co)"
                    ></button>
                </td>
            </tr>
        </ng-template>
    </p-table>
</p-card>