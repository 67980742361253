import { Component, OnInit } from '@angular/core';
import { of } from 'rxjs';

@Component({
  selector: 'app-organization-consultant-competencies',
  templateUrl: './organization-consultant-competencies.component.html',
  styles: [
  ]
})
export class OrganizationConsultantCompetenciesComponent implements OnInit {

  competencies : any;
  processing = true;

  constructor() { }

  ngOnInit(): void {

    const dummyobs = of([
      { id: 1, compName: 'C#', compLvl: 1, compLvlName: 'Beginner', },
      { id: 2, compName: 'F#', compLvl: 5, compLvlName: 'Master' },
      ]).subscribe((res) => {
        this.competencies = res;
        this.processing = false;
      })

  }

}
