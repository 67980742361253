<div class="p-grid">
    <div class="p-col-4">
        <p-card header="Redigera roll">
            <form [formGroup]="editRoleForm" (ngSubmit)="onEditRole()">
                <div class="p-fluid p-grid">
                    <div class="p-field p-col-6">
                        <label for="name">
                            Roll namn
                            <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px"
                                pTooltip="placeholder text"></i>
                            </span>
                        </label>
                        <input id="name" formControlName="name" type="text" [disableControl]="processing" pInputText>
                    </div>
                </div>
                <div class="p-grid p-mt-4">
                    <div class="p-col-6">
                        <button pButton
                                type="button"
                                icon="pi pi-chevron-left"
                                class="p-button-raised p-button-secondary p-text-uppercase"
                                label="Tillbaka"
                                [disabled]="processing"
                                (click)="onBackClicked()"></button>
                    </div>
                    <div class="p-col-6 p-text-right">
                        <button pButton
                                type="submit"
                                icon="pi pi-user-edit"
                                iconPos="right"
                                class="p-button-raised p-button p-text-uppercase"
                                label="Redigera"
                                [disabled]="processing"></button>
                    </div>
                </div>
                <div class="p-mt-4" [hidden]="!processing">
                    <p-progressBar mode="indeterminate"></p-progressBar>
                </div>
            </form>
        </p-card>
    </div>
    <div class="p-col-4">
        <app-edit-role-users [roleId]="roleId"></app-edit-role-users>
    </div>
</div>