import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AuthService } from "../../../services/auth.service";
import { Router, ActivatedRoute } from '@angular/router';
import { SubscriptionService } from 'src/app/services/subscription.service';

@Component({
  selector: 'app-unsubscribe-enter-email',
  templateUrl: './unsubscribe-enter-email.component.html',
  styles: [
  ]
})
export class UnsubscribeEnterEmailComponent implements OnInit {
  userMessage: string;
  isError: boolean = false;
  processing: boolean = false;
  unsubscribeAuthenticated = '/subscription/unsubscribe-enter-email';
  unsubscribe = '/login/subscription/unsubscribe-enter-email';
  email: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private subscriptionService: SubscriptionService,
    private cdRef: ChangeDetectorRef,
    public authService: AuthService
  ) { }

  ngOnInit(): void {
    this.processing = this.route.snapshot.queryParamMap.get('processing') === 'true';

    if (this.processing) {
      if (this.authService.isAuthenticated()) {
        this.router.navigate([this.unsubscribeAuthenticated], { queryParams: { processing: 'false' } });
      } else {
        this.router.navigate([this.unsubscribe], { queryParams: { processing: 'false' } }).then(() => {
          window.location.reload();
        });
      }
    } else{

    }
  }

  unsubscribeByEmail(): void {
    if (!this.email) {
      this.userMessage = 'Vänligen ange en giltig e-postadress.';
      this.isError = true;
      return;
    }
    this.isError = false;
    this.processing = true;

    this.subscriptionService.unsubscribeNotification(this.email).subscribe({
      next: (response) => {
        this.userMessage = 'Ett email kommer skickas inom en minut till denna emailadress, följ instruktionerna för att bekräfta emailadressen och avprenumerera.';
        this.isError = false;
      },
      error: (error) => {
        this.userMessage = 'Ett fel uppstod. Försök igen senare.';
        this.isError = true;
      },
      complete: () => this.processing = false
    });
  }

  onShowLogin(): void {
    this.router.navigate(['login']);
  }
  onShowBrokerCalloffs(): void {
    this.router.navigate(['broker-call-off-list']);
  }
}