import { Injectable } from "@angular/core";
import { AuthService } from 'src/app/services/auth.service';
import { DialogService } from "primeng/dynamicdialog";
import { ErrorModalComponent } from "src/app/components/shared/error-modal/error-modal/error-modal.component";
import { AssignmentChildren } from "src/app/models/assignment-children/assignment-children.model";
import { UpdateStatusDTO } from "src/app/models/assignment-children/Board/update-status-dto";
import { BaseHubService } from "./base-hub.service";

@Injectable({
    providedIn: 'root'
  })
  export class CallOffBoardHubService extends BaseHubService {
    endpointBaseURL  = `callOffBoard`; 
    organizationTypeID: number;

    constructor(
      public dialogService: DialogService, private authService: AuthService
      ) {
      super();
  
      this.organizationTypeID = this.getOrganizationType();
    }

    initListeners(): void {
      this.connection.on("Error",  (errorMessage:string) => {
        this.dialogService.open(ErrorModalComponent, 
          {
            header: "Något gick fel",
            data: {
              message: errorMessage
            }
          } )
          
      }); 
    }

    public async getAssignmentChildren(organizationID: number, token: string, callback: (AssignmentChildrenResponse: any) => void) {
      try {
        this.organizationTypeID = this.getOrganizationType();
        this.connection.invoke("GetCallOffs", organizationID, token, this.organizationTypeID);
      }
        catch (error: any) {
        throw new Error(error.message);
      }
     }

    public async updateAssignmentChildStatus(updateStatusDTO: UpdateStatusDTO,
      errorCallback: (assignmentChildren: AssignmentChildren[])=> void, updateCallback: (assignmentChildren: AssignmentChildren[])=> void, customerID: number) {
     try {
      this.organizationTypeID = this.getOrganizationType();
       let _ = await this.connection.invoke("MoveCallOffObject", updateStatusDTO, customerID, this.organizationTypeID)
     } catch (error: any){
       throw new Error(error.message);
     }
    }

    public async subscribeToCallOffGroup(organizationID: number, token: string, callback: (AssignmentChildrenResponse: any) => void) {
      try {
        this.organizationTypeID = this.getOrganizationType();
        this.connection.on("AssignmentChildren", callback)
        let _ = await this.connection.invoke("SubscribeToCallOffGroup", organizationID, token, this.organizationTypeID);
      }
        catch (error: any) {
        throw new Error(error.message);
      }
    }
    getOrganizationType() {
      const user = this.authService.getUserFromStorage();
      if (!user) {
        throw new Error('User information not found');
      } 
      
      return parseInt(user.organizationTypeID);
    }

}