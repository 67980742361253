import {NgModule} from '@angular/core';
import { HeaderComponent } from './header/header.component';
import { HeaderLoginComponent } from './header/header-login.component';
import { ContainerComponent } from './container/container.component';
import { FooterComponent } from './footer/footer.component';
import {RouterModule} from "@angular/router";
import { SideNavComponent } from './side-nav/side-nav.component';
import {PrimeModule} from "../../prime-module.ts";
import {CommonModule} from "@angular/common";

@NgModule({
    declarations: [
        HeaderComponent,
        HeaderLoginComponent,
        ContainerComponent,
        FooterComponent,
        SideNavComponent
    ],
    imports: [
        CommonModule,
        PrimeModule,
        RouterModule,
    ],
    exports: [
        HeaderComponent,
        HeaderLoginComponent,
        ContainerComponent,
        FooterComponent
    ]
})

export class LayoutModule { }
