import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogModule, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-comment-modal',
  templateUrl: './comment-modal.component.html',
  styles: [
  ]
})
export class CommentModalComponent implements OnInit {
  commentForm: FormGroup;
  title: string;
  constructor(
    private formBuilder: FormBuilder,
    private modalRef: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) { }

  ngOnInit(): void {
    this.title = this.config.data.title;
    this.commentForm = this.formBuilder.group({
      comment:[''],
    });
  }

  onSendComment() {
    this.modalRef.close(this.commentForm.controls.comment.value);
  }
}
