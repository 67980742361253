import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { StatusInfo } from '../models/status/status-info.model';
import { StatusInfoList } from '../models/status/status-info-list.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  constructor(private http: HttpClient) { }

  endpointBaseURL = "permission"
  endpointStatusURL = "StatusInfo"
  getStatusRules(organizationTypeID: number): Observable<any> {
    return this.http.get(`${this.endpointBaseURL}?userTypeID=${organizationTypeID}`);
  }

  getStatusInfos(organizationTypeID: number, boardID?: number): Observable<StatusInfo[]> {
    
    var query: string = `${this.endpointStatusURL}?userTypeID=${organizationTypeID}`;

    if (boardID !== undefined) {
      query = `${query}&boardID=${boardID}`
    }

    return this
      .http
      .get<StatusInfoList>(
        query
      )
      .pipe(map(response => response.statusInfos));
  }

}
