<div class="user-reset-content">
    <p-card>
      <div class="card-top-large">
        <div>Nytt lösenord</div>
      </div>
      <ng-container *ngIf="state === 'unsent'">
        <div class="field col-1">
          <h5>Ange nytt lösenord:</h5>
          <input class="col-1" type="password" placeholder="**********" pPassword [(ngModel)]="pw" [feedback]="false" />
        </div>
        <div class="field col-1">
          <h5>Upprepa nytt Lösenord: </h5>
          <input class="col-1" type="password" placeholder="**********" pPassword [(ngModel)]="pwRepeat" [feedback]="false" />
        </div>
        <div class="btns">
          <button type="button" [disabled]="!passwordValid()" mat-flat-button class="primary-btn" (click)="updatePassword()">SPARA</button>
        </div>
      </ng-container>
      <ng-container *ngIf="state === 'sent'">
        <div style="text-align: center;">
          <p>Lösenord uppdaterat!</p>
        </div>
      </ng-container>
      <p *ngIf="error" style="color: red; padding: 0 15px; font-size: 12px;">Lösenord kunde ej uppdateras!</p>
      <p *ngIf="error" style="color: red; padding: 0 15px; font-size: 12px;">Tidsfristen för din begäran kan ha gått ut eller så kan server ej kontaktas.</p>
      <p *ngFor="let error of errors" style="color: red; padding: 0 15px; font-size: 12px;">{{ error }}</p>
      <p-progressBar mode="indeterminate" *ngIf="loading"></p-progressBar>
    </p-card>
  </div>