import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {RoleService} from "../../../../services/role.service";
import {User} from "../../../../models/user/user.model";
import {UserService} from "../../../../services/user.service";
import {ConfirmationService, MenuItem} from "primeng/api";
import {UserList} from "../../../../models/user/user-list.model";
import {Result} from "../../../../models/common/result.model";

@Component({
  selector: 'app-edit-role-users',
  templateUrl: './edit-role-users.component.html',
  styles: []
})
export class EditRoleUsersComponent implements OnInit {

  @Input() roleId: number;

  processing: boolean;
  invalidForm: boolean;
  items: MenuItem[];

  users: User[];
  selectedUser: User;

  roleUsers: User[];

  editRoleUsersForm: FormGroup;


  constructor(private router: Router,
              private formBuilder: FormBuilder,
              private confirmationService: ConfirmationService,
              private roleService: RoleService,
              private userService: UserService) {
  }

  ngOnInit(): void {
    this.items = [
      {label: 'Ta bort', icon: 'pi pi-fw pi-search', command: () => this.onRemoveUserFromRoleClicked(this.selectedUser)}
    ];

    this.createEditUserRoleForm();
    this.fetchUsersInRole(this.roleId);
  }

  fetchUsersInRole(roleId: number) {
    this.processing = true;
    this.userService.getAllInRole(this.roleId)
      .subscribe(({users}: UserList) => {
        this.roleUsers = users;
        this.processing = false;
      }, error => {
        console.log(error);
        this.processing = false;
      });
  }

  fetchUsersNotInRole(roleId: number, searchTerm: string) {
    this.processing = true;
    this.userService.getAllNotInRole(this.roleId, searchTerm, 1, 10)
      .subscribe(({users}: UserList) => {
        this.users = users;
        this.processing = false;
      }, error => {
        console.log(error);
        this.processing = false;
        this.processing = false;
      });
  }

  onAddRoleUserClicked() {
    this.invalidForm = false;
    if(!this.editRoleUsersForm.valid) {
      this.invalidForm = true;
      return;
    }
    this.processing = true;
    this.userService.addUserToRole(this.selectedUser.id, this.roleId)
      .subscribe(({succeeded, errors}: Result) => {
        if(succeeded) {
          this.fetchUsersInRole(this.roleId);
          this.selectedUser = <User>{};
        }
        this.processing = false;
      }, ({succeeded, errors}: Result) => {
        this.invalidForm = true;
        this.processing = false;
      });
  }

  onRemoveUserFromRoleClicked(user: User) {
    this.processing = true;
    this.userService.removeUserFromRole(user.id, this.roleId)
      .subscribe(({succeeded, errors}: Result) => {
        if(succeeded) {
          this.fetchUsersInRole(this.roleId);
          this.selectedUser = <User>{};
        }
        this.processing = false;
      }, ({succeeded, errors}: Result) => {
        this.invalidForm = true;
        this.processing = false;
      });
  }

  private createEditUserRoleForm() {
    this.editRoleUsersForm = this.formBuilder.group({
      selectedUser: ['', Validators.required]
    });
  }

  confirmRemoveUserFromRole(user: User) {
    this.confirmationService.confirm({
      header: `Är du säker?`,
      message: `Är du säker att du vill ta bort \`${user.fullName}\` från roll id \`${this.roleId}\`?`,
      icon: 'pi pi-info-circle',
      acceptLabel: `Ja`,
      acceptButtonStyleClass: `p-button p-button-danger`,
      rejectLabel: `Nej`,
      rejectButtonStyleClass: `p-button-outlined p-button-secondary`,
      accept: () => {
        this.onRemoveUserFromRoleClicked(user);
      }
    });
  }

  search($event: any) {
    this.fetchUsersNotInRole(this.roleId, $event.query);
  }
}
