import { ConsoleLogger } from "@microsoft/signalr/dist/esm/Utils";

export function removeTimeFromDateString(datestring: string): string {
  return datestring.split('T')[0];
}

export function calculateDiff(dateString: Date) {
  let currentDate = new Date();
  dateString = new Date(dateString);

  let days = Math.floor((dateString.getTime() - currentDate.getTime()) / 1000 / 60 / 60 / 24);
  console.log(days);
  return days;
}

export function convertToLocaleString(date: string): string {
  return new Date(date).toLocaleString();
}

export function convertToLocaleDateString(date: string): string {
  return new Date(date).toLocaleDateString();
}

export function convertToLocaleStringFromUTC(date: string): string {
  const d = new Date(date);
  d.setHours(d.getHours() + 4);

  const day = ("0" + d.getDate()).slice(-2); // get the day and prepend zero if necessary
  const month = ("0" + (d.getMonth() + 1)).slice(-2); // get the month (0-11), add 1, and prepend zero if necessary
  const year = d.getFullYear().toString(); // get the full year

  return `${year}-${month}-${day}`;
}