import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Contract, CreateContract, UpdateContract } from 'src/app/models/contract/contract.model';
import { ContractService } from 'src/app/services/contract.service';
import { Location } from '@angular/common';
import { BrokerTender } from 'src/app/models/assignment-children/broker-tender.model';
import { BrokerTenderService } from 'src/app/services/broker-tender.service';
import { ResumeService } from 'src/app/services/resume.service';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { ErrorModalComponent } from '../../shared/error-modal/error-modal/error-modal.component';
import { convertToLocaleStringFromUTC, removeTimeFromDateString } from 'src/app/utils/date';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-create-contract',
  templateUrl: './create-contract.component.html',
  styles: [
  ]
})
export class CreateContractComponent implements OnInit {
  form: FormGroup;
  errors: string[];
  processing: boolean;
  leavePageWarningEnabled: boolean = true;
  invalidForm: boolean
  // minimumDate = new Date();
  contract: CreateContract
  updateContract: UpdateContract;
  selectedContractId: number;
  brokerTenderToContract: BrokerTender;
  state: string = 'create';
  selectedConsultantId: number;
  selectedBrokerTenderId: number;
  convertDate = convertToLocaleStringFromUTC;

  @Input() inputContractId: number;
  

  constructor(
    private formBuilder: FormBuilder,
    private contractService: ContractService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private brokerTenderService: BrokerTenderService,
    private resumeService: ResumeService,
    private messageService: MessageService,
    private dialogService: DialogService,
    // private config: DynamicDialogConfig
  ) { }

  ngOnInit(): void {

    this.form = this.formBuilder.group({
      brokerTenderID: ["",[]],
      contractDate: ["",[Validators.required]],
      contractName: ['',[Validators.required]],
      margin: [0,[]],
      priceComment: ["",[]],
      customerContractReference: ["",[Validators.required]],
      supplierContractReference: ["",[Validators.required]],
      customerPrice: [0,[Validators.required]],
      supplierPrice: [0,[Validators.required]],
      frameWorkPrice: [0,[]],
      assignmentExtent: ["",[]],
      startDate: ["",[Validators.required]],
      endDate: ["",[Validators.required]],
      consultantID: ["" ,[Validators.required]],
    });

    this.route.params.subscribe(params => {
      if (params?.contractID) {
        this.selectedContractId = Number(params.contractID);
        this.fetchContract(Number(params.contractID));
      }
      if (params?.brokerTenderID) {
        this.brokerTenderService.get<BrokerTender>(Number(params.brokerTenderID)).subscribe(res => {
          this.brokerTenderToContract = res;
          this.resumeService.getResume(this.brokerTenderToContract.resumes[0].resumeID).subscribe(res => {
            this.form.controls.consultantID.patchValue(res.consultant.consultantID);
          }, error => {
            console.log(error);
          })
        })
      }
  });
  }

  getMargin() {
    var cprice = this.form.get('customerPrice')?.value;
    var sprice = this.form.get('supplierPrice')?.value;
    this.form.get('margin')?.setValue(cprice - sprice);
    
  }

  fetchContract(contractId: number): void {
    this.contractService.get(contractId).subscribe((res: any) => {

      res.startDate = res.startDate !== null ? this.convertDate(res.startDate): res.startDate;
      res.contractDate = res.contractDate !== null ? this.convertDate(res.contractDate) : res.contractDate;
      res.endDate = this.convertDate(res.endDate);

      this.form.get('assignmentID')?.setValue(res.assignmentID);
      this.form.get('contractDate')?.setValue(new Date(res.contractDate));
      this.form.get('contractName')?.setValue(res.contractName);
      this.form.get('margin')?.setValue(res.margin);
      this.form.get('priceComment')?.setValue(res.priceComment);
      this.form.get('customerContractReference')?.setValue(res.customerContractReference);
      this.form.get('customerPrice')?.setValue(res.customerPrice);
      this.form.get('supplierContractReference')?.setValue(res.supplierContractReference);
      this.form.get('supplierPrice')?.setValue(res.supplierPrice);
      this.form.get('frameWorkPrice')?.setValue(res.frameworkPrice);
      this.form.get('assignmentExtent')?.setValue(res.assignmentExtent);
      this.form.get('startDate')?.setValue(new Date(res.startDate));
      this.form.get('endDate')?.setValue(new Date(res.endDate));
      this.form.get('consultantID')?.setValue(res.consultantID);
      this.form.get('brokerTenderID')?.setValue(res.brokerTenderID);

      this.selectedBrokerTenderId = res.brokerTenderID;
      this.selectedConsultantId = res.consultantID;
    })
  }

  onCreateClicked() {
    this.processing = true;
    this.leavePageWarningEnabled = false;

    if (this.form.invalid) {
      this.invalidForm = true;
      return;
    }

    this.contract = this.form.value;

    if (this.selectedContractId) {
      this.onSaveClicked();
      return
    }
    this.contract.brokerTenderID = this.brokerTenderToContract.brokerTenderID;
    this.contract.consultantId = this.form.controls["consultantID"].value; //TODO: fix this
    this.contractService.create<CreateContract>(this.contract).subscribe((res: any) => {
      this.processing = false;
      this.showCreatedToast();
      this.router.navigate(['/contract-list']);
    },
    (error) => {
      this.processing = false;
      this.leavePageWarningEnabled = true;
      this.dialogService.open(ErrorModalComponent,
        {
          header: "Något gick fel",
          data: {
            message:"Misslyckades att skapa kontraktet. Vänligen försök igen."
          }
        })
    })
  }

  onSaveClicked() {
    this.updateContract = this.form.value;
    this.updateContract.brokerTenderID = this.selectedBrokerTenderId;
    this.updateContract.consultantID = this.selectedConsultantId;
    this.contractService.update(this.selectedContractId, this.updateContract).subscribe(res => {
      this.processing = false;
      this.showUpdatedToast();
      this.router.navigate(['/contract-list']);
    },
    (error) => {
      this.processing = false;
      this.dialogService.open(ErrorModalComponent,
        {
          header: "Något gick fel",
          data: {
            message:"Misslyckades att uppdatera kontraktet. Vänligen försök igen."
          }
        })
    });
  }


  getDatestring(date: any) {
    return removeTimeFromDateString(date.toString());
  }

  onBackClicked(): void {
    this.location.back();
  }

  showCreatedToast() {
    this.messageService.add({
      key:'main',
      severity:'success',
      detail:'Kontraktet har skapats'
    })
  }

  canDeactivate(confirmationMessage: string): boolean | Observable<boolean> | Promise<boolean> {
    if (this.leavePageWarningEnabled) {
      if (this.form.dirty) {
        return window.confirm(confirmationMessage);
      }
    }
    return true;
  }

  showUpdatedToast() {
    this.messageService.add({
      key:'main',
      severity:'success',
      detail:'Kontraktet har uppdaterats'
    })
  }
}
