import { UserToAssignmentComponent } from './../../../user-to-assignment/user-to-assignment/user-to-assignment.component';
import { CreateUserToAssignmentDTO } from './../../../../models/assignment/assignment.model';
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { BrokerCallOffService } from "../../../../services/broker-call-off.service";
import { CustomerService } from "../../../../services/customer.service";
import { environment } from "src/environments/environment";
import { Organization } from "src/app/models/organization/organization.model";
import { User } from "../../../../models/user/user.model";
import { UserService } from "src/app/services/user.service";
import { AssignmentService } from "../../../../services/assignment.service";
import { OrganizationType } from "src/app/models/organization-type-enum.model";
import { ActivatedRoute, Router } from "@angular/router";
import { CreateAssignment } from "src/app/models/assignment/create-assignment.model";
import { CreateBrokerCallOff, UpdateBrokerCallOff } from "src/app/models/assignment-children/broker-call-off.model";
import { SupplierTenderService } from "src/app/services/supplier-tender.service";
import { BrokerTenderService } from "src/app/services/broker-tender.service";
import { CreateBrokerTender } from "src/app/models/assignment-children/broker-tender.model";
import { CreateSupplierTender } from "src/app/models/assignment-children/supplier-tender.model";
import { OrganizationService } from "src/app/services/organization.service";
import { AuthService } from "src/app/services/auth.service";
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { map, mergeMap } from 'rxjs/operators';
import { MessageService } from 'primeng/api';
import { Observable } from 'rxjs';

@Component({
  selector: "app-create-broker-call-off",
  templateUrl: "./create-broker-call-off.component.html",
  styles: [],
})

export class CreateBrokerCallOffComponent implements OnInit {
  form: FormGroup;
  errors: string[];
  assignmentId: number;
  userID = JSON.parse(this.userService.getUserFromLocalStorage()).userID;
  id: number = parseInt(this.userID);
  orgID = JSON.parse(this.userService.getUserFromLocalStorage()).organizationID;
  callOff: CreateBrokerCallOff;
  updateCallOff: UpdateBrokerCallOff;
  customers: Organization[] = [];
  processing: boolean;
  invalidForm: boolean;
  price: number;
  settings: any = environment.applicationSettings.table;
  assignment: CreateAssignment;
  users: User[] = [];
  picked: any[] = [];
  selectedCallOffID: number;
  selectedAssignmentID: number;
  statusID: number;
  user = this.authService.getUserFromStorage();
  isBroker: boolean;
  isCustomer: boolean;
  isSupplier: boolean;
  usersToAssignment: CreateUserToAssignmentDTO[] = [];
  allBrokerUsers: User[] = [];
  assignmentID: number;
  dialogRef: DynamicDialogRef;
  customersOrg: any;
  showHoverList: boolean;
  customerID: number;
  leavePageWarningEnabled: boolean = true;

  constructor(
    private formBuilder: FormBuilder,
    private callOffservice: BrokerCallOffService,
    private organizationService: OrganizationService,
    private assignmentService: AssignmentService,
    private userService: UserService,
    private supplierTenderService: SupplierTenderService,
    private brokerTenderService: BrokerTenderService,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private dialogService: DialogService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    if (Number(this.user.organizationTypeID) === OrganizationType.broker) {
      this.isBroker = true
    }
    if (Number(this.user.organizationTypeID) === OrganizationType.customer) {
      this.isCustomer = true
    }
    this.getCustomers();

    this.form = this.formBuilder.group({
      customerID: ["", [Validators.required]],
      header: ["", [Validators.required]],
      assignmentReference: ["", [Validators.required]],
      assignmentTypeID: ["", [Validators.required]],
      priceTypeID: ["", [Validators.required]],
      assignmentEstimate: ["", [Validators.required]],
      assignmentUnitID: ["", [Validators.required]],
      multipleResources: [null, []],
      assignmentMaxPrice: ["", [Validators.required]],
      startDate: ["", [Validators.required]],
      endDate: ["", [Validators.required]],
      description: ["", [Validators.required]],
      assignmentLocationTypeID: ["", [Validators.required]],
      assignmentLocation: ["", [Validators.required]],
      brokerID: [1],
      userID: [this.id],
      users: [[]],
      assignmentTemplateID: [1],
    });

    this.route.params.subscribe(params => {
      if (params?.callOffID) {
        this.selectedCallOffID = Number(params.callOffID);
        this.fetchCallOff(Number(params.callOffID));

        this.callOffservice.get(this.selectedCallOffID).pipe(
          mergeMap((response: any) => {
            this.selectedAssignmentID = response.assignmentID;
            this.statusID = response.statusID;
            return this.assignmentService.getUsers(this.selectedAssignmentID); // You can return the response if needed in the subsequent steps
          })
        ).subscribe(
          (res: any) => {
            this.picked = res.userToAssignmentUsers;
          },
          (error) => {

          }
        );

      }
    });
  }

  canDeactivate(confirmationMessage: string): boolean | Observable<boolean> | Promise<boolean> {
    if(this.leavePageWarningEnabled)
    {
      if (this.form.dirty) {
        return window.confirm(confirmationMessage);
      }
    }
    return true;
  }

  fetchCallOff(callOffID: number): void {
    this.processing = true;
    this.callOffservice.get(callOffID).subscribe(
      (response: any) => {
        this.customerID = response.assignment.customerID;
        this.form.get('customerID')?.setValue(response.assignment.customerID);
        this.form.get('header')?.setValue(response.assignment.header);
        this.form.get('assignmentReference')?.setValue(response.assignment.assignmentReference);
        this.form.get('assignmentTypeID')?.setValue(response.assignment.assignmentTypeID);
        this.form.get('priceTypeID')?.setValue(response.assignment.priceTypeID);
        this.form.get('assignmentEstimate')?.setValue(response.assignment.assignmentEstimate);
        this.form.get('assignmentUnitID')?.setValue(response.assignment.assignmentUnitID);
        this.form.get('multipleResources')?.setValue(response.assignment.multipleResources);
        this.form.get('assignmentMaxPrice')?.setValue(response.assignment.assignmentMaxPrice);
        this.form.get('startDate')?.setValue(new Date(response.assignment.startDate));
        this.form.get('endDate')?.setValue(new Date(response.assignment.endDate));
        this.form.get('description')?.setValue(response.assignment.description);
        this.form.get('assignmentLocationTypeID')?.setValue(response.assignment.assignmentLocationTypeID);
        this.form.get('assignmentLocation')?.setValue(response.assignment.assignmentLocation);
        this.form.get('brokerID')?.setValue(response.assignment.brokerID);
        this.form.get('userID')?.setValue(this.id);
        this.form.get('assignmentTemplateID')?.setValue(response.assignment.assignmentTemplateID);
        this.processing = false;

        this.assignmentID = response.assignmentID;
      },
      (error) => {
        this.processing = false;
      }
    );
  }

  get assignmentTypeID(): any {
    return this.form.get('assignmentTypeID');
  }

  updateAssignmentTypeID(e: any) {
    console.log(e);
    switch (e) {
      case 1: {
        this.form.get('priceTypeID')?.setValue(2);
        this.form.get('assignmentUnitID')?.setValue(1);
        console.log("tidbank");
        break;
      }
      case 2: {
        this.form.get('priceTypeID')?.setValue(1);
        this.form.get('assignmentUnitID')?.setValue(3);
        this.form.get('assignmentEstimate')?.setValue(0);
        console.log("åtagande");
        break;
      }
      case 3: {
        // this.form.setValue({priceTypeID:1, assignmentUnitID:2});
        this.form.get('priceTypeID')?.setValue(2);
        this.form.get('assignmentUnitID')?.setValue(2)
        console.log("resurskonsult");
        break;
      }
    }
  }

  getUsersInOrganization(pageIndex?: number): Observable<any> {
    this.processing = true;
    return this.userService.getAllInOrganization(Number(this.user.organizationID), pageIndex).pipe(
      map(response => {
        this.processing = false;
        for (const user of response.users) {
          user.organizationTypeID = OrganizationType.broker;
        }
        this.users = response.users;
      },
        (error: any) => {
          this.processing = false;
        }
      )
    );
  }

  getCustomers() {
    this.processing = true;
    this.organizationService.getOrganizationsByType(OrganizationType.customer).subscribe(
      (response: any) => {
        this.customers = response.organizations;
        this.processing = false;
      },
      (error) => {
        this.processing = false;
      }
    );
  }

  onCreateClicked() {
    this.leavePageWarningEnabled = false;
    this.processing = true;
    if (this.picked == null) {
      this.form.value.users.push({ 'userId': this.userID, 'isResponsible': true });
    } else {
      this.form.value.users = this.picked;
    }

    this.assignment = this.form.value;
    this.price = this.assignment.assignmentMaxPrice;

    if (this.selectedCallOffID) {
      this.onSaveClicked();
      return;
    }

    this.assignmentService.create(this.assignment).subscribe(
      (response: any) => {
        this.processing = false;
        this.assignmentId = response;
        this.sendBrokerCallOff(this.price, this.assignmentId);
      },
      (error) => {
        this.processing = false;
        this.leavePageWarningEnabled = true;
      }
    );
  }

  onSaveClicked() {
    this.assignmentService.update(this.selectedAssignmentID, this.assignment).subscribe(
      (response: any) => {
        this.updateBrokerCallOff(this.price, this.selectedAssignmentID);
        this.processing = false;
      },
      (error) => {
        this.processing = false;
      }
    );
  }

  public showUserList(): void {
    this.users = [];

    this.getUsersInOrganization().subscribe(res => {
      if (!this.isCustomer) {
        this.processing = true;
        this.organizationService.getOrganizationsByType(OrganizationType.customer).pipe(
          mergeMap((res: any) => {
            this.customersOrg = res.organizations.find((x: any) => x.customerID == this.customerID);
            return this.userService.getAllInOrganization(Number(this.customersOrg.organizationID))
          })
        ).subscribe(result => {
          for (const user of result.users) {
            user.organizationTypeID = OrganizationType.customer;
          }
          this.users = [...this.users, ...result.users];
          this.dialogRef = this.dialogService.open(UserToAssignmentComponent,
            {
              header: "Val av ansvarig användare",
              width: '70%',
              contentStyle: { "max-height": "800px", "overflow": "auto" },
              closable: false,
              data: {
                users: this.users,
                assignmentId: this.assignmentID,
                picked: this.picked,
                isCustomer: this.isCustomer
              }
            });
          this.dialogRef.onClose.subscribe((usersToAssignment: any) => {
            console.log(usersToAssignment);
            this.picked = [];
            usersToAssignment.forEach((elem: any) => {
              var user = this.users.find(user => user.userID == elem.userID)
              if (elem.isResponsible && user) {
                user.isResponsible = true
              }
              this.picked.push(user);
            })
            this.usersToAssignment = usersToAssignment;
            this.processing = false;
            this.processing = false;
          });
        })
      };
    });
  }

  sendBrokerCallOff(price: number, id: number) {
    this.callOff = {
      assignmentID: id,
      statusID: 5,
      brokerCallOffPrice: price,
      brokerUserID: Number(this.userID),
    };

    this.callOffservice.create(this.callOff).subscribe(
      (response) => {
        this.processing = false;
        this.showCreatedToast();
        this.router.navigate(['/broker-call-off-list']);
      },
      (error) => {
        this.processing = false;
        this.leavePageWarningEnabled = true;
      }
    );
  }
  showCreatedToast() {
    this.messageService.add({
      key: 'main',
      severity: 'success',
      detail: 'Annonsen har skapats'
    })
  }

  showUpdatedToast() {
    this.messageService.add({
      key: 'main',
      severity: 'success',
      detail: 'Annonsen har uppdaterats'
    })
  }


  updateBrokerCallOff(price: number, id: number) {
    this.updateCallOff = {
      statusID: this.statusID,
      assignmentMaxPrice: price,
      brokerUserID: Number(this.userID)
    };

    this.callOffservice.update(this.selectedCallOffID, this.updateCallOff).subscribe(
      (response) => {
        this.processing = false;
        this.showUpdatedToast();
        this.router.navigate(['/broker-call-off-list']);
      },
      (error) => {
        this.processing = false;
      }
    );
  }

  onBackClicked() {
    this.router.navigate(['/broker-call-off-list']);
  }

  createTender(): void {
    const tender: CreateSupplierTender = {
      assignmentID: 1,
      supplierUserID: 2,
      supplierTenderPrice: 850,
      supplierComment: 'Kommnetar',
      isTermsAccepted: true,
      statusID: 5,
      supplierID: 1
    }
    this.supplierTenderService.create(tender).subscribe(res => {
      console.log(res);

    });
  }

  createBrokerTender(): void {
    const tender: CreateBrokerTender = {
      assignmentID: 1,
      supplierTenderID: 4,
      brokerUserID: 4,
      brokerTenderPrice: 875,
      brokerComment: 'hej',
      statusID: 7,
    }
    this.brokerTenderService.create(tender).subscribe(res => {
      console.log(res);
    });
  }
}
