import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthService } from "./auth.service";
import { switchMap } from "rxjs/operators";
import { CreateAssignmentDTO, CreateCustomerCallOffDTO } from "../models/assignment-children/customer-call-off.model";
import { Observable, Subject } from "rxjs";
import { UpdateSupplierTenderStatus } from "../models/assignment-children/supplier-tender.model";

@Injectable({
    providedIn: 'root'
  })

export class CustomerCallOffService {

    constructor (private http: HttpClient, private authService: AuthService) { }

    createAssignment(assignment: CreateAssignmentDTO): Observable<any> {

        return this.http.post("assignment", assignment) 
    };

    createCustomerCallOff(assignmentID: number, customerUserID: number): Observable<any> {
        let callOff: CreateCustomerCallOffDTO = {assignmentID, customerUserID};
        return this.http.post("customercalloff", callOff);
    }

    getCustomerCallOffs(): Observable<any> {
        return this.http.get("customercalloff");
    }

    getCustomerCallOff(customerCallOffId: number): Observable<any> {
        return this.http.get(`customercalloff/${customerCallOffId}`);
    }

    getArchivedCallOffs(organizationID: number): Observable<any> {
        return this.http.get(`customercalloff/archivedcalloffs?organizationID=${organizationID}`);
    }

    update(customerCallOffId: number, updateCustomerCallOff: UpdateSupplierTenderStatus): Observable<any> {
        return this.http.put(`customercalloff/${customerCallOffId}`, updateCustomerCallOff);
    }
}