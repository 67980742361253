import { Injectable, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
  })

  export class FileService implements OnInit{
    endpointBaseURL  = ``;
    constructor(
      private http: HttpClient
    ) 
    { }

    ngOnInit(): void {
    }

    getFileById(id: string): Observable<any> {
        return this.http.get(`file/${id}`,
        {responseType: 'blob'});
    }

    getFileByIdWithToken(id: string, token: string): Observable<any> {
      return this.http.get(`file/${id}/token/${token}`
      ,
      {responseType: 'blob'});
    }
    
  }