import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { PrimeModule } from "src/app/prime-module.ts";
import { BrokerCallOffListComponent } from "./broker-call-off-list/broker-call-off-list.component";
import { BrokerCallOffComponent } from "./broker-call-off.component";
import { CreateBrokerCallOffComponent } from "./create-broker-call-off/create-broker-call-off.component";
import { CreateBrokerCallOffModalComponent } from './create-broker-call-off/create-broker-call-off-modal.component';
import { BrokerCallOffArchiveListComponent } from './broker-call-off-archive-list/broker-call-off-archive-list.component';


@NgModule({
    declarations: [
    BrokerCallOffComponent,
    BrokerCallOffListComponent,
    CreateBrokerCallOffComponent,
    CreateBrokerCallOffModalComponent,
    BrokerCallOffArchiveListComponent
  ],
    imports: [
       PrimeModule,
       CommonModule
    ],
    exports: [
       
    ]
})

export class BrokerCallOffModule {}