<div class="p-grid">
    <div class="p-col-4 p-offset-4">
        <p-card header="Ange Organisationsnummer">
            <form [formGroup]="organizationNumberForm" (ngSubmit)="onApplyClicked()">
                <div class="p-fluid p-grid">
                    <div class="p-field p-col-6">
                        <label for="organizationNumber">Organisationsnummer
                            <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px"
                                pTooltip="Ditt organisationsnummer"></i>
                            </span>
                        </label>
                        <input id="organizationNumber" formControlName="organizationNumber" type="text" [disableControl]="processing" pInputText>
                    </div>
                </div>
                <div class="p-mt-2">
                    <small *ngIf="invalidForm" class="p-error">Ett fel uppstod vid sökningen av leverantören misslyckades. Kontrollera fältet</small>
                </div>
                <div class="p-grid p-mt-4">
                    <div class="p-col-6">
                        <button pButton
                                type="button"
                                icon="pi pi-chevron-left"
                                class="p-button-secondary p-button-raised p-text-uppercase" label="Tillbaka"
                                [disabled]="processing"
                                (click)="onBackClicked()"></button>&nbsp;
                        <button pButton
                                type="submit"
                                
                                iconPos="right"
                                class="p-button p-button-raised p-text-uppercase"
                                label="Nästa"
                                [disabled]="processing"></button>
                    </div>
                </div>
                <div class="p-mt-4" [hidden]="!processing">
                    <p-progressBar mode="indeterminate"></p-progressBar>
                </div>
            </form>
        </p-card>
    </div>
</div>