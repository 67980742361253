import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { UserService } from "../../../services/user.service";
import { AuthService } from "../../../services/auth.service";
import { User } from "../../../models/user/user.model";
import { environment } from "../../../../environments/environment";
import { passwordMatchValidator } from "../../../validators/password-match.validator";
import { Router } from "@angular/router";

@Component({
  selector: "app-user-profile",
  templateUrl: "./user-profile.component.html",
  styles: [],
})
export class UserProfileComponent implements OnInit {
  processing: boolean;
  invalidForm = false;

  userForm: FormGroup;
  user: User;

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private router: Router,
    private authService: AuthService
  ) {}
 
   userId = JSON.parse(this.userService.getUserFromLocalStorage()).userID;

  ngOnInit(): void {
   
    this.initUserForm(<User>{});

    this.fetchUser(this.userId);
  }

  private fetchUser(userId: any) {
    this.processing = true;
    this.userService.get(userId).subscribe(
      (user: User | any) => {
        this.user = user;
        this.initUserForm(user);
        this.processing = false;        
      },
      (error) => {
        this.processing = false;
      }
    );
  }
  onSavedClicked() {
    this.invalidForm = false;

    if (this.processing) {
      return;
    }

    this.processing = true;
  
    this.userService.update(this.userId, this.userForm.value).subscribe(
      (res) => {
        this.processing = false;   
      },
      (error) => {
        this.processing = false;
      }
    );
  }
  onTillbakaClicked() {
    this.router.navigate(['/broker-call-off-list']);
  }

  get f() {
    return this.userForm.controls;
  }

  private initUserForm(user: User) {
    this.userForm = this.formBuilder.group(
      {
        firstName: [
          user?.firstName, [Validators.minLength(2)],
        ],
        lastName: [
          user?.lastName,
          [ Validators.minLength(2)],
        ],
        title: [user?.title],
        email: [user?.email],
        password: [
          "",
          [
            Validators.required,
            Validators.minLength(2),
            Validators.pattern(
              environment.applicationSettings.validation.passwordValidationRegex
            ),
          ],
        ],
        passwordConfirm: [
          "",
          [
            Validators.required,
            Validators.minLength(2),
            Validators.pattern(
              environment.applicationSettings.validation.passwordValidationRegex
            ),
          ],
        ],

        phoneNumber: [user?.phoneNumber],
      },
      { validator: passwordMatchValidator }
    );
  }
}
