import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {OrganizationService} from 'src/app/services/organization.service';
import {UserService} from "../../../services/user.service";
import {passwordMatchValidator} from "../../../validators/password-match.validator";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-check-org-number',
  templateUrl: './apply-check-org-number.component.html',
  styles: [
  ]
})
export class CheckOrgNumberComponent implements OnInit {

  processing: boolean;
  invalidForm: boolean;

  organizationNumberForm: FormGroup;
  errors: string[];


  constructor(private router: Router,
              private formBuilder: FormBuilder,
              private userService: UserService,
              private organizationService: OrganizationService) {
  }

  ngOnInit() {
    this.organizationNumberForm = this.formBuilder.group({
      organizationNumber: ['', [
        Validators.required,
        Validators.minLength(2)]],
    }, {validator: passwordMatchValidator});
  }

  onApplyClicked() {
    this.invalidForm = false;

    if(this.processing) {
      return;
    }

    this.processing = true;

    if(this.organizationNumberForm.invalid) {
      this.invalidForm = true;
      this.processing = false;
      return;
    }
    

    const { organizationNumber } = this.organizationNumberForm.value;

    this.organizationService.checkIfSupplierExists(organizationNumber)
        .subscribe(res => {
          localStorage.setItem('UserHasOrg', res);
          localStorage.setItem('OrgNumber', organizationNumber);
          this.processing = false;
          this.router.navigate(['login/user/check-org-number/apply']);
        }, error => {
          this.processing = false;
        });
  }

  onBackClicked() {
    if(this.processing) {
      return;
    }

    this.router.navigate(['/login']);

    this.toggleProcessing();
  }

  private toggleProcessing(): void {
    this.processing = !this.processing;
  }
}
