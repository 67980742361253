import {Injectable} from "@angular/core";
import {HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel} from '@microsoft/signalr';
import {Subject} from "rxjs";
import {environment} from "../../../environments/environment";
import {Status} from "../../models/status/status.model";

@Injectable({
  providedIn: 'root'
})
export class BaseHubService {
  public connection: HubConnection;
  public endpointBaseURL: string;
  private message$: Subject<string>;

  constructor() {
    this.message$ = new Subject<string>();
  }

  public async init(token: string) {
    this.build(LogLevel.Debug, token);
    await this.start();
  }

  public initListeners(): void {
    console.log("listener");
  }

  public build(logLevel = LogLevel.Debug, token: string) {
    this.connection = new HubConnectionBuilder()
      .withUrl(`${environment.hubUrl}/${this.endpointBaseURL}`,{accessTokenFactory:() => token})
      .withAutomaticReconnect(this.reconnectPolicy())
      .configureLogging(logLevel)
      .build();
  }

  public async connect() {
    if (this.connection.state === HubConnectionState.Disconnected) {
      try {
        let _ = await this.start();
      } catch (e: any) {
        throw new Error(e);
      }
    }
  }

  public async disconnect() {
    if(this.connection){
      if (this.connection.state === HubConnectionState.Connected) {
        await this.connection.stop();
      }
    }
  }

  private async start() {
    try {
      if (this.connection.state === HubConnectionState.Connected)
        return;
      await this.connection.start();
      this.initListeners();
    } catch (e: any) {
      throw new Error(e);
      setTimeout(async () => await this.start(), 6000);
    }
  }

  private reconnectPolicy() {
    return {
      nextRetryDelayInMilliseconds: (retryContext: any) => {
        if (retryContext.elapsedMilliseconds < 60000) {
          // If we've been reconnecting for less than 60 seconds so far,
          // wait between 0 and 10 seconds before the next reconnect attempt.
          return Math.random() * 10000;
        } else {
          // If we've been reconnecting for more than 60 seconds so far, stop reconnecting.
          console.error("SignalR hub connection failed.");
          return null;
        }
      }
    }
  }
}