import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Consultant } from 'src/app/models/consultant/consultant.model';
import { ContractService } from 'src/app/services/contract.service';
import { convertToLocaleStringFromUTC, removeTimeFromDateString } from 'src/app/utils/date';
import { CommentModalComponent } from '../../shared/comment-modal/comment-modal.component';
import { ContractExtensionReceiverResponseDTO } from 'src/app/models/contract/contract.model';
import { MessageService } from 'primeng/api';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-sign-contract-extension',
  templateUrl: './sign-contract-extension.component.html',
  styles: [
  ]
})
export class SignContractExtensionComponent implements OnInit {
token: string;
contractExtensionId: number;
contractExtension: any;
contractConsultant: Consultant;
loaded = false;
dialogRef: DynamicDialogRef;
contractExtensionReceiverResponse: any;
state: string;
processing: boolean = false;
signContractAuthenticated = '/sign-contract-extension';
signContract = '/login/sign-contract-extension/';
convertDate = convertToLocaleStringFromUTC;

  constructor(
    private route: ActivatedRoute,
    private contractService: ContractService,
    private dialogService: DialogService,
    private messageService: MessageService,
    private authService: AuthService,
    private router: Router
    ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.token = params.token;
      this.contractExtensionId = params.contractExtensionID;
      this.state = params.organizationType;
      this.processing = params.processing === 'true';

      if (this.processing) {
        if (this.authService.isAuthenticated()) {
          this.router.navigate([this.signContractAuthenticated], {
            queryParams: {
              processing: 'false',
              token: this.token,
              contractExtensionId: this.contractExtensionId,
              organizationType: this.state,
            }
          });
        } else {
          this.router.navigate([this.signContract], {
            queryParams: {
              processing: 'false',
              token: this.token,
              contractExtensionId: this.contractExtensionId,
              organizationType: this.state,
            }
          }).then(() => {
            window.location.reload();
          });
        }

      }

      this.contractService.getContractExtensionAndConsultantByToken(this.token).subscribe(res => {
        if(res) {
          this.contractExtension = res.contractExtension;
          this.contractExtension.startDate = this.contractExtension.startDate !== null ? this.convertDate(this.getDatestring(this.contractExtension.startDate)): this.contractExtension.startDate;
          this.contractExtension.contractDate = this.contractExtension.contractDate !== null ? this.convertDate(this.getDatestring(this.contractExtension.contractDate)) : this.contractExtension.contractDate;
          this.contractExtension.endDate = this.convertDate(this.getDatestring(this.contractExtension.endDate));
          this.contractConsultant = res.consultant;
          console.log(this);
          this.contractExtensionReceiverResponse = null;
          this.loaded = true;
        } else {
          console.error('Response is empty or in an unexpected format');
        }

      },
      (error) => {
        console.error('An error occurred:', error);
      });
    });
  }

  getDatestring(date: any) {
    return removeTimeFromDateString(date.toString());
  }

  onApproveTerms() {
  let dto: ContractExtensionReceiverResponseDTO = {isContractExtensionAccepted : true, contractExtensionRejectionReason: null};
    this.contractService.updateContractExtensionReceiverResponse(this.token, dto).subscribe(res => {
      if (res) {
        this.showResponseSuccessful();
      }
    })
  }

  onRejectTerms() {
    this.dialogRef = this.dialogService.open(CommentModalComponent, {
      data:{
        title: "Anledning till avböjande"
      }
    })
    this.dialogRef.onClose.subscribe(res => {
      if (res != undefined){
        let dto: ContractExtensionReceiverResponseDTO= {isContractExtensionAccepted : false, contractExtensionRejectionReason: res};
        this.contractService.updateContractExtensionReceiverResponse(this.token, dto).subscribe(res => {
          if (res){
            this.showResponseSuccessful();
          }
        })
      }
    })
  }

  showResponseSuccessful(){
    this.messageService.add({
      key:'main',
      severity: 'success',
      detail: 'Ditt svar har lämnats till Swedish Consulting Group'
    }
    )
  }

}
