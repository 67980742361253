import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {UserService} from "../../../services/user.service";
import {passwordMatchValidator} from "../../../validators/password-match.validator";
import {environment} from "../../../../environments/environment";
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { ErrorModalComponent } from 'src/app/components/shared/error-modal/error-modal/error-modal.component';

@Component({
  selector: 'app-user-supplier',
  templateUrl: './apply-user-supplier.component.html',
  styles: [
  ]
})
export class ApplyUserSupplierComponent implements OnInit {

  processing: boolean;
  invalidForm: boolean;
  hasOrg: boolean;
  userForm: FormGroup;
  errors: string[];

  constructor(private router: Router,
              private formBuilder: FormBuilder,
              private messageService: MessageService,
              private dialogService: DialogService,
              private userService: UserService) {
  }

  ngOnInit() {
    const hasOrgString = localStorage.getItem('UserHasOrg');
    this.hasOrg = hasOrgString === 'true';
    
    this.userForm = this.formBuilder.group({
      firstName: ['', [
        Validators.required,
        Validators.minLength(2)]],
      lastName: ['', [
        Validators.required,
        Validators.minLength(2)],],
      title: [''],
      email: ['', [
        Validators.required,
        Validators.pattern(environment.applicationSettings.validation.emailValidationRegex)]],
      password: ['', [
        Validators.required,
        Validators.minLength(2),
        Validators.pattern(environment.applicationSettings.validation.passwordValidationRegex)]],
      passwordConfirm: ['', [
        Validators.required,
        Validators.minLength(2)],
        Validators.pattern(environment.applicationSettings.validation.passwordValidationRegex)],
      phoneNumber: [''],
      nameOrg: ['', [
        Validators.required,
        Validators.minLength(2)]],
      descriptionOrg: ['', [
        Validators.required,
        Validators.minLength(2)]],
      emailOrg: ['', [
        Validators.required,
        Validators.pattern(environment.applicationSettings.validation.emailValidationRegex)]],
      phoneNumberOrg: ['', [
        Validators.required,
        Validators.minLength(2)]],
      websiteOrg: ['', [
        Validators.required,
        Validators.minLength(2)]],

    }, {validator: passwordMatchValidator});
  }

  onRegisterClicked() {
    this.invalidForm = false;

    if(this.processing) {
      return;
    }

    this.processing = true;

    if(this.userForm.invalid) {
      this.invalidForm = true;
      this.processing = false;
      return;
    }

    const { passwordConfirm, ...user } = this.userForm.value;
    user.organizationNumber = localStorage.getItem('OrgNumber');

    this.userService.apply(user)
        .subscribe(res => {
          this.processing = false;
          this.router.navigate(['/login']);
          this.showCreatedNotification();
        }, error => {
          this.processing = false;
          this.dialogService.open(ErrorModalComponent, { data: { header: 'Kunde ej skapa konto', message: 'Vänligen se över fälten och försök igen.' } });
        });
  }

  showCreatedNotification() {
    this.messageService.add({
      key: 'main',
      severity: 'success',
      detail: 'Kontot har skapats!'
    })
  }

  onBackClicked() {
    if(this.processing) {
      return;
    }

    this.router.navigate(['login/user/check-org-number']);
    this.toggleProcessing();
  }

  private toggleProcessing(): void {
    this.processing = !this.processing;
  }
}
