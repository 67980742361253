

import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {ComponentsModule} from "./components/components.module";
import {environment} from "../environments/environment";
import {BaseUrlInterceptor} from "./interceptors/baseUrl.interceptor";
import {LayoutModule} from "./components/layout/layout.module";
import { NgModule } from '@angular/core';
import {AuthInterceptor} from "./interceptors/auth.interceptor";
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { PdfViewerComponent, PdfViewerModule } from 'ng2-pdf-viewer';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { CanDeactivateGuardService } from './services/can-deactivate-guard.service';

@NgModule({
  declarations: [
    AppComponent   
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ComponentsModule,    
    LayoutModule,
    DynamicDialogModule,
    PdfViewerModule,
    NgxExtendedPdfViewerModule,
    ToastModule,
    MatSnackBarModule
  ],

  providers: [
    {provide: "BASE_API_URL", useValue: environment.apiUrl},
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: BaseUrlInterceptor, multi: true},
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    DialogService,
    PdfViewerComponent,
    MessageService,
    CanDeactivateGuardService
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
