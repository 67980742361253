<div [formGroup]="subscriptionForm">
    <div>
        <div style="margin-bottom: 2px;">
            <p-multiSelect [options]="regions" formControlName="region" defaultLabel="Välj region" appendTo="body" [filter]="false">
            </p-multiSelect>
        </div>
        
        <input type="email" formControlName="email" placeholder="Mailadress:" style="margin-bottom: 2px;"/>


        <!-- Error message for invalid email -->
        <div *ngIf="(subscriptionForm.controls.email.touched && subscriptionForm.controls.email.errors?.required) || errorMessage=='MailRequired'" style="color: red;">
            Ange mailadress.
        </div>

        <div *ngIf="subscriptionForm.controls.email.touched && subscriptionForm.controls.email.errors?.invalidEmail" style="color: red;">
            Ange korrekt mailadress.
        </div>
        
        <div>
            <button pButton type="button" iconPos="right"
            class="p-button p-button-raised p-text-uppercase" label="Prenumerera" 
            (click)="addEmailToSubscriptionsList()">
            </button>
        </div>
    </div>
</div>
