<p-contextMenu #cm [model]="items"></p-contextMenu>
<p-card header="Mallar ">
    <p-toolbar styleClass="p-mb-4">
        <ng-template styleClass="p-mb-4" pTemplate="left">
            <button (click)="onCreateOrganizationTemplateClicked()" pButton pTooltip="Skapa ny mall"
                tooltipPosition="top" class="p-button-raised p-button p-text-uppercase"
                label="Skapa"></button>
        </ng-template>
    </p-toolbar>
    <p-table [value]="organizationTemplateList" [lazy]="true" (onLazyLoad)="loadTemplates($event)" [paginator]="true"
        [rows]="this.pageSize" [totalRecords]="records" [loading]="loading" [(contextMenuSelection)]="selectedTemplate"
        [contextMenu]="cm" dataKey="organizationTemplateID"
        [globalFilterFields]="['brokerUserName', 'customerName', 'assignmentReference','header', 'startDate']">
        <ng-template pTemplate="header">
            <tr>
                <th style="width: 18%" pSortableColumn="header">Mall ID
                </th>
                <th style="width: 18%" pSortableColumn="assignmentReference">Namn
                </th>
                <th style="width: 18%" pSortableColumn="startDate">EventID
                </th>
                <th style="width: 8rem" class="p-text-right"></th>
            </tr>
            <tr>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-organizationTemplate>
            <tr [pContextMenuRow]="organizationTemplate" (click)="onOrganizationTemplateClicked(organizationTemplate)">
                <td>{{organizationTemplate.organizationTemplateID}}</td>
                <td>{{organizationTemplate.name}}</td>
                <td>{{organizationTemplate.eventID}}</td>
                <td>
                    <button pButton label="Redigera"
                        (click)="onEditOrganizationClicked(organizationTemplate)">
                    </button>
                </td>
            </tr>
        </ng-template>
    </p-table>
</p-card>