import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { BrokerTender } from 'src/app/models/assignment-children/broker-tender.model';
import { CreateContract, CreateContractExtension } from 'src/app/models/contract/contract.model';
import { BrokerTenderService } from 'src/app/services/broker-tender.service';
import { ContractService } from 'src/app/services/contract.service';
import { ResumeService } from 'src/app/services/resume.service';
import { ErrorModalComponent } from '../../shared/error-modal/error-modal/error-modal.component';
import { Location } from '@angular/common';
import { ConsultantService } from 'src/app/services/consultant.service';
import { Consultant, ConsultantListItem } from 'src/app/models/consultant/consultant.model';
import { OrganizationService } from 'src/app/services/organization.service';
import { mergeMap } from 'rxjs/operators';
import { Organization } from 'src/app/models/organization/organization.model';
import { sortAlphabetically } from 'src/app/utils/array-sorting';
import { convertToLocaleStringFromUTC, removeTimeFromDateString } from 'src/app/utils/date';

@Component({
  selector: 'app-contract-extension-create',
  templateUrl: './contract-extension-create.component.html',
  styles: [
  ]
})
export class ContractExtensionCreateComponent implements OnInit {

  form: FormGroup;
  errors: string[];
  processing: boolean;
  invalidForm: boolean
  // minimumDate = new Date();
  contract: CreateContractExtension
  selectedContractId: number;
  brokerTenderToContract: BrokerTender;
  state: string = 'create';
  currentConsultant: Consultant;
  selectedConsultant: Consultant;
  supplierOrg: Organization
  consultants: ConsultantListItem[] = [];
  showConsultants: boolean = false;
  loaded: boolean = false;
  convertDate = convertToLocaleStringFromUTC;

  @Input() inputContractId: number;
  

  constructor(
    private formBuilder: FormBuilder,
    private contractService: ContractService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private brokerTenderService: BrokerTenderService,
    private resumeService: ResumeService,
    private messageService: MessageService,
    private dialogService: DialogService,
    public modalRef: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private consultantService: ConsultantService,
    private organizationService: OrganizationService
  ) { }

  ngOnInit(): void {

    if(this.config !== undefined && this.config.data.contractId !== undefined){
      this.fetchContract(Number(this.config.data.contractId));
      // this.contractService.get(this.config.data.contractId).subscribe(res => {
      //   console.log(res);
      //   this.state = 'createExtension';
      //   this.selectedContractId = Number(this.config.data.contractID);
      //   this.fetchContract(Number(this.config.data.contractID));
      // })
    }

    if(this.inputContractId !== undefined){
      console.log("fungerar");
    }

    this.form = this.formBuilder.group({
      brokerTenderID: ["",[]],
      contractDate: ["",[Validators.required]],
      contractExtensionName: ['',[Validators.required]],
      consultantId: ['', [Validators.required]],
      changesInText: ['', []],
      margin: [0,[]],
      priceComment: ["",[]],
      customerContractReference: ["",[Validators.required]],
      supplierContractReference: ["",[Validators.required]],
      customerPrice: [0,[Validators.required]],
      supplierPrice: [0,[Validators.required]],
      frameWorkPrice: [0,[]],
      assignmentExtent: ["",[]],
      startDate: ["",[Validators.required]],
      endDate: ["",[Validators.required]],
      // consultantID: [1 ,[Validators.required]],
    });

    this.route.params.subscribe(params => {
      if (params?.contractID) {
        this.selectedContractId = Number(params.contractID);
        this.fetchContract(Number(params.contractID));
      }
      if (params?.brokerTenderID) {
        this.brokerTenderService.get<BrokerTender>(Number(params.brokerTenderID)).subscribe(res => {
          this.brokerTenderToContract = res;
          this.resumeService.getResume(this.brokerTenderToContract.resumes[0].resumeID).subscribe(res => {
            this.form.controls.consultantId.patchValue(res.consultant.consultantID);
          }, error => {
            console.log(error);
          })
        })
      }
  });
  }

  getMargin() {
    var cprice = this.form.get('customerPrice')?.value;
    var sprice = this.form.get('supplierPrice')?.value;
    this.form.get('margin')?.setValue(cprice - sprice);
    
  }

  fetchContract(contractId: number): void {
    this.contractService.get<any>(contractId).subscribe((res: any) => {
      
      res.startDate = res.startDate !== null ? this.convertDate(this.getDatestring(res.startDate)): res.startDate;
      res.contractDate = res.contractDate !== null ? this.convertDate(this.getDatestring(res.contractDate)) : res.contractDate;
      res.endDate = this.convertDate(this.getDatestring(res.endDate));

      this.form.get('assignmentID')?.setValue(res.assignmentID);
      this.form.get('contractDate')?.setValue(new Date(res.contractDate));
      this.form.get('contractName')?.setValue(res.contractName);
      this.form.get('margin')?.setValue(res.margin);
      this.form.get('priceComment')?.setValue(res.priceComment);
      this.form.get('customerContractReference')?.setValue(res.customerContractReference);
      this.form.get('customerPrice')?.setValue(res.customerPrice);
      this.form.get('supplierContractReference')?.setValue(res.supplierContractReference);
      this.form.get('supplierPrice')?.setValue(res.supplierPrice);
      this.form.get('frameWorkPrice')?.setValue(res.frameworkPrice);
      this.form.get('assignmentExtent')?.setValue(res.assignmentExtent);
      this.form.get('startDate')?.setValue(new Date(res.startDate));
      this.form.get('endDate')?.setValue(new Date(res.endDate));
      console.log(res);
      this.consultantService.get<Consultant>(res.consultantID).subscribe(res => { //mergeMap this request and show consultant props in html
        console.log(res);
        this.currentConsultant = res;
        this.form.get('consultantId')?.setValue(res.consultantID);
        this.loaded = true;
      })
    })
  }

  onCreateClicked() {
    console.log(this.form.value);
    if (this.form.invalid) {
      this.invalidForm = true;
      return;
    }

    this.processing = true;
    this.contract = this.form.value;
    this.contract.contractId = this.config.data.contractId;
    this.contract.consultantId = this.currentConsultant.consultantID;
    if (this.selectedContractId) {
      this.onSaveClicked();
      return
    }

    this.contractService.createContractExtension(this.contract).subscribe((res: any) => {
      this.processing = false;
      this.showCreatedToast();
      this.router.navigate([`/contract/${this.contract.contractId}`]);
      this.modalRef.close(true);
    },
    (error) => {
      this.processing = false;
      this.dialogService.open(ErrorModalComponent,
        {
          header: "Något gick fel",
          data: {
            message:"Misslyckades att skapa kontraktet. Vänligen försök igen."
          }
        })
    })
  }

  onConsultantChange(event: any) {
    console.log(event);
    this.consultantService.get<Consultant>(Number(event.value)).subscribe(res => {
      this.currentConsultant = res;
    })
  }

  onGetConsultants() {
    console.log(this);

    this.organizationService.getOrganizationWithSupplierId(this.currentConsultant.supplierID).pipe(mergeMap((res: any) => {
      console.log(res);
      this.supplierOrg = res
      return this.consultantService.getConsultantsByOrganization(this.currentConsultant.supplierID)
    })
    ).subscribe(res => {
      this.showConsultants = true;
      console.log(res);
      this.consultants = res.consultants;
      this.consultants = sortAlphabetically(this.consultants, 'firstName');
      console.log(this.consultants);
    })

    // this.organizationService.getOrganizationWithSupplierId(this.currentConsultant.supplierID).subscribe(res => {
    //   console.log(res);
    // })
  }

  onSaveClicked() {
    this.contractService.update(this.selectedContractId, this.contract).subscribe(res => {
      this.processing = false;
      this.showUpdatedToast();
      this.router.navigate(['/contract-list']);
    },
    (error) => {
      this.processing = false;
      this.dialogService.open(ErrorModalComponent,
        {
          header: "Något gick fel",
          data: {
            message:"Misslyckades att uppdatera kontraktet. Vänligen försök igen."
          }
        })
    });
  }

  getDatestring(date: any) {
    return removeTimeFromDateString(date.toString());
  }

  onBackClicked(): void {
    // this.location.back();
    this.modalRef.close(false);
  }

  showCreatedToast() {
    this.messageService.add({
      key:'main',
      severity:'success',
      detail:'Kontraktet har skapats'
    })
  }

  showUpdatedToast() {
    this.messageService.add({
      key:'main',
      severity:'success',
      detail:'Kontraktet har uppdaterats'
    })
  }
}
