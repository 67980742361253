import {NgModule} from '@angular/core';
import {CommonModule} from "@angular/common";
import { ErrorModalComponent } from './error-modal/error-modal/error-modal.component';
import { FileReaderComponent } from './file-reader/file-reader.component';
import { PdfViewerComponent, PdfViewerModule } from 'ng2-pdf-viewer';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { CommentModalComponent } from './comment-modal/comment-modal.component';
import { PrimeModule } from 'src/app/prime-module.ts';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { SuccessToastComponent } from './success-toast/success-toast.component';
import { ToastModule } from 'primeng/toast';
import { UserSelectDialogComponent } from './user-select-dialog/user-select-dialog.component';
import { SupplierTenderInfoModalComponent } from './supplier-tender-info-modal/supplier-tender-info-modal.component';


@NgModule({
    declarations: [
    ErrorModalComponent,
    FileReaderComponent,
    CommentModalComponent,
    FileUploadComponent,
    ConfirmationDialogComponent,
    SuccessToastComponent,
    UserSelectDialogComponent,
    SupplierTenderInfoModalComponent
  ],
    imports: [
        CommonModule,
        PdfViewerModule,
        NgxExtendedPdfViewerModule,
        PrimeModule,
        ToastModule
    ],
    exports: [
      FileUploadComponent
    ]
})

export class SharedComponentsModule { }
