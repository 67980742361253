<div class="p-grid p-justify-center" *ngIf="!processing">
  <div class="p-col-12 p-lg-7">
      <p-card>
        <form [formGroup]="form" (ngSubmit)="onSubmit()" novalidate>
          <div class="p-field p-col-6">
            <div class="p-field">
              <label for="brokerTenderPrice">Pris</label>
            </div>
            <input type="text" id="brokerTenderPrice" name="brokerTenderPrice" formControlName="brokerTenderPrice" pInputText>
          </div>
            <div *ngIf="brokerTenderPrice != null">
              <div *ngIf="brokerTenderPrice.invalid">
                <p>Ogiltigt pris</p>
              </div>
            </div>
  
            <div class="p-field p-col-6">
              <div class="p-field">
                <label for="brokerComment">Kommentar </label>
              </div>
              <input type="text" id="brokerComment" name="brokerComment" formControlName="brokerComment" pInputText>
            </div>  
            <div class="p-mt-2">
              <small *ngIf="invalidForm" class="p-error">Misslyckades skapa anbud. Kontrollera fälten</small>
            </div>
            <div class="p-grid p-mt-4">
              <div class="p-col-6">
                <button pButton type="button" 
                  class="p-button p-button-raised p-text-uppercase" label="Avbryt" (click)="onCancel()"></button>
              </div>
              <div class="p-col-6">
                <button style="float: right;" pButton type="submit" iconPos="right" class="p-button p-button-raised p-text-uppercase" label="Skapa anbud"></button>
              </div>
            </div>
            
          </form>
            
        </p-card>
    </div>
  </div>