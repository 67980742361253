import { Injectable } from "@angular/core"
import { settings } from "cluster"
import { Observable } from "rxjs"
import { createOrganizationSettings, organizationSettings } from "../models/organization-settings/organization-settings"
import { organizationSettingsList } from "../models/organization-settings/organization-settings-list"
import { updateOrganizationSettings } from "../models/organization-settings/update-organization-settings"
import { BaseService } from "./base.service"


@Injectable({
    providedIn: 'root'
  })
  
  export class OrganizationSettingsService extends BaseService<organizationSettings, organizationSettingsList, createOrganizationSettings, updateOrganizationSettings>
  {
    endpointBaseURL = 'organizationsettings'
  
    updateOrganizationSettings(OrganizationSettingsID: number, updateDTO: updateOrganizationSettings): Observable<any> {
        return this.http.put<any>(`organizationsettings/${OrganizationSettingsID}`,updateDTO);
    }
}