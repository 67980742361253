import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { CreateFile } from "../models/file/create-file.model";
import { File } from "../models/file/file.model";
import { FileList } from "../models/file/file-list.model";
import { UpdateFile } from "../models/file/update-file.model";
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class FileService extends BaseService <File, FileList, CreateFile, UpdateFile>{
  endpointBaseURL = "file" 

  getFileById(id: string): Observable<any> {
    return this.http.get(`file/${id}`,
    {responseType: 'blob'});
}

getFileByIdWithToken(id: string, token: string): Observable<any> {
  return this.http.get(`file/${id}/token/${token}`,
  {responseType: 'blob'});
}
}
