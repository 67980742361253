import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";


export interface CanComponentDeactivate {
    canDeactivate: (confirmationMessage: string) => Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree;
  }
  
  @Injectable({
    providedIn: 'root',
  })
  export class CanDeactivateGuardService
  implements CanDeactivate<CanComponentDeactivate>
{
    canDeactivate(
        component: CanComponentDeactivate,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState?: RouterStateSnapshot
      ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const confirmationMessage = 'Dina ändringar kommer inte sparas. Vill du fortsätta?';
        return component.canDeactivate ? component.canDeactivate(confirmationMessage) : true;
      }
    }