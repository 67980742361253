import { Injectable } from '@angular/core';
import {BaseService} from "./base.service";
import {Role} from "../models/role/role.model";
import {RoleList} from "../models/role/role-list.model";
import {UpdateRole} from "../models/role/update-role.model";
import {CreateRole} from "../models/role/create-role.model";

@Injectable({
    providedIn: 'root'
})
export class RoleService extends BaseService<Role, RoleList, CreateRole, UpdateRole> {
    endpointBaseURL = 'api/v1/roles'
}
