<ng-container *ngIf="loaded">
    <p-card header="Avtal">
        <div style="display: flex; justify-content: start; font-size: 16px;">
            <div style="width: 400px;">
              <p>Rubrik: <b>{{contractExtension.contractExtensionName}}</b></p>
              <p>Konsult: <b>{{ contractConsultant.firstName }} {{contractConsultant.lastName}}</b></p>
              <p>Startdatum: <b>{{ getDatestring(contractExtension.startDate) | date: 'dd/MM/yyyy'}}</b></p>
              <p>Slutdatum: <b>{{ getDatestring(contractExtension.endDate) | date: 'dd/MM/yyyy'}}</b></p>
            </div>
            
            <div style="width: 400px;">
                <p *ngIf="state === 'customer'">Pris Kund: <b>{{contractExtension.customerPrice}}</b></p>
                <p *ngIf="state === 'supplier'">Pris Leverantör: <b>{{contractExtension.supplierPrice}}</b></p>
              <p>Ändringar: <b [innerHTML]="contractExtension.changesInText"></b></p>
            </div>
        </div>
        <div class="p-grid p-mt-4">
          <div class="p-col-6" style="text-align:center; margin-top:50px;">
            <button pButton class="p-button p-button-raised p-text-uppercase" label="Godkänn villkor" (click)="onApproveTerms()"></button>
          </div>
          <div class="p-col-4" style="text-align:center-right; margin-top:50px;">
            <button pButton class="p-button p-button-raised p-text-uppercase" label="Avböj villkor" (click)="onRejectTerms()"></button>
          </div>
        </div>
    </p-card>
    </ng-container>
    
    