import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Form, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from "../../../services/auth.service";
import { UserAuthenticateResponse } from 'src/app/models/user/user-authenticate-response.model';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Organization } from 'src/app/models/organization/organization.model';
import { OrganizationService } from 'src/app/services/organization.service';
import { ErrorModalComponent } from '../../shared/error-modal/error-modal/error-modal.component';
import { OrganizationType } from 'src/app/models/organization-type-enum.model';
import { User } from 'src/app/models/user/user.model';
import { Observable } from 'rxjs';
import { UserService } from 'src/app/services/user.service';
import { map } from 'rxjs/operators';
import { CustomerBrokerUserModalComponent } from '../customer-broker-user-modal/customer-broker-user-modal.component';
import { Role } from 'src/app/models/role/role.enum';

@Component({
  selector: 'app-create-organization',
  templateUrl: './create-organization.component.html',
  styles: [
  ],
  providers: [OrganizationService]
})
export class CreateOrganizationComponent implements OnInit {

  state: String;
  processing: boolean;
  leavePageWarningEnabled: boolean = true;
  invalidForm: boolean;
  header: string;
  buttonLabel: string;
  user: UserAuthenticateResponse;
  users: User[] = [];
  isBrokerUser: boolean = false;
  form: FormGroup;
  errors: string[];
  selectedOrganizationId: number;
  customerOrganizationType: boolean;
  responsibleBrokerUser: User;
  customer: any;
  dialogRef: DynamicDialogRef;
  userIsAdmin: boolean;

  @Input() isSupplier: boolean;
  @Input() passedOrganizationId: number;
  @Output() supplierCreated: EventEmitter<Organization> = new EventEmitter();

  constructor(
    private builder: FormBuilder,
    private organizationService: OrganizationService,
    private router: Router,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private dialogService: DialogService,
    public authService: AuthService,
    private userservice: UserService
  ) { }

  ngOnInit(): void {
    this.user = this.authService.getUserFromStorage();
    this.userIsAdmin = Number(this.user.role) >= Role.Admin;

    if (Number(this.user.organizationTypeID) === OrganizationType.broker) {
      this.isBrokerUser = true;
    }

    this.form = this.builder.group({
      organizationType: ["", [Validators.required]],
      responsibleBroker: [""],
      name: ["", [Validators.required]],
      organizationNumber: ["", [Validators.required]],
      description: ["", [Validators.required]],
      email: ["", [Validators.required]],
      phoneNumber: ["", [Validators.required]],
      website: ["", [Validators.required]],
      activated: [false]
    });

    if (this.passedOrganizationId === undefined) {
      this.route.params.subscribe(params => {
        if (params?.organizationId) {
          this.selectedOrganizationId = Number(params.organizationId);
        }
        if (this.selectedOrganizationId && !isNaN(this.selectedOrganizationId)) {
          this.fetchOrganization(Number(this.selectedOrganizationId));
        }
        else {
          console.error("Invalid or missing organizationId:", this.selectedOrganizationId);
        }
      });

      if (this.selectedOrganizationId) {
        this.header = "Redigera organisation";
        this.buttonLabel = "Spara";
      }
      else {
        this.header = "Skapa organisation";
        this.buttonLabel = "Skapa";
      }
    }

    else {
      this.header = "";
      this.buttonLabel = "Spara";
      this.fetchOrganization(this.passedOrganizationId);
    }
  }

  fetchOrganization(organizationID: number): void {
    this.processing = true;
    this.organizationService.get(organizationID).subscribe(
      (response: any) => {
        this.form.get('organizationType')?.setValue(response.organizationTypeID);
        this.form.get('name')?.setValue(response.organizationName);
        this.form.get('organizationNumber')?.setValue(response.organizationNumber);
        this.form.get('description')?.setValue(response.organizationDescription);
        this.form.get('email')?.setValue(response.email);
        this.form.get('phoneNumber')?.setValue(response.phoneNumber);
        this.form.get('website')?.setValue(response.website);
        this.form.get('activated')?.setValue(response.isActivated);

        if (this.isBrokerUser) {
          if (this.form.value["organizationType"] == OrganizationType.customer) {
            this.getCustomer(this.selectedOrganizationId);
            this.customerOrganizationType = true;
          }
        }
        this.processing = false;
      },
      (error) => {
        this.processing = false;
      }
    );
  }

  getCustomer(organizationID: number): void {
    this.organizationService.getCustomerByOrganizationID(organizationID).subscribe(
      (res: any) => {
        this.customer = res;

        if (this.customer.responsibleBrokerUser != null) {
          this.form.get('responsibleBroker')?.setValue(this.customer.responsibleBrokerUser.fullName);
          this.responsibleBrokerUser = this.customer.responsibleBrokerUser;
        }
        else {
          this.form.get('responsibleBroker')?.setValue("Det finns ingen ansvarig mäklare");
        }
      });
  }

  onCreateClicked() {
    this.invalidForm = false;

    if (this.processing) {
      return;
    }

    this.processing = true;
    this.leavePageWarningEnabled = false;

    if (this.form.invalid) {
      this.invalidForm = true;
      this.processing = false;
      return;
    }

    if (this.selectedOrganizationId) {
      this.onSaveClicked();
      return;
    }

    this.organizationService.create({
      organizationTypeID: this.form.value["organizationType"],
      organizationName: this.form.value["name"],
      organizationNumber: this.form.value["organizationNumber"],
      organizationDescription: this.form.value["description"],
      email: this.form.value["email"],
      phoneNumber: this.form.value["phoneNumber"],
      website: this.form.value["website"],
      isActivated: this.form.value["activated"],
    }).subscribe(response => {
      this.processing = false;
      this.showCreatedToast();
      !this.isSupplier ? this.router.navigate(["/organizations"])
        : this.supplierCreated.emit(response);
    },
      error => {
        this.processing = false;
        this.leavePageWarningEnabled = true;
        this.dialogService.open(ErrorModalComponent,
          {
            header: "Något gick fel",
            data: {
              message: "Misslyckades att skapa organisationen. Vänligen försök igen."
            }
          })
      });
  };

  onSaveClicked() {
    this.organizationService.update(this.selectedOrganizationId, {
      organizationTypeID: this.form.value["organizationType"],
      organizationName: this.form.value["name"],
      organizationNumber: this.form.value["organizationNumber"],
      organizationDescription: this.form.value["description"],
      email: this.form.value["email"],
      phoneNumber: this.form.value["phoneNumber"],
      website: this.form.value["website"],
      isActivated: this.form.value["activated"],
    }
    ).subscribe(response => {
      this.processing = false;
      this.showUpdatedToast()


      if (this.isBrokerUser) {
        this.router.navigate(["/organizations"]);
      }

    },
      error => {
        this.processing = false;
        this.leavePageWarningEnabled = true;
        this.dialogService.open(ErrorModalComponent,
          {
            header: "Något gick fel",
            data: {
              message: "Misslyckades att uppdatera organisationen. Vänligen försök igen."
            }
          })
      });
  }

  getBrokersInOrganization(): Observable<any> {
    this.processing = true;
    return this.userservice.getAllInOrganization(Number(this.user.organizationID)).pipe(
      map(response => {
        this.processing = false;
        this.users = response.users;
      },
        (error: any) => {
          console.error(error);
          this.processing = false;
        }
      )
    )
  }

  onEditResponsibleBrokerClicked() {
    this.getBrokersInOrganization().subscribe(res => {
      this.dialogRef = this.dialogService.open(CustomerBrokerUserModalComponent, {
        header: "Val av ansvarig mäklare",
        width: '40%',
        contentStyle: { "maz-height": "800px", "overflow": "auto" },
        data: {
          users: this.users,
          responsibleBrokerUser: this.responsibleBrokerUser,
          customer: this.customer,
        }
      })
    })
    return this.dialogRef.onClose
  }

  onBackClicked() {
    if (this.processing) {
      return;
    }
    this.router.navigate(["/organizations"]);
  };

  showCreatedToast() {
    this.messageService.add({
      key: 'main',
      severity: 'success',
      detail: 'Organisationen har skapats'
    })
  }

  canDeactivate(confirmationMessage: string): boolean | Observable<boolean> | Promise<boolean> {
    if (this.leavePageWarningEnabled) {
      if (this.form.dirty) {
        return window.confirm(confirmationMessage);
      }
    }
    return true;
  }

  showUpdatedToast() {
    this.messageService.add({
      key: 'main',
      severity: 'success',
      detail: 'Organisationen har uppdaterats'
    })
  }
}
