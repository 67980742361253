<div *ngIf="processing==false">
  <ng-container *ngIf="this.userTypeID == OrganizationType.customer; else notCustomer">
<form [formGroup]="form" (ngSubmit)="onSubmit()" novalidate>

  <div class="p-fluid p-grid">
    <div class="p-field p-col-6">
      <div class="p-field">
        <label>Ämne</label>
      </div>
      <input type="text" id="notificationSubject" name="notificationSubject" formControlName="notificationSubject" pInputText>
    </div>
  </div>

<p-editor formControlName="notificationContent" [style]="{'height':'320px'}">    
</p-editor>
<div class="p-mt-2">
  <small *ngIf="invalidForm" class="p-error">Misslyckades skicka e-post. Kontrollera fälten</small>
</div>
<div class="p-grid p-mt-4">
  <div class="p-col-6">
    <button pButton type="button"  
    class="p-button p-button-raised p-text-uppercase" label="Avbryt" (click)="onCancel()"></button>
  </div>
  <div class="p-col-6">
    <button pButton type="submit" iconPos="right" style="float: right;"
      class="p-button p-button-raised p-text-uppercase" label="Skicka epost" [disabled]="(processing) "></button>
  </div>
</div>
</form>
</ng-container>
<ng-template #notCustomer>
  <div style="color: red;">
    This page is only visible for customers.
  </div>
</ng-template>
</div>