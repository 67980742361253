<div class="p-grid p-justify-center">
    <div class="p-col-12 ">
        <p-card header="Organisationer">
            <p-toolbar styleClass="p-mb-4">
                <ng-template styleClass="p-mb-4" pTemplate="left">
                    <button (click)="onCreateOrganizationClicked()" pButton pTooltip="Skapa ny organisation"
                        tooltipPosition="top"  class="p-button-raised p-button p-text-uppercase"
                        label="Skapa"></button>
                </ng-template>
            </p-toolbar>
            <p-table [value]="organizations" [loading]="loading" sortMode="multiple" (onLazyLoad)="onLoad($event)"
            [paginator]="true" [rows]="size" [totalRecords]="records">
                <ng-template pTemplate="header">
                    <tr>
                        <th pSortableColumn="organizationName">
                            Namn
                            <p-sortIcon field="organizationName"></p-sortIcon>
                        </th>
                        <th pSortableColumn="organizationTypeID" >
                            Organisationstyp
                            <p-sortIcon field="organizationTypeID"></p-sortIcon>
                        </th>
                        <th pSortableColumn="email">
                            E-Post
                            <p-sortIcon field="email"></p-sortIcon>
                        </th>
                        <th pSortableColumn="phoneNumber">
                            Telefonnummer
                            <p-sortIcon field="phoneNumber"></p-sortIcon>
                        </th>
                        <th pSortableColumn="isActivated">
                            Aktiverad
                            <p-sortIcon field="isActivated"></p-sortIcon>
                        </th>
                        <th></th>
                    </tr>
                    <tr>
                        <th>
                            <p-columnFilter
                                    [matchModeOptions]="settings.defaultTextMatchMode"
                                    [matchMode]="'contains'"
                                    type="text"
                                    field="organizationName">
                            </p-columnFilter>
                        </th>
                        <th>
                            <p-columnFilter
                                    [matchModeOptions]="settings.defaultTextMatchMode"
                                    [matchMode]="'contains'"
                                    type="text"
                                    field="organizationTypeID">
                            </p-columnFilter>
                        </th>
                        <th>
                            <p-columnFilter
                                    [matchModeOptions]="settings.defaultTextMatchMode"
                                    [matchMode]="'contains'"
                                    type="text"
                                    field="email">
                            </p-columnFilter>
                        </th>
                        <th>
                            <p-columnFilter
                                    [matchModeOptions]="settings.defaultTextMatchMode"
                                    [matchMode]="'contains'"
                                    type="text"
                                    field="phoneNumber">
                            </p-columnFilter>
                        </th>
                        <th>
                            <p-columnFilter
                                    [matchModeOptions]="settings.defaultTextMatchMode"
                                    [matchMode]="'contains'"
                                    type="text"
                                    field="isActivated">
                            </p-columnFilter>
                        </th>
                        <th></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-item>
                    <tr>
                        <td>
                            {{item.organizationName}} 
                        </td>
                        <td>
                            {{item.organizationTypeName}}
                        </td>
                        <td>
                            {{item.email}}
                        </td>
                        <td>
                            {{item.phoneNumber}}
                        </td>
                        <td>
                            {{item.isActivated}}
                        </td>
                        <td>
                            <button pButton label="Redigera"
                                (click)="onEditOrganizationClicked(item)">
                            </button>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </p-card>
    </div>
</div>