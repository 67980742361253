import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { UserService } from "src/app/services/user.service";
import { OrganizationService } from "src/app/services/organization.service";
import { OrganizationType } from 'src/app/models/organization-type-enum.model';
import { Organization } from 'src/app/models/organization/organization.model';
import { AuthService } from "src/app/services/auth.service";
import { User } from "src/app/models/user/user.model";
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CreateUserToAssignmentDTO } from 'src/app/models/assignment/assignment.model';
import { UserToAssignmentComponent } from 'src/app/components/user-to-assignment/user-to-assignment/user-to-assignment.component';
import { CreateAssignment } from "src/app/models/assignment/create-assignment.model";
import { AssignmentService } from "src/app/services/assignment.service";
import { CreateCustomerCallOffDTO } from 'src/app/models/assignment-children/customer-call-off.model';
import { CustomerCallOffService } from "src/app/services/customer-call-off.service";
import { Router } from "@angular/router";
import { map, mergeMap } from 'rxjs/operators';
import { AssignmentTemplateService } from 'src/app/services/assignment-template-service';
import { MessageService } from 'primeng/api';
import { ErrorModalComponent } from 'src/app/components/shared/error-modal/error-modal/error-modal.component';
import { NotificationService } from 'src/app/services/notification.service';
import { NotificationReceiverDTO, Notification } from 'src/app/models/notification/notification.model';
import { getTemplateFooter } from 'src/app/utils/template-footer';
import { UtilService } from 'src/app/services/util.service';
import { generateContentString } from 'src/app/utils/template-string-replace';
import { Observable } from 'rxjs';
import { sortAlphabetically } from 'src/app/utils/array-sorting';

@Component({
  selector: 'app-create-customer-call-off',
  templateUrl: './create-customer-call-off.component.html',
  styles: [
  ]
})
export class CreateCustomerCallOffComponent implements OnInit {
  form: FormGroup;
  userID = JSON.parse(this.userService.getUserFromLocalStorage()).userID;
  orgID = JSON.parse(this.userService.getUserFromLocalStorage()).organizationID;
  // id: number = parseInt(this.userID);
  processing: boolean;
  leavePageWarningEnabled: boolean = true;
  customers: Organization[] = [];
  invalidForm: boolean;
  selectedCallOffID: number;
  isCustomer: boolean;
  user = this.authService.getUserFromStorage();
  users: User[] = [];
  picked: any[] = [];
  assignmentID: number;
  usersToAssignment: CreateUserToAssignmentDTO[] = [];
  assignment: CreateAssignment;
  price: number;
  assignmentId: number;
  callOff: CreateCustomerCallOffDTO;
  selectedAssignmentID: number;
  statusID: number;
  customerID: number;
  selectedOrganizationID: number;
  customersOrg: any;
  dialogRef: DynamicDialogRef;
  showHoverList: boolean;
  notificationSubject: string;
  notificationContent: string;
  notificationReceivers: any[];
  account: any;

  constructor(private formBuilder: FormBuilder,
    private userService: UserService,
    private organizationService: OrganizationService,
    private authService: AuthService,
    private dialogService: DialogService,
    private assignmentService: AssignmentService,
    private router: Router,
    private callOffService: CustomerCallOffService,
    private assignmentTemplateService: AssignmentTemplateService,
    private messageService: MessageService,
    private notificationService: NotificationService,
    private utilService: UtilService
  ) { }

  ngOnInit(): void {
    // this.selectedOrganizationID = 0;
    if (Number(this.user.organizationTypeID) === OrganizationType.customer) {
      this.isCustomer = true
      this.selectedOrganizationID = this.orgID;
      this.organizationService.getCustomerIDByOrganization(this.selectedOrganizationID).subscribe(res => {
        this.customerID = res;
      }
      )
      this.getAssignmentTemplate();
    }
    if (Number(this.user.organizationTypeID) === OrganizationType.broker) {
      this.isCustomer = false

      this.organizationService.getOrganizationsByType(OrganizationType.customer).subscribe(
        (response: any) => {
          this.customers = response.organizations;
          this.selectedOrganizationID = this.customers[0].organizationID;
          this.customerID = this.customers[0].customerID;
        },
        (error) => {
          this.dialogService.open(ErrorModalComponent,
            {
              header: "Något gick fel",
              data: {
                message: "Kunde inte hitta kundobjekt"
              }
            })
        }
      );
    }

    this.form = this.formBuilder.group({
      customerID: [""],
      organizationID: [""],
      header: ["", [Validators.required]],
      assignmentReference: ["", [Validators.required]],
      assignmentTypeID: ["", [Validators.required]],
      priceTypeID: ["", [Validators.required]],
      assignmentEstimate: ["", [Validators.required]],
      assignmentUnitID: ["", [Validators.required]],
      multipleResources: [null, []],
      assignmentMaxPrice: ["", [Validators.required]],
      startDate: ["", [Validators.required]],
      endDate: ["", [Validators.required]],
      description: ["", [Validators.required]],
      assignmentLocationTypeID: [, [Validators.required]],
      assignmentLocation: ["", [Validators.required]],
      brokerID: [1],
      userID: [0],
      users: [[]],
      assignmentTemplateID: [0],
    });

    this.utilService.getAccount().subscribe(res => {
      this.account = res;
    })

    this.userService.get(this.userID).subscribe(res => {
      this.picked.push(res);
      this.picked[0].isResponsible = true;
      this.picked[0].organizationTypeID = this.user.organizationTypeID;
    })
  }

  onChange(e: any) {
    console.log(e);
    this.selectedOrganizationID = e.value;
    console.log(this.selectedOrganizationID);
    this.assignmentTemplateService.getAssignmentTemplatesByOrganizationId(this.selectedOrganizationID).subscribe(res => {
      this.form.get('description')?.setValue(res.assignmentTemplates[0].template);
    })
    this.organizationService.getCustomerIDByOrganization(this.selectedOrganizationID).subscribe(res => {
      this.customerID = res;
      console.log(this.customerID);
    })
  }

  get assignmentTypeID(): any {
    return this.form.get('assignmentTypeID');
  }

  getAssignmentTemplate() {
    if (this.selectedOrganizationID) {
      this.assignmentTemplateService.getAssignmentTemplatesByOrganizationId(this.selectedOrganizationID).subscribe(res => {
        this.form.get('description')?.setValue(res.assignmentTemplates[0].template);
      })
    }
  }

  getCustomers() {
    this.processing = true;
    this.organizationService.getOrganizationsByType(OrganizationType.customer).subscribe(
      (response: any) => {
        this.customers = response.organizations;
        this.customers = sortAlphabetically(this.customers, 'organizationName');
        this.processing = false;
      },
      (error) => {
        this.processing = false;
      }
    );
  }

  updateAssignmentTypeID(e: any) {
    console.log(e);
    switch (e) {
      case 1: {
        this.form.get('priceTypeID')?.setValue(2);
        this.form.get('assignmentUnitID')?.setValue(1);
        console.log("tidbank");
        break;
      }
      case 2: {
        this.form.get('priceTypeID')?.setValue(1);
        this.form.get('assignmentUnitID')?.setValue(3);
        this.form.get('assignmentEstimate')?.setValue(0);
        console.log("åtagande");
        break;
      }
      case 3: {
        // this.form.setValue({priceTypeID:1, assignmentUnitID:2});
        this.form.get('priceTypeID')?.setValue(2);
        this.form.get('assignmentUnitID')?.setValue(2)
        console.log("resurskonsult");
        break;
      }
    }
  }

  getUsersInOrganization(pageIndex?: number): Observable<any> {
    this.processing = true;
    return this.userService.getAllInOrganization(this.selectedOrganizationID, pageIndex).pipe(
      map(response => {
        this.processing = false;
        for (const user of response.users) { user.organizationTypeID = 2 }
        this.users = response.users;
      },
        (error: any) => {
          console.log(error);
          this.processing = false;
        }
      )
    )
  }

  showUserList() {
    this.users = [];
    this.getUsersInOrganization().subscribe(res => {
      console.log(res);
      if (!this.isCustomer) {
        const { customerID } = this.form.value;
        this.processing = true;
        this.organizationService.getOrganizationsByType(OrganizationType.customer).pipe(
          mergeMap((res: any) => {
            console.log(res);
            this.customersOrg = res.organizations.find((x: any) => x.customerID == this.customerID);
            return this.userService.getAllInOrganization(Number(this.user.organizationID))
          })
        ).subscribe(result => {
          for (const user of result.users) { user.organizationTypeID = 1 }
          console.log(result);
          this.users = [...this.users, ...result.users];
        })
      };
      this.dialogRef = this.dialogService.open(UserToAssignmentComponent,
        {
          header: "Val av ansvarig användare",
          width: '70%',
          contentStyle: { "max-height": "800px", "overflow": "auto" },
          closable: false,
          data: {
            users: this.users,
            assignmentId: this.assignmentID,
            picked: this.picked,
            isCustomer: this.isCustomer
          }
        });
      this.dialogRef.onClose.subscribe((usersToAssignment: any) => {
        this.picked = [];
        usersToAssignment.forEach((elem: any) => {
          var user = this.users.find(user => user.userID == elem.userID)
          if (elem.isResponsible && user) {
            user.isResponsible = true
          }
          this.picked.push(user);
        })
        this.usersToAssignment = usersToAssignment;
        this.processing = false;
        this.processing = false;
      });
    });
  }


  onCreateClicked() {

    if (this.form.invalid) {
      this.invalidForm = true;
      return;
    }
    this.leavePageWarningEnabled = false;
    this.processing = true;
    if (this.usersToAssignment == null) {
      this.form.value.users.push({ 'userId': this.userID, 'isResponsible': true });
    } else {
      this.form.value.users = this.usersToAssignment;
    }
    this.assignment = this.form.value;
    this.assignment.customerID = this.customerID;
    this.price = this.assignment.assignmentMaxPrice;
    if (this.assignment.assignmentTemplateID == 0) {
      this.assignment.assignmentTemplateID = 1;
    }
    this.assignmentService.create(this.assignment).subscribe(
      (response: any) => {
        this.processing = false;
        this.assignmentId = response;
        this.sendCustomerCallOff(this.price, this.assignmentId);
      },
      (error) => {
        this.processing = false;
        this.leavePageWarningEnabled = true;
        this.dialogService.open(ErrorModalComponent,
          {
            header: "Något gick fel",
            data: {
              message: "Misslyckades skapa avrop. Vänligen försök igen."
            }
          })
      }
    );
  }

  sendCustomerCallOff(price: number, id: number) {
    this.callOff = {
      assignmentID: id,
      customerUserID: 0
    };

    this.callOffService.createCustomerCallOff(this.assignmentId, this.userID).subscribe(
      (res) => {
        var test = res;
        this.processing = false;
        this.showCreatedToast();
        this.router.navigate(['/']);
      },
      (error) => {
        this.leavePageWarningEnabled = true;
        this.processing = false;
        this.dialogService.open(ErrorModalComponent,
          {
            header: "Något gick fel",
            data: {
              message: "Misslyckades skapa avrop. Vänligen försök igen."
            }
          })
      }
    );
  }

  getCallOffMail(assignment: any, params: any) {
    this.notificationSubject = `Nytt avrop: ${assignment.assignment.header}  från  ${assignment.assignment.customerName}`
    console.log(this.notificationSubject);
    this.organizationService.getOrganizationTemplateWithEventID(1).subscribe(resp => {
      this.notificationContent = resp.contentString;
      this.notificationContent = generateContentString(this.notificationContent, params);

      var receiver: NotificationReceiverDTO = {
        notificationReceiverFirstName: "",
        notificationReceiverLastName: "",
        notificationReceiverEmail: this.account.email
      }
      this.notificationReceivers = [receiver];

      const notification: Notification = {
        notificationSubject: this.notificationSubject,
        notificationContent: this.notificationContent,
        notificationData: {
          notificationContent: "avrop"
        },
        notificationReceivers: this.notificationReceivers
      }

      this.notificationService.postNotification(notification).subscribe(res => {
        this.getCallOffConfirmationMail(assignment)
      })
    }
    )
  }

  getCallOffConfirmationMail(assignment: any) {
    this.notificationSubject = `Tack för avrop: ${assignment.assignment.header}  från  ${assignment.assignment.customerName}`
    console.log(this.notificationSubject);
    this.organizationService.getOrganizationTemplateWithEventID(3).pipe(map(res => {
      this.notificationContent = res.contentString;

      var params = [
        { param: 'responsible_person', value: this.user.name },
        { param: 'responsible_email', value: this.account.email },
        { param: 'responsible_phone', value: this.user.phone },
        { param: 'request_customer', value: assignment.assignment.customerName },
        { param: 'request_title', value: assignment.assignment.header },
        { param: 'brm_link', value: `<a href=${this.account.brm_link_calloff}${this.user.organizationID}">Länk</a>` },
        { param: 'brm_banner', value: `<p><img src='https://brmngversion.blob.core.windows.net/images/swcg-email-banner.png'></p>` },
        { param: 'brm_footer', value: getTemplateFooter() }
      ];

      this.notificationContent = generateContentString(this.notificationContent, params);

      var receiver: NotificationReceiverDTO = {
        notificationReceiverFirstName: this.user.name,
        notificationReceiverLastName: "",
        notificationReceiverEmail: this.user.email
      }
      this.notificationReceivers = [receiver];

      const notification: Notification = {
        notificationSubject: this.notificationSubject,
        notificationContent: this.notificationContent,
        notificationData: {
          notificationContent: "tack för avrop"
        },
        notificationReceivers: this.notificationReceivers
      }
      return this.notificationService.postNotification(notification).subscribe(res => {
        console.log(res);
      })
    })
    ).subscribe(res => {
      console.log(res);
    })
  }

  onShowBrokerCalloffs(): void {
    this.router.navigate(['broker-call-off-list']);
  }

  canDeactivate(confirmationMessage: string): boolean | Observable<boolean> | Promise<boolean> {
    if (this.leavePageWarningEnabled) {
      if (this.form.dirty) {
        return window.confirm(confirmationMessage);
      }
    }
    return true;
  }

  showCreatedToast() {
    this.messageService.add({
      key: 'main',
      severity: 'success',
      detail: 'Avropet har skapats'
    })
  }
}
