import { keyframes } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { settings } from 'cluster';
import { LazyLoadEvent } from 'primeng/api';
import { OrganizationList } from 'src/app/models/organization/organization-list.model';
import { Organization } from 'src/app/models/organization/organization.model';
import { OrganizationService } from 'src/app/services/organization.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-organizations',
  templateUrl: './organizations-component.html',
  providers: [OrganizationService]
})
export class OrganizationsComponent implements OnInit {
  settings: any = environment.applicationSettings.table;

  result: OrganizationList;
  loading: boolean;
  organizations: Organization[];

  records: number;
  size: number = this.settings.defaultPageSize;
  modes: [] = this.settings.defaultTextMatchMode;

  pages: number;

  constructor(
    private organization: OrganizationService,
    private router: Router,

  ) { }

  ngOnInit(): void {
    this.fetch(null, this.pages);
  }

  onCreateOrganizationClicked() {
    this.router.navigate([`/organization/create`])
  }

  onEditOrganizationClicked(organization: Organization) {
    this.router.navigate([`/organization/edit/${organization.organizationID}`])
  }

  fetch(filter: any, page: number): void {

    if (filter != null) {
      this.organization.getAllFiltered<OrganizationList>(filter, page, this.size)
        .subscribe((response: OrganizationList) => {
          this.result = response;
          this.organizations = this.result.organizations;

          this.records = this.result.totalRecords;

          this.pages = this.result.totalPages;

        });
    } else {
      this.organization.getAll<OrganizationList>()
        .subscribe((response: OrganizationList) => {
          this.result = response;
          this.organizations = this.result.organizations;

          this.records = this.result.totalRecords;

          this.pages = this.result.totalPages;

        });
    }

  }

  onLoad(event: LazyLoadEvent) {
    const { first, filters, sortField, sortOrder } = event;

    let find: boolean = false;

    let page = (<number>first / this.size) + 1;

    for (const key in filters) {
      const value = filters[key];
      if (value.value !== null) {
        find = true;
        break;
      }
    }

    if (find) {
      this.fetch({ sortField, sortOrder, filters }, page);
    } else {
      this.fetch(null, page);
    }
  }

}