import { AbstractControl, ValidatorFn } from '@angular/forms';

export function emailAddressValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        if (!control.value || emailRegex.test(control.value)) {
            return null;
        } else {
            return { 'invalidEmail': true };
        }
    };
}
