import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LazyLoadEvent, MenuItem } from 'primeng/api';
import { Contract, ContractList, UpdateContract } from 'src/app/models/contract/contract.model';
import { OrganizationType } from 'src/app/models/organization-type-enum.model';
import { UserAuthenticateResponse } from 'src/app/models/user/user-authenticate-response.model';
import { AuthService } from 'src/app/services/auth.service';
import { ContractService } from 'src/app/services/contract.service';
import { OrganizationService } from 'src/app/services/organization.service';
import { sortAlphabetically } from 'src/app/utils/array-sorting';
import { calculateDiff, convertToLocaleStringFromUTC, removeTimeFromDateString } from 'src/app/utils/date';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-contract-list',
  templateUrl: './contract-list.component.html',
  styles: [
  ]
})
export class ContractListComponent implements OnInit {
  user: UserAuthenticateResponse
  token: string;
  tableSettings: any = environment.applicationSettings.table;
  organizationId: number;
  selectedOrganizationID: number;
  contracts: Contract[];
  selectedContract: Contract | any;
  items: MenuItem[];
  pageIndex: number = 1;
  pageSize: number = this.tableSettings.defaultPageSize;
  pageTotal: number;
  loading: boolean;
  isBrokerUser: boolean = false;
  customers: any[];
  kebabItems: MenuItem[];
  convertDate = convertToLocaleStringFromUTC;
  settings: any = environment.applicationSettings.table;
  size: number = this.settings.defaultPageSize;
  state: string;
  oneAndHalfYearInDays: number = 548; //18 months in days is 547.8xxx - rounded to 548

  constructor(
    private contractService: ContractService,
    private authService: AuthService,
    private router: Router,
    private organizationService: OrganizationService
  ) { }

  ngOnInit(): void {
    const storedOrganizationID = localStorage.getItem('selectedCustomerOrganizationID');
    if (storedOrganizationID) {
      this.selectedOrganizationID = +storedOrganizationID;
    } else {
      this.selectedOrganizationID = 0;
    }
    this.token = this.authService.getTokenFromStorage();
    this.user = this.authService.getUserFromStorage();
    this.getAllContracts();

    this.kebabItems = [
      {
        label: 'Redigera',
        command: (event: any) => {
          // event.stopPropagation()
          this.onEditContractClicked(this.selectedContract)
        },
        visible: this.isBrokerUser
      },
      {
        label: 'Arkivera',
        command: (event: any) => {
          // event.stopPropagation() 
          this.onArchiveContractClicked(event, this.selectedContract)
        },
        visible: this.isBrokerUser
      }
    ]

  }

  getAllContracts() {
    //TODO: If Customer = Use Own OrgID
    //TODO: If Broker = Select Customers OrgID from Drop Down
    //TODO: If Supplier = Create new Endpoint in backend
    if (Number(this.user.organizationTypeID) === OrganizationType.customer) {
      this.state = 'customer';
      this.organizationId = Number(this.user.organizationID);
      this.contractService.getAllContracts(this.organizationId).subscribe(res => {
        this.contracts = res['contracts'];
        this.contracts.reverse();
        this.contracts.forEach(contract => {
          contract.contractExpiresInDays = calculateDiff(new Date(contract.endDate));
          contract.startDate = contract.startDate !== null ? this.convertDate(contract.startDate) : contract.startDate;
          contract.contractDate = contract.contractDate !== null ? this.convertDate(contract.contractDate) : contract.contractDate;
          contract.endDate = this.convertDate(contract.endDate);
        })
      })
    }
    else if (Number(this.user.organizationTypeID) === OrganizationType.supplier) {
      this.state = 'supplier';
      this.organizationId = Number(this.user.organizationID);
      this.contractService.getAllSupplierContracts(this.organizationId).subscribe(res => {
        this.contracts = res['contracts'];
        this.contracts.reverse();
        this.contracts.forEach(contract => {
          contract.contractExpiresInDays = calculateDiff(new Date(contract.endDate));
          contract.startDate = contract.startDate !== null ? this.convertDate(contract.startDate) : contract.startDate;
          contract.contractDate = contract.contractDate !== null ? this.convertDate(contract.contractDate) : contract.contractDate;
          contract.endDate = this.convertDate(contract.endDate);
        })
      })
    }
    else if (Number(this.user.organizationTypeID) === OrganizationType.broker) {
      this.isBrokerUser = true;
      this.state = 'broker';
      this.getCustomers();
    }
  }

  getCustomers() {
    this.organizationService.getOrganizationsByType(OrganizationType.customer).subscribe(
      (response: any) => {
        this.customers = response.organizations;
        this.customers = sortAlphabetically(this.customers, 'organizationName');
        this.selectedOrganizationID = this.selectedOrganizationID === 0 ? this.customers[0].organizationID : this.selectedOrganizationID;
        this.onChange();
      },
      (error) => {
      }
    );
  }

  onChange() {
    localStorage.setItem('selectedCustomerOrganizationID', this.selectedOrganizationID.toString());
    this.contractService.getAllContracts(this.selectedOrganizationID).subscribe(res => {
      this.contracts = res['contracts'];
      this.contracts.reverse();
      this.contracts.forEach(contract => {
        contract.contractExpiresInDays = calculateDiff(new Date(contract.endDate));
        contract.contractHasBeenActiveForDays = contract.startDate !== null ? Math.abs(calculateDiff(new Date(contract.startDate))) : 0;
        contract.startDate = contract.startDate !== null ? this.convertDate(contract.startDate) : contract.startDate;
        contract.contractDate = contract.contractDate !== null ? this.convertDate(contract.contractDate) : contract.contractDate;
        contract.endDate = this.convertDate(contract.endDate);
      })
    })
  }

  fetch(filter: any, page: number): void {
    var isEmpty: boolean = false
    if (filter) {
      for (const elem in filter.filters) {
        if (filter.filters[elem].value === "" || filter.filters[elem].value === null) {
          isEmpty = true
        }
        else {
          isEmpty = false
          break;
        }
      }
    }
    if (filter !== null && !isEmpty) {
      this.contractService.getAllFiltered<ContractList>(filter, page, this.size)
        .subscribe((response: ContractList) => {
          this.contracts = response.contracts;
          this.contracts.forEach(contract => {
            contract.contractExpiresInDays = calculateDiff(new Date(contract.endDate));
            contract.startDate = contract.startDate !== null ? this.convertDate(contract.startDate) : contract.startDate;
            contract.contractDate = contract.contractDate !== null ? this.convertDate(contract.contractDate) : contract.contractDate;
            contract.endDate = this.convertDate(contract.endDate);
          })
        });
    }
    else {
      this.contractService.getAllContracts(this.organizationId).subscribe(res => {
        this.contracts = res['contracts'];
        this.contracts.reverse();
        this.contracts.forEach(contract => {
          contract.contractExpiresInDays = calculateDiff(new Date(contract.endDate));
          contract.startDate = contract.startDate !== null ? this.convertDate(contract.startDate) : contract.startDate;
          contract.contractDate = contract.contractDate !== null ? this.convertDate(contract.contractDate) : contract.contractDate;
          contract.endDate = this.convertDate(contract.endDate);
        })
      })
    }
  }

  loadContracts(event: LazyLoadEvent) {
    const { first, filters, sortField, sortOrder } = event;
    this.pageIndex = (<number>first / 10) + 1;
    let find: boolean = false;

    for (const key in filters) {
      const value = filters[key];
      if (value.value !== null) {
        find = true;
        break;
      }
    }

    if (find) {
      this.fetch({ sortField, sortOrder, filters }, this.pageIndex);
    }
    else {
      this.fetch(null, this.pageIndex);
    }

  }

  onEditContractClicked(contract: Contract) {
    console.log(this.selectedContract);
    this.router.navigate([`/create-contract/edit/${contract.contractID}`]);
  }

  onArchiveContractClicked(event: any, contract: Contract) {
    event.originalEvent.stopPropagation();
    var updateContract: UpdateContract = {
      isActive: false
    };
    this.contractService.activateContract(contract.contractID, updateContract).subscribe(res => {
      console.log(res);
      if (res) {
        this.onChange();
      }
    })
  }

  openContractPage(contract: Contract): void {
    this.router.navigate([`/contract/${contract.contractID}`]);
  }

  getDatestring(date: any) {
    return removeTimeFromDateString(date.toString());
  }

  colourBackground(value: number): string {
    if (value < 29 && value >= 1)
      return 'red';
    else if (value > 29 && value < 60)
      return 'coral';
    else if (value > 59)
      return 'green';
    else return 'black';

  }

  colourText(value: number): string {
    if (value < 29 && value >= 1)
      return 'black';
    else if (value > 29 && value < 60)
      return 'black';
    else if (value > 59)
      return 'black';
    else return 'white';

  }

  contractExpiresString(days: number): string {
    if (days >= 0) {
      return `Avtalet löper ut om ${days} dagar`
    }
    return `Avtalet har löpt ut för ${days * (-1)} dagar sen`
  }

  checkOneAndHalfYearContract(days: number) {
    if (days >= this.oneAndHalfYearInDays){
      return true;
    }
    else return false;
  }

}
