import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {RoleService} from "../../../services/role.service";
import {UpdateRole} from "../../../models/role/update-role.model";
import {Role} from "../../../models/role/role.model";

@Component({
  selector: 'app-edit-role',
  templateUrl: './edit-role.component.html',
  styles: [
  ]
})
export class EditRoleComponent implements OnInit {

  roleId: number;
  role: Role;
  processing: boolean;
  invalidForm: boolean;

  editRoleForm: FormGroup;


  constructor(
      private route: ActivatedRoute,
      private router: Router,
      private formBuilder: FormBuilder,
      private roleService: RoleService) {
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.roleId = params.roleId;
      this.fetchRole(this.roleId);
    }, error => {
      this.navigateToRoles();
    });

    this.createEditRoleForm(<UpdateRole>({}));
  }

  fetchRole(roleId: number) {
    this.processing = true;
    this.roleService.get(roleId)
      .subscribe((role: UpdateRole | any) => {
          this.role = role;
          this.createEditRoleForm(role);
          this.processing = false;
      }, error => {
        this.navigateToRoles();
        this.processing = false;
      });
  }

  onBackClicked() {
    this.navigateToRoles();
  }

  onEditRole() {
    this.invalidForm = false;

    if(this.processing) {
      return;
    }

    this.processing = true;

    if(this.editRoleForm.invalid) {
      this.invalidForm = true;
      this.processing = false;
      return;
    }

    this.roleService.update(this.role.id, this.editRoleForm.value)
      .subscribe(res => {
        this.processing = false;
        this.navigateToRoles();
      }, error => {
        this.processing = false;
      });
  }

  private navigateToRoles() {
    this.router.navigate(['/roles']);
  }

  private createEditRoleForm(role: UpdateRole) {
    this.editRoleForm = this.formBuilder.group({
      name: [role?.name, [Validators.required, Validators.minLength(2)]]
    });
  }
}
