<div class="p-d-flex p-jc-center p-mt-6">
    <p-card styleClass="login-form p-formgrid" *ngIf="state === 'reset'">
        <div class="p-text-left p-text-bold p-mb-4">Återställ lösenord</div>
        <div class="p-fluid">
            <form [formGroup]="loginForm" (ngSubmit)="onResetSubmit()">
                <div class="p-field">
                    <label for="userName">Email</label>
                    <input formControlName="userName" [disableControl]="processing" id="userName" type="text" pInputText>
                </div>
                <!-- <div class="p-field">
                    <label for="password">Lösenord</label>
                    <input formControlName="password" [disableControl]="processing" id="password" type="password" pInputText>
                </div> -->
                <div class="p-mt-4" pRipple>
                    <button pButton class="p-button p-button-raised p-text-uppercase" label="SKICKA BEGÄRAN"
                            [disabled]="processing" *ngIf="!resetSent"
                            ></button>
                            <p *ngIf="resetSent">BEGÄRAN SKICKAD</p>
                </div>
                <!-- <div class="p-mt-2">
                    <small *ngIf="loginInvalid" class="p-error">Felaktigt användarnamn eller lösenord.</small>
                </div> -->
            </form>
            <p-divider></p-divider>
            <div class="p-mb-2">
                <button pButton class="p-button p-button-raised p-text-uppercase" label="Ny användare"
                [disabled]="processing"
                (click)="onApplyClicked()"></button>
            </div>
            <div>
                <button pButton class=" p-button-secondary p-button-raised p-text-uppercase" label="Tillbaka till inloggning"
                [disabled]="processing"
                (click)="onBackToLoginClicked()"></button>
            </div>
            <div class="p-mt-4" [hidden]="!processing">
                <p-progressBar mode="indeterminate"></p-progressBar>
            </div>
        </div>
    </p-card>

    <p-card styleClass="login-form p-formgrid" *ngIf="state === 'login'">
        <div class="p-text-left p-text-bold p-mb-4">Logga in</div>
        <div class="p-fluid">
            <form [formGroup]="loginForm" (ngSubmit)="onLoginClicked()">
                <div class="p-field">
                    <label for="userName">Email</label>
                    <input formControlName="userName" [disableControl]="processing" id="userName" type="text" pInputText>
                </div>
                <div class="p-field">
                    <label for="password">Lösenord</label>
                    <input formControlName="password" [disableControl]="processing" id="password" type="password" pInputText>
                </div>
                <div class="p-mt-4" pRipple>
                    <button pButton class="p-button p-button-raised p-text-uppercase" label="Logga in"
                            [disabled]="processing"
                            ></button>
                </div>
                <div class="p-mt-2">
                    <small *ngIf="loginInvalid" class="p-error">Felaktigt användarnamn eller lösenord.</small>
                </div>
            </form>
            <p-divider></p-divider>
            <div class="p-mb-2">
                <button pButton class="p-button p-button-raised p-text-uppercase" label="Glömt lösenord"
                        [disabled]="processing"
                        (click)="onForgottenPasswordClicked()"></button>
            </div>
            <div>
                <button pButton class="p-button p-button-raised p-text-uppercase" label="Ny användare"
                        [disabled]="processing"
                        (click)="onApplyClicked()"></button>
            </div>
            <div class="p-mt-4" [hidden]="!processing">
                <p-progressBar mode="indeterminate"></p-progressBar>
            </div>
        </div>
    </p-card>
</div>