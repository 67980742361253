import { NotificationService } from '../../../services/notification.service';
import { NotificationReceiverDTO, Notification } from '../../../models/notification/notification.model';
import { FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { AssignmentChildren, Region } from 'src/app/models/assignment-children/assignment-children.model';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FormGroup } from '@angular/forms';
import { OrganizationService } from 'src/app/services/organization.service';
import { forkJoin, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AssignmentService } from 'src/app/services/assignment.service';
import { generateContentString } from 'src/app/utils/template-string-replace';
import { MessageService } from 'primeng/api';
import { ErrorModalComponent } from '../../shared/error-modal/error-modal/error-modal.component';
import { getTemplateFooter } from 'src/app/utils/template-footer';
import { UtilService } from 'src/app/services/util.service';
import { SharedResume } from 'src/app/models/resume/resume.model';
import { Receiver } from 'src/app/models/receiver/receiver.model';
import { UpdateAssignment } from 'src/app/models/assignment/update-assignment.model';
import { AssignmentChildrenStatus } from 'src/app/models/status/status.enum';
import { EventEnum } from 'src/app/models/event/event.enum';
import { mergeMap } from 'rxjs/operators';
import { BrokerCallOffService } from 'src/app/services/broker-call-off.service';
import { UpdateBrokerCallOff } from 'src/app/models/assignment-children/broker-call-off.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-publish-modal',
  templateUrl: './publish-modal.component.html',
  styles: [
  ]
})

export class PublishModalComponent implements OnInit {
  eventID: number;
  notificationReceivers: any[];
  notificationSubject: string;
  notificationContent: string;
  recipients: any;
  sender: any;
  assignmentChild: AssignmentChildren;
  form: FormGroup;
  sendEmail: boolean = true;
  sendPublishEmailToSubscribers?: boolean = true;
  showError: boolean = false;
  processing: boolean;
  nrs: NotificationReceiverDTO[];
  required: boolean;
  account: any;
  sharedResume: SharedResume;
  enterReceivers: boolean;
  newStatusID: number;
  eventEnum = EventEnum;

  pdfSrc: Blob;
  assignmentChildID: number;
  receivers: Receiver[];
  newItem: string;
  supplierTenderID: number;
  brokerTenderID: number;
  showEmailForm: boolean;
  selectedRegionID: number;
  invalidRegion: boolean;
  updatedProperties: any;

  constructor(
    public config: DynamicDialogConfig,
    private formBuilder: FormBuilder,
    public modalRef: DynamicDialogRef,
    private notificationService: NotificationService,
    private organizationService: OrganizationService,
    private assignmentService: AssignmentService,
    private messageService: MessageService,
    private dialogService: DialogService,
    private utilService: UtilService,
    private brokerCallOffService: BrokerCallOffService){
    
  }

  ngOnInit(): void {
    this.eventID = this.config.data.eventID;
    this.eventEnum = EventEnum; 
    this.enterReceivers = false;

    if(this.config.data.sharedResume !== undefined) {
      this.processing = false;
      this.sharedResume = this.config.data.sharedResume;
      this.enterReceivers = true;

      this.receivers = [];
    }

    var newStatusIDNullable = this.config.data.newStatusID;
    if(newStatusIDNullable != null)
    {
      this.newStatusID = newStatusIDNullable;
    }
    
    let requests: Observable<any>[] = [];
    if(this.eventID !== undefined){
      requests.push(this.organizationService.getOrganizationTemplateWithEventID(this.eventID))
    }
    if(this.config.data.assignmentID !== undefined){
      requests.push(this.assignmentService.getResponsibleUsers(this.config.data.assignmentID))
    }

    this.initializeForm();

    forkJoin(requests).pipe(finalize(() => {
      this.form.patchValue({
        notificationContent: this.notificationContent,
      });
    })).subscribe(res => {
      this.utilService.getAccount().subscribe(res => {
        this.account = res;
      })
      this.notificationContent = res[0].contentString;

        this.recipients = {email:  res[1].responsibleCustomerUser.email,
          firstName : res[1].responsibleCustomerUser.fullName,
          lastName: ""
        }
        this.sender = res[1].responsibleBrokerUser
    
      var params = [
        {param: 'responsible_person', value: this.sender.fullName},
        {param:'responsible_email', value:this.sender.email},
        {param:'responsible_phone', value:this.sender.phoneNumber},
        {param:'request_customer', value:res[1].responsibleCustomerUser.organizationName},
        {param:'request_title', value:this.config.data.assignmentChild.header},
        {param:'offer_supplier', value:this.config.data.assignmentChild.supplierOrganizationName},
        // {param: 'information_text', value:this.form.get('interviewtime')}, website_requests_url
        {param: 'website_requests_url', value: `<a href='${environment.publishedCallOffsUrl}'>Uppdrag</a>` },
        {param: 'brm_link', value: `<a href="${environment.clientUrl}call-off-board/"${res[1].responsibleCustomerUser.organizationID}">Länk</a>`},
        {param: 'brm_banner', value: `<p><img src='https://brmngversion.blob.core.windows.net/images/swcg-email-banner.png'></p>`},
        {param: 'brm_footer', value: getTemplateFooter()}
      ];

      this.notificationContent = generateContentString(this.notificationContent, params);
      var re = 'responsible_person';
      this.notificationContent = this.notificationContent.replace(re, this.sender.email);
    
      this.required = false;
      this.processing = true;
      
      var nr: NotificationReceiverDTO = {
        notificationReceiverFirstName: this.recipients.firstName,
        notificationReceiverLastName: this.recipients.lastName,
        notificationReceiverEmail: this.recipients.email
      };
      this.notificationReceivers = [nr];
      this.notificationSubject = this.config.data.notificationSubject;
      
      this.assignmentChild = this.config.data.assignmentChild;
      if(this.config.data.required==true){
        this.required = true;
      }
      this.processing = false;
    })
  }

  private initializeForm() {
    if(this.eventID == EventEnum.Publish)
    {
      this.form = this.formBuilder.group({
        sendEmail: [this.sendEmail],
        notificationSubject: [{ value: this.config.data.notificationSubject, disabled: !this.sendEmail }, Validators.required],
        notificationContent: [{ value: this.notificationContent, disabled: !this.sendEmail }, Validators.required],
        selectedRegionID: [null, Validators.required],
        sendPublishEmailToSubscribers: [this.sendPublishEmailToSubscribers]
      });
    }
    else
    {
      this.form = this.formBuilder.group({
        sendEmail: [this.sendEmail],
        notificationSubject: [{ value: this.config.data.notificationSubject, disabled: !this.sendEmail }, Validators.required],
        notificationContent: [{ value: this.notificationContent, disabled: !this.sendEmail }, Validators.required]
      });
    }
  
    const sendEmailControl = this.form.get('sendEmail');
    if (sendEmailControl) {
      sendEmailControl.valueChanges.subscribe(value => {
        this.sendEmail = value;
        const notificationSubjectControl = this.form.get('notificationSubject');
        const notificationContentControl = this.form.get('notificationContent');
        if (value) {
          notificationSubjectControl?.enable();
          notificationContentControl?.enable();
        } else {
          notificationSubjectControl?.disable();
          notificationContentControl?.disable();
        }
      });
    }
  
    const sendPublishEmailToSubscribersControl = this.form.get('sendPublishEmailToSubscribers');
    if (sendPublishEmailToSubscribersControl) {
      sendPublishEmailToSubscribersControl.valueChanges.subscribe(value => {
        this.sendPublishEmailToSubscribers = value;
      });
    }
  }

  get interviewtime() {
    return this.form.get('interviewtime');
  }
  
  onSubmit(): void {
    var subject;
    var content; 
    this.selectedRegionID = Number(this.form.value.selectedRegionID);
    if (this.sendEmail) 
    {
      subject = (this.form.get('notificationSubject')?.value ?? '').trim();
      content = (this.form.get('notificationContent')?.value ?? '').trim(); 
      if(!subject || !content || (!this.selectedRegionID && this.eventID == EventEnum.Publish))
      {
        this.showError = true; 
        return;
      }
    }
    else
    {
      if(!this.selectedRegionID && this.eventID == EventEnum.Publish)
      {
        this.showError = true; 
        return;
      }
    }

    if(this.eventID == EventEnum.Publish)
    {
        this.updatedProperties = {
            "RegionID": Number(this.selectedRegionID),
            "SendPublishEmailToSubscribers": this.sendPublishEmailToSubscribers,
            "StatusID": this.newStatusID
        };

       this.assignmentService.updateAssignment(this.assignmentChild.assignmentID, this.updatedProperties).pipe(
          mergeMap((res: any)=>{
            return this.brokerCallOffService.update<UpdateBrokerCallOff>(this.assignmentChild.assignmentChildID, this.updatedProperties)
          })
        ).subscribe(res => {

        })
    }

    if(this.sendEmail)
    {
      if(this.required) {
        content?.setValue(content?.value.replace("{information_text}", this.interviewtime?.value))
      }
      var notifications = [];
      
      for(let i = 0; i < this.notificationReceivers.length; i++){
        var nr: NotificationReceiverDTO = {
          notificationReceiverFirstName: this.recipients.firstName,
          notificationReceiverLastName: this.recipients.lastName,
          notificationReceiverEmail: this.recipients.email
        };
        notifications.push(nr);
      }

          const notification: Notification = {
            notificationSubject: subject,
            notificationContent: content,
            notificationData: {
              notificationContent : "testContent"
            },
            notificationReceivers: notifications,
          }
          
          this.notificationService.postNotification(notification).subscribe(res => {
            if(res==true){
              this.showPublishedFeedback(this.sendEmail);
              this.onCreate();
            }else{
              this.dialogService.open(ErrorModalComponent,
                {
                  header: "Något gick fel",
                  data: {
                    message:"Epostutskick misslyckades. Vänligen försök igen."
                  }
                }
                )
              this.onCancel();
            }
          });
      }
      else
      {
        this.showPublishedFeedback(this.sendEmail);
        this.onCreate();  
      }
  }
  onCreate(): void {
    this.modalRef.close(true);
  }
  
  onCancel(): void {
    this.modalRef.close(false);
  }

  showPublishedFeedback(sendEmail: boolean) {
    var message = "Avropet har ";
    if(this.eventID == EventEnum.Publish)
    {
      message += "publicerats";
    }
    else if(this.eventID == EventEnum.Unpublish)
    {
      message += "avpublicerats";
    }
    if(sendEmail)
      message += " och epost har skickats";

    this.messageService.add({
      key: 'main',
      severity: 'success',
      detail: message
    })
  }

  get sendEmailValue(): boolean {
    
    return this.form.get('sendEmail')?.value ?? false;
  }

  get sendEmailToSubscribersValue(): boolean {
    
    return this.form.get('sendPublishEmailToSubscribers')?.value ?? false;
  }
  
  addItem(email: string) {
    // this.newItem = '';
    // this.receivers.unshift({
    //   email
    // });
  }

}
