import { Component, OnInit } from '@angular/core';
import { LazyLoadEvent, MessageService } from 'primeng/api';
import { Organization } from 'src/app/models/organization/organization.model';
import { OrganizationType } from 'src/app/models/organization-type-enum.model';
import { UserAuthenticateResponse } from 'src/app/models/user/user-authenticate-response.model';
import { UserList } from 'src/app/models/user/user-list.model';
import { User } from 'src/app/models/user/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { OrganizationService } from 'src/app/services/organization.service';
import { ConsultantService } from 'src/app/services/consultant.service';
import { ConsultantListItem } from 'src/app/models/consultant/consultant.model';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';
import { Role } from 'src/app/models/role/role.enum';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { UpdateUserRoleComponent } from '../../user/update-user-role/update-user-role.component';
import { map, mergeMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { sortAlphabetically } from 'src/app/utils/array-sorting';
import { UserSelectDialogComponent } from '../../shared/user-select-dialog/user-select-dialog.component';
import { CreateConsultantComponent } from '../../assignment-children/create-consultant/create-consultant.component';

@Component({
  selector: 'app-organization-users',
  templateUrl: './organization-users.component.html',
  styles: [],
  providers: [UserService, OrganizationService, ConsultantService, DynamicDialogRef, DynamicDialogConfig]
})
export class OrganizationUsersComponent implements OnInit {
  orgID = JSON.parse(this.userService.getUserFromLocalStorage()).organizationID;
  supplierID: number
  consultants: ConsultantListItem[] = [];
  list: User[] = [];
  size: number = environment.applicationSettings.table.defaultPageSize;
  options = environment.applicationSettings.table.defaultTextMatchMode;
  records: number;
  page: number = 1;
  organizations: Organization[] = [];
  selectedOrganization: Organization;
  selectedOrganizationID: number;
  user: UserAuthenticateResponse;
  userIsAdmin: boolean;
  showOrganizationDropdown: boolean;
  updateUserRole: boolean;
  all: boolean;

  isSupplierUser: boolean;
  isSupplierOrganization: boolean;
  isGetConsultantsClicked: boolean;

  constructor(
    private userService: UserService,
    private organizationService: OrganizationService,
    private consultantService: ConsultantService,
    private authService: AuthService,
    private dialogService: DialogService,
    private router: Router,
    public dialogRef: DynamicDialogRef,
    public dialogConfig: DynamicDialogConfig,
    private messageService: MessageService
  ) { }

  async ngOnInit(): Promise<void> {
    this.user = this.authService.getUserFromStorage();
    this.userIsAdmin = Number(this.user.role) >= Role.Admin;
    this.isSupplierUser = Number(this.user.organizationTypeID) === OrganizationType.supplier;
    const storedOrganizationID = localStorage.getItem('selectedOrganizationID');

    if (storedOrganizationID) {
      try {
        this.selectedOrganizationID = +storedOrganizationID;
        this.selectedOrganization = await this.organizationService.get<Organization>(this.selectedOrganizationID).toPromise();
      } catch (error) {
        console.error("Error fetching organization by ID:", error);
      }
    } else {
      try {
        this.selectedOrganizationID = +this.user.organizationID;
        this.selectedOrganization = await this.organizationService.get<Organization>(this.selectedOrganizationID).toPromise();
      } catch (error) {
        console.error("Error fetching organization :", error);
      }
    }

    this.organizationService.getAll().subscribe(
      (response: any) => {
        this.organizations = response.organizations;
        this.organizations = sortAlphabetically(this.organizations, 'organizationName');
        this.onChange();
      },
      (error) => {
        console.error("Error fetching all organizations:", error);
      }
    );

    this.showOrganizationDropdown = Number(this.user.organizationTypeID) === OrganizationType.broker;
  }


  onGetConsultantsClicked(): void {
    let supplierIdToUse: number;

    if (this.isSupplierUser) {
        supplierIdToUse = this.orgID;
    } else {
      if (this.selectedOrganization.supplierID == null) {
        throw new Error("SupplierID is undefined");
      }
        supplierIdToUse = this.selectedOrganization.supplierID;
    }

    this.consultantService.getConsultantsByOrganization(supplierIdToUse)
        .subscribe(res => {
            this.consultants = res.consultants;
            this.isGetConsultantsClicked = true;
        });
  }

  onShowCreateConsultantClicked(): void {

    this.dialogService.open(CreateConsultantComponent,
      {
        header: '',
        width: '40%',
        data: {
          supplierId: this.selectedOrganization.supplierID,
          isEditMode: false
        }
      })
      .onClose.subscribe(shouldFetch => {
        if (shouldFetch) {
          if (this.selectedOrganization.supplierID == null) {
            throw new Error("SupplierID is undefined");
          }
          this.consultantService.getConsultantsByOrganization(this.selectedOrganization.supplierID).subscribe(res => {
            this.consultants = res.consultants;
          })
        }
      });
  }

  async onFetch(entry: any): Promise<void> {
    this.all = true;

    if (entry != null) {
      for (const key in entry.filters) {
        const value = entry.filters[key];
        if (value.value !== null) {
          this.all = false;
          break;
        }
      }
    }

    let currentPage = 1;
    let totalPages = 1;
    this.list = [];

    while (currentPage <= totalPages) {
      try {
        let response: any;

        if (this.all) {
          response = await this.userService.getAllInOrganization(this.selectedOrganization.organizationID, currentPage, this.size).toPromise();
        } else {
          response = await this.userService.getAllFiltered<UserList>(entry, currentPage, this.size).toPromise();
        }

        this.list.push(...response.users.filter((item: User) => item.organizationID !== null));
        this.records = response.totalRecords;

        totalPages = response.totalPages;

        currentPage++;

      } catch (error) {
        console.error("Error fetching users:", error);
        break;
      }
    }
  }

  onLoad(event: LazyLoadEvent): void {
    const { first, sortField, sortOrder, filters } = event;
    this.page = (<number>first / this.size) + 1;
    this.onFetch({ sortField, sortOrder, filters });
  }

  onChange(): void {
    localStorage.setItem('selectedOrganizationID', this.selectedOrganizationID.toString());

    // Find the organization object that matches the selectedOrganizationID
    var tempOrganization = this.organizations.find(org => org.organizationID === this.selectedOrganizationID);

    // Check if the selectedOrganization is defined
    if (!tempOrganization) {
      console.error("Selected organization not found in the list of organizations.");
      return;
    }
    else {
      this.selectedOrganization = tempOrganization;
    }

    if (this.selectedOrganization.organizationTypeID === OrganizationType.supplier) {
      this.isSupplierOrganization = true;
    }
    else this.isSupplierOrganization = false;
    this.page = 1;
    this.onFetch(null);
  }

  convertOrganizationIdToName(id: number): string {
    let name: string = "";
    let result = this.organizations.filter(item => item.organizationID == id);
    if (result.length == 0) {
      this.organizationService.get<Organization>(id)
        .subscribe(response => {
          this.organizations.push(response);
          name = response.organizationName;
        });
    } else {
      name = result[0].organizationName;
    }
    return name;
  }

  convertRoleIdToName(id: number): string {
    if (id === Role.User) return 'Användare';
    if (id === Role.Admin) return 'Administratör';
    if (id === Role.SystemAdmin) return 'Systemadministratör';
    return '';
  }

  usersRoleIsHigher(id: number): boolean {
    return Number(this.user.role) > id;
  }

  onUpdateRoleClicked(userID: number) {
    this.user = this.authService.getUserFromStorage();
    this.userService.get(userID).subscribe(
      (user: User | any) => {
        this.dialogRef = this.dialogService.open(UpdateUserRoleComponent,
          {
            header: "Uppdatera roll",
            width: '30%',
            contentStyle: { "min-height": "200px", "max-height": "800px", "overflow": "auto" },
            data: {
              user: user,
              updatersOwnRole: Number(this.user.role)
            }
          }
        );
        return this.dialogRef.onClose.subscribe((user) => {
          var foundIndex = this.list.findIndex(x => x.userID == user.userID);
          this.list[foundIndex] = user;
        });
      },
      (error) => {

      }
    )
  }

  onEditUserClicked(user: User) {
    this.router.navigate([`/user/edit/${user.userID}`])
  }

  onDeleteUserClicked(user: any) {
    this.dialogService.open(UserSelectDialogComponent,
      {
        header: `Ta bort användare: ${user.fullName}`,
        width: '70%',
        contentStyle: { "max-height": "800px", "overflow": "auto" },
        data: {
          message: `Om du tar bort användaren kommer avrop, avtal, anbud som är kopplade till hen i systemet, istället tilldelas den valda användaren.
          Den valda användaren kan komma att få tillgång till nya avrop, avtal, anbud och dylikt, samt få mail-utskick kopplat till dessa.`,
          users: this.list,
          filterUserId: user.userID
        }
      }).onClose.subscribe(res => {
        console.log(res);
        if (res === false || res === undefined) {
          console.log("cancelled");
        }
        else {
          //send clicked user.userID and selected user res.userID for delete/reassign
          this.userService.deleteUser(user.userID, res.userID).subscribe(
            (res: any) => {
              console.log(res);
              this.messageService.add({
                key: 'main',
                severity: 'success',
                detail: 'Användaren har tagits bort'
              });
              this.onFetch(null);
            },
            (error: any) => {
              this.messageService.add({
                key: 'main',
                severity: 'error',
                detail: 'Användaren kunde inte tas bort'
              });
            }
          )
        }
      }
      )
  }

  onCreateUserClicked() {
    this.router.navigate([`/user/create`])
  }
}