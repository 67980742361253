import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import {PrimeNGConfig} from "primeng/api";

@Component({
  selector: 'app-root',
  templateUrl: `./app.component.html`,
  styles: []
})
export class AppComponent implements OnInit {

  constructor(private primengConfig: PrimeNGConfig, public authService: AuthService) {}

  ngOnInit() {
    this.primengConfig.ripple = true;
  }
}
