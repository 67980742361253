import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CreateSubscriberDTO } from 'src/app/models/subscription/create-subscriber.model'

@Injectable({
    providedIn: 'root'
})

export class SubscriptionService {
    endpointBaseURL = 'subscriber';

    constructor(private http: HttpClient) {
    }

    createSubscriber(createSubscriber: CreateSubscriberDTO): Observable<any> {
        return this.http.post(`${this.endpointBaseURL}`, createSubscriber)
    };

    deleteSubscriber(token: string, email: string): Observable<any> {
        return this.http.delete(`${this.endpointBaseURL}/${token}/${email}`);
    }

    unsubscribeNotification(email: string): Observable<any> {
        return this.http.get(`${this.endpointBaseURL}/sendUnsubscribeNotification?subscriberEmail=${email}`);
    }

    EmailReminderToSubscribers(assignmentID: number): Observable<any> {   
        const url = `${this.endpointBaseURL}/EmailReminderToSubscribers?assignmentID=${assignmentID}`;
        return this.http.post(url, null);   
      }
}