<p-dialog header="Support" [(visible)]="display">
    <p>E-post: {{supportEmail}}</p>
    <div style="text-align:center">
        <button pButton class="p-button p-button-raised p-text-uppercase" label="OK" (click)="onOK()" ></button>
    </div>
</p-dialog>
<div class="p-grid p-nogutter">
    <div class="p-col">
        <p-menubar styleClass="menubar p-shadow-1" [model]="items">
            <p-menubar styleClass="menubar p-shadow-none" [model]="rightItems">
            </p-menubar>
        </p-menubar>
    </div>
</div>
