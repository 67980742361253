import { debugOutputAstAsTypeScript } from '@angular/compiler';
import { Component, OnInit, Input, Output } from '@angular/core';
import { Consultant } from 'src/app/models/consultant/consultant.model';
import { LazyLoadEvent } from 'primeng/api';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-organization-consultants',
  templateUrl: './organization-consultants.component.html',
  styles: [
  ]
})
export class OrganizationConsultantsComponent implements OnInit {

  @Input() consultants: Consultant[];
  //@Output() consultantClicked: EventEmitter<Consultant> = new Eventemitter<consultant>();

  page: number = 1;
  tableSettings: any = environment.applicationSettings.table;
  size: number = environment.applicationSettings.table.defaultPageSize;
  options = environment.applicationSettings.table.defaultTextMatchMode;
  records: number;

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  onEditConsultantClicked(consultantId: number) {
    this.router.navigate([`organization/consultant/${consultantId}`]);


  }

  onLoad(event: LazyLoadEvent): void {
    const { first, sortField, sortOrder, filters } = event;
    this.page = (<number>first / this.size) + 1;
    // this.onFetch({ sortField, sortOrder, filters });
  }
}
