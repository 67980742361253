<div>
    <div class="p-d-flex card">
        <span>{{receiver.email}}</span>
        <span>
            <i pButton icon="pi pi-times" class="p-button-rounded p-button-text"
                style="color: 'red'; padding: 0px 0px 4px 0px;" (click)="onDelete(receiver)">
            </i>
        </span>
    </div>
</div>
