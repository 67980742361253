<main>
    <ng-container *ngIf="authService.isAuthenticated(); else loginHeader">
        <app-header></app-header>
    </ng-container>

    <ng-template #loginHeader>
        <app-header-login></app-header-login>
    </ng-template>

    <app-container></app-container>
    <app-footer></app-footer>
</main>

<p-toast position="top-center" key="main"></p-toast>
