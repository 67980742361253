import { Injectable, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CreateAssignmentTemplate } from '../models/assignment/assignment-template.model';

@Injectable({
    providedIn: 'root'
  })

  export class AssignmentTemplateService implements OnInit{
    endpointBaseURL  = 'assignmenttemplate';

    constructor(
      private http: HttpClient
    ) 
    { }

    ngOnInit(): void {
    }

    createTemplate(template: CreateAssignmentTemplate): Observable<any> {
      return this.http.post<CreateAssignmentTemplate>(this.endpointBaseURL, template);
    }

    getAssignmentTemplatesByOrganizationId(id: number): Observable<any> {     
      return this.http.get(`${this.endpointBaseURL}?organizationID=${id}`);
    }

    updateTemplate(id: number, template: any): Observable<any> {
      return this.http.put(`${this.endpointBaseURL}/${id}`, template);
    }
  }