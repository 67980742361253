<div *ngIf="isBrokerUser">
    <p-dropdown 
    [options]="customers"
    optionLabel="organizationName"
    [(ngModel)]="selectedOrganizationID"
    (onChange)="onChange()"
    optionValue="organizationID"
    [filter]="true">

    </p-dropdown>
</div>
<p-contextMenu #cm [model]="items"></p-contextMenu>
<p-card header="Avtal">
    <p-table [value]="contracts" sortMode="multiple" [tableStyle]="{'min-width': '60rem'}" (onLazyLoad)="loadContracts($event)" [paginator]="true"
    [rows]="this.pageSize" [totalRecords]="pageTotal" [loading]="loading" [(contextMenuSelection)]="selectedContract"
    [contextMenu]="cm" dataKey="contractID"
    [globalFilterFields]="['contractName', 'customerContractReference', 'customerUserFullName', 'supplierContractReference', 'supplierOrganization', 'contractDate', 'endDate']">
        <ng-template pTemplate="header">
            <tr>
                <th style="width: 18%" pSortableColumn="contractName">Avtalsrubrik <p-sortIcon field="contractName"></p-sortIcon>
                </th>
                <th style="width: 18%" pSortableColumn="customerContractReference">Kundens referens <p-sortIcon field="customerContractReference"></p-sortIcon>
                </th>
                <th *ngIf="this.state === 'customer' || this.state === 'broker'" style="width: 18%" pSortableColumn="customerUserFullName">Beställare <p-sortIcon field="customerUserFullName"></p-sortIcon>
                </th>
                <th style="width: 18%" pSortableColumn="supplierContractReference">Leverantörens referens <p-sortIcon field="supplierContractReference"></p-sortIcon>
                </th>
                <th style="width: 18%" pSortableColumn="supplierOrganization">Leverantörens org. <p-sortIcon field="supplierOrganization"></p-sortIcon>
                </th>
                <th style="width: 18%" pSortableColumn="contractDate">Avtalsdatum <p-sortIcon field="contractDate"></p-sortIcon>
                </th>
                <th style="width: 18%" pSortableColumn="endDate">Slutdatum <p-sortIcon field="endDate" rowspan="2"></p-sortIcon>
                </th>
                <th style="width: 18%" pSortableColumn="endDate">Avslutas <p-sortIcon field="endDate" rowspan="2"></p-sortIcon>
                </th>
                <th style="width: 8rem" class="p-text-right"></th>
                <th></th>
            </tr>
            <tr>
                <th>
                    <p-columnFilter [matchModeOptions]="tableSettings.defaultTextMatchMode" [matchMode]="'contains'" type="text" field="contractName">
                    </p-columnFilter>
                </th>
                <th>
                    <p-columnFilter [matchModeOptions]="tableSettings.defaultTextMatchMode" [matchMode]="'contains'" type="text" field="customerContractReference">
                    </p-columnFilter>
                </th>
                <th *ngIf="this.state === 'customer' || this.state === 'broker'">
                    <p-columnFilter [matchModeOptions]="tableSettings.defaultTextMatchMode" [matchMode]="'contains'" type="text" field="customerUserFullName">
                    </p-columnFilter>
                </th>
                <th>
                    <p-columnFilter [matchModeOptions]="tableSettings.defaultTextMatchMode" [matchMode]="'contains'" type="text" field="supplierContractReference">
                    </p-columnFilter>
                </th>
                <th>
                    <p-columnFilter [matchModeOptions]="tableSettings.defaultTextMatchMode" [matchMode]="'contains'" type="text" field="supplierOrganization">
                    </p-columnFilter>
                </th>
                <th>
                    <p-columnFilter [matchModeOptions]="tableSettings.defaultTextMatchMode" [matchMode]="'contains'" type="text" field="contractDate">
                    </p-columnFilter>
                </th>
                <th>
                    <p-columnFilter [matchModeOptions]="tableSettings.defaultTextMatchMode" [matchMode]="'contains'" type="text" field="endDate">
                    </p-columnFilter>
                </th>
                <th></th>
                <th></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-contract>
             <tr [pContextMenuRow]="contract" (click)="openContractPage(contract)" style="cursor: pointer;">
                <td>{{contract.contractName}}</td>
                <td>{{contract.customerContractReference}}</td>
                <td *ngIf="this.state === 'customer' || this.state === 'broker'">{{contract.customerUserFullName}}</td>
                <td>{{contract.supplierContractReference}}</td>
                <td>{{contract.supplierOrganization}}</td>
                <td>{{getDatestring(contract.contractDate)  | date: 'dd/MM/yyyy'}}  <i class="pi pi-flag" pTooltip="Avtalet har varit aktivt längre än 18 månader" *ngIf="checkOneAndHalfYearContract(contract.contractHasBeenActiveForDays)"></i> <i class="pi pi-info-circle" style="margin-left:  2px;" pTooltip="Avtalet har varit aktivt längre än 18 månader" *ngIf="checkOneAndHalfYearContract(contract.contractHasBeenActiveForDays)"></i></td>
                <td>{{getDatestring(contract.endDate) | date: 'dd/MM/yyyy'}}</td>
                <td [ngStyle]="{'background-color':colourBackground(contract.contractExpiresInDays)}"><b [ngStyle]="{'color':colourText(contract.contractExpiresInDays)}">{{contractExpiresString(contract.contractExpiresInDays)}}</b>
            </td>
                <td>
                    <div #myDiv></div><span>
                        <button
                            *ngIf="this.isBrokerUser"
                          pButton
                          type="icon"
                          class="kebab-icon p-button-rounded p-button-text p-button-plain"
                          icon="pi pi-ellipsis-v"
                          (click)="menu.toggle($event); $event.stopPropagation(); selectedContract = contract;">
                        </button>
                    
                        <p-menu styleClass="resume-kebab-menu" class="kebab-menu"#menu [popup]="true" [model]="kebabItems" [appendTo]="myDiv">
                        </p-menu>
                      </span>
                </td> 
            </tr>
        </ng-template>
    </p-table>
</p-card>