<p-card header="Redigera Resume">
    <div *ngIf="!processing">
        <form [formGroup]="form">
            <div class="p-fluid p-grid">
                <div class="p-field p-col-6">
                    <label for="displayFileName">Display File Name:</label>
                    <input [(ngModel)]="displayFileName" formControlName="DisplayFileName">
        
                </div>
            </div>
            <button pButton label="Spara" (click)="submitForm()"></button>
        </form>
    </div>
    
    <div *ngIf="processing">
        <p-progressBar mode="indeterminate"></p-progressBar>
    </div>
</p-card>
