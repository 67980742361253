import { ResumeService } from 'src/app/services/resume.service';
import { Component, OnInit } from '@angular/core';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Resume } from 'src/app/models/resume/resume.model';
import { FileService } from 'src/app/services/file-service';
import { ShareResumeData } from 'src/app/models/share-resume/share-resume.model';
import { Receiver } from 'src/app/models/receiver/receiver.model';
import { EmailModalComponent } from 'src/app/components/email-modal/email-modal/email-modal.component';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-resume-modal',
  templateUrl: './resume-modal.component.html',
  styles: [
  ]
})
export class ResumeModalComponent implements OnInit {
  resumes: Resume[];
  pdfSrc: Blob;
  assignmentChildID: number;
  receivers: Receiver[];
  newItem: string;
  supplierTenderID: number;
  brokerTenderID: number;
  showEmailForm: boolean;

  constructor(
    public config: DynamicDialogConfig,
    public modalRef: DynamicDialogRef,
    public fileService: FileService,
    private resumeService: ResumeService,
    private dialogService: DialogService,
    private messageService: MessageService) { }
  
  selectedResume?: Resume;

  ngOnInit(): void {
    this.resumes = this.config.data.resumes;
    this.receivers = [];
    this.assignmentChildID = this.config.data.assignmentChildID;
    this.brokerTenderID = -1;
    this.supplierTenderID = this.assignmentChildID;
    this.showEmailForm = false;
  }

  onOK(): void {
    this.modalRef.close(true);
  }
  onSelect(resume: Resume): void {
    this.selectedResume = resume;

     this.fileService.getFileById(this.selectedResume.fileID).subscribe(res => {
      this.pdfSrc = res;
     });
  }

  shareResume():void {
    this.showEmailForm=!this.showEmailForm;
  }

  addItem(email: string) {
    this.newItem = '';
    this.receivers.unshift({
      email
    });
  }

  deleteReceiver(receiver: Receiver){
    const index = this.receivers.indexOf(receiver);
    if (index > -1) {
      this.receivers.splice(index, 1);
    }
  }

  postToEmails(): void {
    const shareResumeData :ShareResumeData = {supplierTenderID: this.supplierTenderID, brokerTenderID: this.brokerTenderID,resumeLinkReceivers:this.receivers};
    this.resumeService.postResumesLinkReceiver(shareResumeData)
    .subscribe({next: token => {
      console.log(token);
  },
  error: error => {
    if(error.status==200){
      this.showToast();
      // alert("Successfully sent email");
      this.showEmailForm=false;
    }else{
      this.dialogService.open(EmailModalComponent, {
        header: "Något gick fel",
        data: {
          message:"Epostutskick misslyckades. Vänligen försök igen."
        }
      })
    }
  }});    
  }

  showToast() {
    this.messageService.add({
      key:'main',
      severity: 'success',
      detail: 'Ditt mail har skickats'
    })
  }

}
