<div class="p-grid p-justify-center">
  <div class="p-col-12 p-lg-7">
    <p-card header="Användare">
      <form [formGroup]="userForm" (ngSubmit)="onSavedClicked()">
        <div class="p-mt-4">
          <p class="p-text-bold">Konto</p>
          <p-divider></p-divider>
        </div>
        <div class="p-fluid p-formgrid p-grid p-mt-5 p-mb-1">
          <div class="p-field p-col">
            <label for="firstName">Förnamn
              <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px"
                pTooltip="Användarens förnamn"></i>
              </span>
            </label>
            <input id="firstName" formControlName="firstName" type="text" [disableControl]="processing" pInputText>
          </div>
          <div class="p-field p-col">
            <label for="lastName">Efternamn
              <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px"
                pTooltip="Användarens efternamn"></i>
              </span>
            </label>
            <input id="lastName" formControlName="lastName" type="text" [disableControl]="processing" pInputText>
          </div>
        </div>
        <div class="p-fluid p-grid">
          <div class="p-field p-col-6">
            <label for="title">Titel
              <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px"
                pTooltip="Användarens titel inom organisationen"></i>
              </span>
            </label>
            <input id="title" formControlName="title" type="text" [disableControl]="processing" pInputText>
          </div>
        </div>
        <div class="p-fluid p-grid">
          <div class="p-field p-col-6">
            <label for="email">E-postadress
              <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px"
                pTooltip="Användarens e-postaddress"></i>
              </span>
            </label>
            <input id="email" formControlName="email" type="text" [disableControl]="processing" pInputText readonly>
          </div>
        </div>


        <div class="p-fluid p-grid">
          <div class="p-field p-col-6">
            <label for="password">Byt lösenord
              <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px"
                pTooltip="Ange ett säkert lösenord för att byta"></i>
              </span>
            </label>
            <p-password formControlName="password" [disableControl]="processing" id="password" required></p-password>
            <small *ngIf="f.password.invalid && f.password.touched " class="p-error">Password
              is required</small>

          </div>
        </div>
        <div class="p-fluid p-grid">
          <div class="p-field p-col-6">
            <label for="passwordConfirm">Bekräfta lösenord
              <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px"
                pTooltip="Bekräfta det angivna lösenordet"></i>
              </span>
            </label>
            <p-password formControlName="passwordConfirm" [disableControl]="processing" id="passwordConfirm" required>
            </p-password>
            <small *ngIf="userForm.hasError('mismatch')" class="p-error">Lösenorden matchar ej</small>
          </div>
        </div>

        <div class="p-mt-4">
          <p class="p-text-bold">Övrigt</p>
          <p-divider></p-divider>
        </div>
        <div class="p-fluid p-grid p-mt-4">
          <div class="p-field p-col-6">
            <label for="phoneNumber">Telefonnummer
              <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px"
                pTooltip="Användarens telefonnummer"></i>
              </span>
            </label>
            <input id="phoneNumber" formControlName="phoneNumber" type="text" [disableControl]="processing" pInputText>
          </div>
        </div>

        <div class="p-mt-2">
          <small *ngIf="invalidForm" class="p-error">Misslyckades att spara ändringar. Kontrollera fälten</small>
        </div>
        <div class="p-grid p-mt-4">
          <div class="p-col-6">
            <button pButton type="button" icon="pi pi-chevron-left"
              class="p-button-secondary p-button-raised p-text-uppercase" (click)="onTillbakaClicked()" label="Tillbaka"
              [disabled]="processing"></button>
          </div>
          <div class="p-col-6 p-text-right">
            <button pButton type="submit"  iconPos="right"
              class="p-button p-button-raised p-text-uppercase" label="Spara" [disabled]="processing"></button>
          </div>
        </div>

        <div class="p-mt-4" [hidden]="!processing">
          <p-progressBar mode="indeterminate"></p-progressBar>
        </div>
      </form>
    </p-card>
  </div>
</div>