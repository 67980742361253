<div *ngIf="hideNotPublishedBrokerCallOff">
  <p-card [style]="{'margin-bottom': '25px'}">
    <ng-template pTemplate="header">
      <div style="color: red;">Uppdrag: {{brokerCallOffObject.assignment.header}} är inte längre publicerad</div>
    </ng-template>
  </p-card>
</div>

<div *ngIf="!hideNotPublishedBrokerCallOff">
  <ng-container *ngIf="processing==false">
    <p-card header="Uppdrag: {{brokerCallOffObject.assignment.header}}" [style]="{'margin-bottom': '25px'}">
      <div style="display: flex; justify-content: left; font-size: 16px;">
        <div style="width: 400px;">
          <p><b>Uppdragstyp:</b> {{brokerCallOffObject.assignmentTypeName}}</p>
          <p><b>Uppdragsort:</b> {{brokerCallOffObject.assignmentLocation}}</p>
          <p><b>Uppdragsbeskrivning:</b>
          <p [innerHTML]="brokerCallOffObject.description"></p>
        </div>
        <div style="width: 400px;">
          <p><b>Omfattning: </b>
            {{estimate}}<b *ngIf="brokerCallOffObject.assignmentTypeID === 3">%</b></p>
          <p><b>Pris:</b> {{brokerCallOffObject.brokerMaxPrice}} ({{brokerCallOffObject.priceTypeName}})</p>
          <p><b>Arbetsplats:</b> {{brokerCallOffObject.assignmentLocationTypeName}}</p>
        </div>
        <div style="width: 400px;">
          <p><b>Startdatum:</b> {{brokerCallOffObject.startDate}}</p>
          <p><b>Slutdatum:</b> {{brokerCallOffObject.endDate}}</p>
        </div>
      </div>
      <div class="p-col-12 p-lg-12">
        <div style="display: flex; justify-content: center; font-size: 16px;">
        </div>
      </div>
    </p-card>
  </ng-container>

  <ng-container>
    <style>
      .p-table th,
      .p-table td {
        text-align: left;
        padding: 0 10px;
      }
    </style>
    <p-card *ngIf="supplierTenders.length" header="Offererat: " [style]="{'margin-bottom': '25px'}">
      <table class="p-table">
        <thead>
          <tr>
            <th style="text-align: left;">Konsult</th>
            <th style="text-align: left;">Kontaktperson</th>
            <th style="text-align: left;">Pris(kr)</th>
            <th style="text-align: left;">Kommentar</th>
            <th style="text-align: left;">CV</th>
            <th style="text-align: left;">Skapad</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let tender of supplierTenders">
            <td>{{tender.consultantName}}</td>
            <td>{{tender.supplierUserName}}</td>
            <td>{{tender.supplierTenderPrice}}</td>
            <td>
              <span>{{tender.supplierComment.length > 10 ? (tender.supplierComment | slice:0:10) + '...' :
                tender.supplierComment}}</span>
              <span *ngIf="tender.supplierComment.length > 10">
                <i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px"
                  pTooltip="{{tender.supplierComment}}"></i>
              </span>
            </td>
            <td><button pButton (click)="onShowResume(tender.supplierTendertoResumeFileID)"
                class="p-button p-button-raised p-text-uppercase">Visa CV</button> </td>
            <td>{{tender.created | date:'yyyy-MM-dd'}}</td>
          </tr>
        </tbody>
      </table>
    </p-card>
  </ng-container>

  <ng-container>
    <p-card *ngIf="authService.checkIfUserHasSupplierAccess()" header="Ny offert" [style]="{'margin-bottom': '25px'}">
      <form [formGroup]="form" (ngSubmit)="onCreateClicked()">
        <div class="p-fluid p-grid">
          <div class="p-field p-col-6" *ngIf="this.state === 'broker'">
            <label for="customerID">
              Leverantör
              <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px"
                  pTooltip="Företaget som skickar offerten till Swedish Consulting Group"></i>
              </span>
            </label>
            <select id="customerID" [(ngModel)]="selectedSupplier" formControlName="supplierID"
              (change)="onSupplierChange($event)" [disableControl]="processing" class="p-inputtext"
              style="appearance: auto">
              <option *ngFor="let org of organizations" [ngValue]="org.supplierID">
                {{org.organizationName}}</option>
            </select>
          </div>
        </div>
        <button *ngIf="this.state === 'broker'" pButton style="margin-bottom: 10px;" type="button"
          (click)="onCreateSupplier()" iconPos="right" class="p-button p-button-raised p-text-uppercase"
          label="Skapa ny Leverantör" [disabled]="processing"></button>
        <p-dialog [(visible)]="createSupplier" [style]="{'margin-top':'50px' }">
          <div [style]="{'width':'800px', 'height':'950px' }">
            <app-create-organization *ngIf="this.state === 'broker'" (supplierCreated)="createSupplierHandler($event)"
              [isSupplier]="createSupplier">
            </app-create-organization>
          </div>
        </p-dialog>

        <ng-container *ngIf="selectedSupplier">
          <div class="p-fluid p-grid">
            <div class="p-field p-col-12 p-md-6">
              <div>
                <label for="supplierUserId">
                  <b>Kontaktperson Leverantör</b>
                  <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px"
                      pTooltip="Den som är ansvarig för offerten och/eller konsulten"></i>
                  </span>
                </label>
              </div>
              <div>
                <select id="supplierUserID" [(ngModel)]="selectedSupplierUser" formControlName="supplierUserID"
                  (change)="onSupplierUserChange($event)" [disableControl]="processing" class="p-inputtext p-inputtext-sm"
                  style="width: 20%;">
                  <option value="0" disabled selected>Välj kontaktperson...</option>
                  <option *ngFor="let user of supplierUsers" [ngValue]="user.userID">
                    {{user.firstName }} {{ user.lastName }}</option>
                </select>
              </div>
            </div>
          </div>

          <button pButton type="button" (click)="onCreateConsultant()" iconPos="right"
            class="p-button p-button-raised p-text-uppercase" label="Ny konsult" [disabled]="processing"></button>

          <div class="p-fluid p-grid">
            <div class="p-field p-col-12 p-md-6">
              <div>
                <label for="custoddmerID">
                  <b>Konsult</b>
                  <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px"
                      pTooltip="Konsulten som offerten omfattar"></i>
                  </span>
                </label>
              </div>
              <div>
                <select id="custddomerID" [(ngModel)]="selectedConsultant" formControlName="consultantID"
                  (change)="onConsultantChange($event)" [disableControl]="processing" class="p-inputtext p-inputtext-sm"
                  style="width: 20%;">
                  <option value="0" disabled selected>Välj konsult...</option>
                  <option *ngFor="let con of consultants" [ngValue]="con.consultantID">
                    {{con.firstName }} {{ con.lastName }}</option>
                </select>
              </div>
            </div>
          </div>

          <p-dialog [(visible)]="createConsultant">
            <div [style]="{'width':'800px', 'height':'650px' }">
              <app-create-consultant [supplierId]="selectedSupplier"
                (createConsultantSuccess)="onCreateConsultantDone($event)">
              </app-create-consultant>
            </div>
          </p-dialog>

        </ng-container>


        <ng-container *ngIf="selectedSupplier && selectedConsultant">
          <app-file-upload [header]="'Ladda upp ett nytt CV för vald konsult'" (uploadSuccess)="onUploadDone($event)">
          </app-file-upload>
          <div class="p-fluid p-grid">
            <div class="p-field p-col-12 p-md-6">
              <div>
                <label for="custddomerID"><b>CV</b></label>
              </div>
              <div>
                <select id="custddomerID" [(ngModel)]="selectedResume" formControlName="resumeID"
                  (change)="onResumeChange($event)" [disableControl]="processing" class="p-inputtext p-inputtext-sm"
                  style="width: 20%;">
                  <option value="0" disabled selected>Välj CV...</option>
                  <option *ngFor="let rem of resumes" [ngValue]="rem.resume.resumeID">
                    {{rem.resume.fileName | slice:0:20}}</option>
                </select>
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="selectedSupplier && selectedConsultant && selectedResume">
          <div class="p-fluid p-grid">
            <div class="p-field p-col-12">
              <div>
                <label for="supplierComment"><b>Kommentar</b></label>
              </div>
              <div>
                <textarea id="supplierComment" formControlName="supplierComment" [disableControl]="processing" rows="5"
                  class="p-inputtext p-inputtext-sm" style="width: 30%;"></textarea>
              </div>
            </div>
          </div>
          <div class="p-fluid p-grid">
            <div class="p-field p-col-12 p-md-6">
              <div>
                <label for="supplierTenderPrice"><b>Pris *</b></label>
              </div>
              <div>
                <input id="supplierTenderPrice" formControlName="supplierTenderPrice" type="number"
                  [disableControl]="processing" required class="p-inputtext p-inputtext-sm" style="width: 10%;">
              </div>
            </div>
          </div>
          <div class="p-mt-2">
            <small *ngIf="invalidForm" class="p-error">Misslyckades skapa offert. Kontrollera fälten</small>
          </div>
          <div class="p-grid p-mt-4">
            <div class="p-col-6">
              <button pButton type="submit" iconPos="right" class="p-button p-button-raised p-text-uppercase"
                label="Lägg Offert" [disabled]="processing"></button>
            </div>
          </div>
          <p-confirmDialog></p-confirmDialog>
        </ng-container>
        <div class="p-mt-4" [hidden]="!processing">
          <p-progressBar mode="indeterminate"></p-progressBar>
        </div>
      </form>
    </p-card>
    <p-card [style]="{'margin-bottom': '25px'}">
    <button pButton type="button" icon="pi pi-chevron-left"
    class="p-button-secondary p-button-raised p-text-uppercase" (click)="onShowBrokerCalloffs()" label="Tillbaka"
    [disabled]="processing"></button>
    </p-card>
  </ng-container>
</div>