import { Injectable } from "@angular/core";
import { ConsoleLogger } from "@microsoft/signalr/dist/esm/Utils";
import { DialogService } from "primeng/dynamicdialog";
import { AuthService } from 'src/app/services/auth.service';
import { ErrorModalComponent } from "src/app/components/shared/error-modal/error-modal/error-modal.component";
import { OrganizationType } from 'src/app/models/organization-type-enum.model';
import { AssignmentChildren } from "src/app/models/assignment-children/assignment-children.model";
import { UpdateStatusDTO } from "src/app/models/assignment-children/Board/update-status-dto";
import { BaseHubService } from "./base-hub.service";
import { UserAuthenticateResponse } from 'src/app/models/user/user-authenticate-response.model';

@Injectable({
    providedIn: 'root'
  })
  export class TenderBoardHubService extends BaseHubService {
    endpointBaseURL  = `tenderBoard`;
    organizationTypeID: number;
    
    constructor(
      public dialogService: DialogService,
      private authService: AuthService
      ) {
      super();

      this.organizationTypeID = this.getOrganizationType();
    }

    initListeners(): void {
      this.connection.on("Error",  (errorMessage:string) => {
        this.dialogService.open(ErrorModalComponent, 
          {
            header: "Något gick fel",
            data: {
              message: errorMessage
            }
          } )
          
      }); 
    }
    
    public async updateAssignmentChildStatus(updateStatusDTO: UpdateStatusDTO,
      errorCallback: (assignmentChildren: AssignmentChildren[])=> void, updateCallback: (assignmentChildren: AssignmentChildren[])=> void, assignmentID: number) {
     try {
      this.organizationTypeID = this.getOrganizationType();
       
       let _ = await this.connection.invoke("MoveTenderObject", updateStatusDTO, assignmentID, this.organizationTypeID);
     } catch (error: any){
       throw new Error(error.message);
     }
    }

    public async subscribeToHubGroup(assignmentID: number, token: string, callback: (AssignmentChildrenResponse: any) => void) {
      try {
        this.organizationTypeID = this.getOrganizationType();
        this.connection.on("AssignmentChildren", callback)
        let _ = await this.connection.invoke("SubscribeToBoardGroup", assignmentID, this.organizationTypeID, token);
      }
        catch (error: any) {
        throw new Error(error.message);
      }
    }

    public async getAssignmentChildren(assignmentID: number, token: string, callback: (AssignmentChildrenResponse: any) => void) {
      try {
        this.organizationTypeID = this.getOrganizationType();
        this.connection.invoke("GetAssignmentChildren", assignmentID, this.organizationTypeID, token);
      }
        catch (error: any) {
        throw new Error(error.message);
      }
     }

     public async onReceiveStatusUpdates(callback: (assignmentChildren: AssignmentChildren[])=> void) {
      try {
        this.connection.on("UpdateStatus", callback);
        let _ = await this.connection.invoke("SendAssignmentChildren");
      }
        catch (error: any) {
        throw new Error(error.message);
      }
    }

    public getOrganizationType() : number {
      const user = this.authService.getUserFromStorage();
      if (!user) {
        throw new Error('User information not found');
      }
      const organizationTypeID = parseInt(user.organizationTypeID);
      if (isNaN(organizationTypeID)) {
        throw new Error('Invalid organizationTypeID');
      }        
       return organizationTypeID; 
    }
    
}