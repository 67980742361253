import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { BaseService } from "./base.service";
import { BrokerCallOffList, BrokerCallOffListItem, CreateBrokerCallOff, UpdateBrokerCallOff } from "../models/assignment-children/broker-call-off.model";
import { HttpParams } from "@angular/common/http";
import { CreateResume, Resume, SharedResume } from "../models/resume/resume.model";
import { ShareResumeData } from "../models/share-resume/share-resume.model";

@Injectable({
    providedIn: 'root'
  })

export class ResumeService extends BaseService<Resume, Resume, CreateResume, CreateResume> {
    endpointBaseURL = 'resume';

    getResumesBySupplierTenderId(id: number): Observable<any> {
        let params = new HttpParams();
        params = params.append('consultantID', id.toString());
        return this.http.get(`consultanttoresume`, { params });
    }

    postResumesLinkReceiver(shareResumeData: ShareResumeData): Observable<string> {
        return this.http.post<string>(`${this.endpointBaseURL}/resumeLink`, shareResumeData);
    }

    getResume(id: number): Observable<any> {
        return this.http.get(`consultanttoresume/${id}`);
    }

    getSharedResume(sharedResumeId: number): Observable<any> {
        return this.http.get(`resume/sharedResume/${sharedResumeId}`)
    }

    getSharedResumes(): Observable<any>{
        return this.http.get("resume/sharedResumeList");
    }

    createSharedResume(sharedResume: SharedResume): Observable<any> {
        return this.http.post("resume/sharedResume", sharedResume);
    }

    updateSharedResume(sharedResumeId:number, updateSharedResumeDTO: SharedResume): Observable<any>{
        return this.http.put(`resume/sharedResume/${sharedResumeId}`,updateSharedResumeDTO);
    }

    sharedResumeActiveStatus(sharedResumeId:number): Observable<any>{
        return this.http.put(`resume/sharedResume/${sharedResumeId}/active`,"");
    }

    archiveSharedResume(sharedResumeId: number): Observable<any>{
        return this.http.delete(`resume/sharedResume/${sharedResumeId}/archive`);
    }

    getSharedResumeReceiverHistory(sharedResumeId: number): Observable<any> {
        return this.http.get(`resume/sharedResume/${sharedResumeId}/history`)
    }
    
    getResumeObject(id: number): Observable<any> {
        return this.http.get(`${this.endpointBaseURL}/info/${id}`);
    }
}
