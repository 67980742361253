import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ResumeService } from 'src/app/services/resume.service';

@Component({
  selector: 'app-resume-edit',
  templateUrl: './resume-edit.component.html',
  styles: [
  ]
})
export class ResumeEditComponent implements OnInit {

  resume : any;
  form: FormGroup;

  displayFileName: string;

  processing = true;

  resumeId = this.config.data.id | this.route.snapshot.params['id'];

  constructor(
    @Optional() public config : DynamicDialogConfig,
    @Optional() public dialogRef: DynamicDialogRef,
    public formBuilder : FormBuilder,
    public http : HttpClient,
    public resumeSerivce : ResumeService,
    public route : ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      DisplayFileName : ["", [Validators.required]],
    });

    this.resumeSerivce.getResumeObject(this.resumeId).subscribe((res) => {
      this.resume = res;
    
      this.displayFileName = this.resume.displayFileName
      this.form.controls['DisplayFileName'].setValue(this.displayFileName)
      this.processing = false;
    })
    

  }

  submitForm() {
    let formvalue = this.form.value;
    this.resumeSerivce.update(this.resumeId, formvalue).subscribe((res) => {
      console.log(res)
        this.dialogRef.close(true);
      
    }, error => {
      console.log(`Something went wrong, got a response with this error: ${error}`)
      this.dialogRef.close(false);
    });

  }

}
