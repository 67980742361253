<div class="p-grid p-justify-center">
    <div class="p-col-12 p-lg-7">
        <p-card [header]="selectedContractId ? 'Redigera Avtal' :'Skapa Avtal'">
            <form [formGroup]="form" (ngSubmit)="onCreateClicked()">
                <div class="p-mt-4">
                    <div class="p-fluid p-formgrid p-grid">
                        <div class="p-field p-col-6">
                            <label for="contractName">Avtalsrubrik *
                                <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text"
                                        style="top: 8px" pTooltip="Rubrik för avtalet"></i>
                                </span>
                            </label>
                            <input id="name" formControlName="contractName" type="text" [disableControl]="processing"
                                pInputText>
                            </div>
                        </div>
                        <div class="p-fluid p-grid">
                            <div class="p-field p-col-6">
                            <label for="contractDate">Avtalsdatum *
                                <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text"
                                        style="top: 8px" pTooltip="Det datum då avtalet skrivs"></i>
                                </span>
                            </label>
                            <p-calendar id="contractDate" formControlName="contractDate"  [disableControl]="processing" >
                            </p-calendar>
                            </div>
                        </div>
                        
                        <div class="p-fluid p-grid">
                            <div class="p-field p-col-6">
                            <label for="customerContractReference">Kundens referens *
                                <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text"
                                        style="top: 8px" pTooltip="Kundens referens för att lättare kunna identifiera avtalet"></i>
                                </span>
                            </label>
                            <input id="customerContractReference" formControlName="customerContractReference" type="text" [disableControl]="processing"
                                pInputText>
                            </div>
                        </div>
                        <div class="p-fluid p-grid">
                            <div class="p-field p-col-6">
                            <label for="customerPrice">Kundens pris *
                                <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text"
                                        style="top: 8px" pTooltip="Priset som kunden och Swedish Consulting Group har kommit överens om"></i>
                                </span>
                            </label>
                            <input id="customerPrice" formControlName="customerPrice" type="number" [disableControl]="processing"
                                pInputText>
                            </div>
                        </div>
                        <div class="p-fluid p-grid">
                            <div class="p-field p-col-6">
                            <label for="supplierContractReference">Leverantörens referens *
                                <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text"
                                        style="top: 8px" pTooltip="Leverantörens referens för att lättare kunna identifiera avtalet"></i>
                                </span>
                            </label>
                            <input id="supplierContractReference" formControlName="supplierContractReference" type="text" [disableControl]="processing"
                                pInputText>
                            </div>
                        </div>
                        <div class="p-fluid p-grid">
                            <div class="p-field p-col-6">
                            <label for="supplierPrice">Leverantörens pris *
                                <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text"
                                        style="top: 8px" pTooltip="Priset som leverantören och Swedish Consulting Group har kommit överens om"></i>
                                </span>
                            </label>
                            <input id="supplierPrice" formControlName="supplierPrice" type="number" [disableControl]="processing"
                                pInputText (blur)="getMargin()">
                            </div>
                        </div>
                        <div class="p-fluid p-grid">
                            <div class="p-field p-col-6">
                            <label for="priceComment">Kommentar angående pris
                                <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text"
                                        style="top: 8px" pTooltip="Eventuella kommentarer angående nuvarande pris"></i>
                                </span>
                            </label>
                            <input id="priceComment" formControlName="priceComment" type="text" [disableControl]="processing"
                                pInputText>
                            </div>
                        </div>
                        <div class="p-fluid p-grid">
                            <div class="p-field p-col-6">
                            <label for="margin">Marginal
                                <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text"
                                        style="top: 8px" pTooltip="Differensen mellan kund- och leverantörspris"></i>
                                </span>
                            </label>
                            <input [attr.disabled]="true" id="margin" formControlName="margin" type="number" [disableControl]="processing"
                                pInputText value="customerPrice-supplierPrice" readonly>
                            </div>
                        </div>
                        <!-- TODO: Add PriceComment -->
                        <div class="p-fluid p-grid">
                            <div class="p-field p-col-6">
                            <label for="frameWorkPrice">Ramavtalspris *
                                <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text"
                                        style="top: 8px" pTooltip="Ange eventuellt ramavtalspris"></i>
                                </span>
                            </label>
                            <input id="frameWorkPrice" formControlName="frameWorkPrice" type="text" [disableControl]="processing"
                                pInputText>
                            </div>
                        </div>
                                <div class="p-fluid p-grid">
                                    <div class="p-field p-col-6">
                            <label for="assignmentExtent">Omfattning *
                                <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text"
                                        style="top: 8px" pTooltip="Avtalets omfattning i fritext"></i>
                                </span>
                            </label>
                            <input id="assignmentExtent" formControlName="assignmentExtent" type="text" [disableControl]="processing"
                                pInputText>
                            </div>
                        </div>
                                <div class="p-fluid p-grid p-formgrid">
                                    <div class="p-field p-col-12 p-md-4">
                            <label>Startdatum *
                                <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text"
                                        style="top: 8px" pTooltip="Det datum då avtalet börjar gälla"></i>
                                </span>
                            </label>
                            <p-calendar id="startDate" formControlName="startDate" [disableControl]="processing" dateFormat="yy/mm/dd">
                            </p-calendar>
                            </div>
                                
                            <div class="p-field p-col-12 p-md-4">
                            <label for="endDate">Slutdatum *
                                <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text"
                                        style="top: 8px" pTooltip="Det datum då avtalet avslutas"></i>
                                </span>
                            </label>
                            <p-calendar id="endDate" formControlName="endDate"
                                [disableControl]="processing" dateFormat="yy/mm/dd">

                            </p-calendar>
                            <div class="p-mt-2">
                                <small *ngIf="invalidForm" class="p-error">Misslyckades skapa avtal. Kontrollera fälten</small>
                              </div>
                            </div>
                                </div>
                            <div class="p-grid p-mt-4">
                                <div class="p-col-6">
                                    <button pButton type="button" icon="pi pi-chevron-left"
                                        class="p-button-secondary p-button-raised p-text-uppercase" label="Tillbaka"
                                        [disabled]="processing" (click)="onBackClicked()"></button>
                                </div>
                                <div class="p-col-6 p-text-right">
                                    <button pButton type="submit" iconPos="right"
                                        class="p-button p-button-raised p-text-uppercase"
                                        [label]="selectedContractId ? 'Spara' :'Skapa'"
                                        [disabled]="processing"></button>
                                </div>
                            </div>
                        <!-- </div> -->
                    <!-- </div> -->
                </div>
                <div class="p-mt-4" [hidden]="!processing">
                    <p-progressBar mode="indeterminate"></p-progressBar>
                </div>
            </form>
        </p-card>
    </div>
</div>