import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { forkJoin, Observable } from 'rxjs';
import { Contract } from 'src/app/models/contract/contract.model';
import { NotificationReceiverDTO, Notification } from 'src/app/models/notification/notification.model';
import { AssignmentService } from 'src/app/services/assignment.service';
import { BrokerTenderService } from 'src/app/services/broker-tender.service';
import { NotificationService } from 'src/app/services/notification.service';
import { OrganizationService } from 'src/app/services/organization.service';
import { UtilService } from 'src/app/services/util.service';
import { getTemplateFooter } from 'src/app/utils/template-footer';
import { generateContentString } from 'src/app/utils/template-string-replace';
import { AuthService } from "src/app/services/auth.service";
import { environment } from 'src/environments/environment';
import { UserAuthenticateResponse } from "src/app/models/user/user-authenticate-response.model";
import { OrganizationType } from 'src/app/models/organization-type-enum.model';

@Component({
  selector: 'app-contract-email',
  templateUrl: './contract-email.component.html',
  styles: [
  ]
})
export class ContractEmailComponent implements OnInit {

  receiver: NotificationReceiverDTO[] = [];
  test: string[] = [];
  account: any;
  notificationContent: string;
  notificationSubject: string;
  recipientAssignmentResponsible: any;
  recipientOrganizationResponsible: any;
  sender: any;
  contract: Contract;
  form: FormGroup;
  processing: boolean;
  notificationReceivers: any[];
  invalidForm: boolean;
  clientUrl: string;
  user: UserAuthenticateResponse;
  OrganizationType = OrganizationType;
  userTypeID: number;
  organizationID: number;

  constructor(
    public config: DynamicDialogConfig,
    private notificationService: NotificationService,
    private modalRef: DynamicDialogRef,
    private messageService: MessageService,
    private organizationService: OrganizationService,
    private brokerTenderService: BrokerTenderService,
    private assignmentService: AssignmentService,
    private utilService: UtilService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private dialogService: DialogService,
  ) { }

  ngOnInit(): void {
    this.user = this.authService.getUserFromStorage();
    this.userTypeID = parseInt(this.user.organizationTypeID);
    this.organizationID = parseInt(this.user.organizationID);

    this.contract = this.config.data.contract;

    let requests: Observable<any>[] = [];
    if (this.config.data.eventID !== undefined) {
      requests.push(this.organizationService.getOrganizationTemplateWithEventID(this.config.data.eventID))
    }
    if (this.config.data.brokerTender !== undefined) {
      requests.push(this.assignmentService.getResponsibleUsers(this.config.data.brokerTender.assignmentID))
      requests.push(this.organizationService.getCustomerByOrganizationID(this.organizationID));
    }
    forkJoin(requests).subscribe(res => {   
      this.notificationContent = res[0].contentString;

      if (this.config.data.mailType === 'sendToBroker') {
        this.recipientAssignmentResponsible = {
          email: res[1].responsibleBrokerUser.email,
          firstName: res[1].responsibleBrokerUser.fullName,
          lastName: ""
        }
        this.recipientOrganizationResponsible = {
          email: res[2].responsibleBrokerUser.email,
          firstName: res[2].responsibleBrokerUser.firstName,
          lastName: res[2].responsibleBrokerUser.lastName
        }

        this.sender = res[1].responsibleCustomerUser
      }
      if (this.config.data.mailType === 'sendToCustomer') {
        this.recipientAssignmentResponsible = {
          email: res[1].responsibleCustomerUser.email,
          firstName: res[1].responsibleCustomerUser.fullName,
          lastName: ""
        }
        this.sender = res[1].responsibleBrokerUser
      }
      if (this.config.data.mailType === 'sendToSupplier') {
        this.recipientAssignmentResponsible = 'TODO:<lägg till supplier mottagare här>';
        this.sender = res[1].responsibleBrokerUser;
      }
      var params = [
        { param: 'responsible_person', value: this.sender.fullName },
        { param: 'responsible_email', value: this.sender.email },
        { param: 'responsible_phone', value: this.sender.phoneNumber },
        { param: 'request_customer', value: res[1].responsibleCustomerUser.organizationName },
        { param: 'customer_contract_number', value: this.contract.customerContractReference },
        // {param:'request_title', value:this.config.data.assignmentChild.header},
        // {param:'offer_supplier', value:this.config.data.assignmentChild.supplierOrganizationName},
        // {param: 'information_text', value:this.form.get('interviewtime')},
         //{param: 'website_requests_url', value: `<a href=${this.account.website_requests_url}>Uppdrag</a>` },
        //{ param: 'brm_link', value: `<a href=${this.account.brm_link_calloff}${this.user.organizationID}">Länk</a>` },
          { param: 'brm_link', value: `<a href=${environment.clientUrl}contract/${this.contract.contractID}>Kontrakt</a>`},
        { param: 'brm_banner', value: `<p><img src='https://brmngversion.blob.core.windows.net/images/swcg-email-banner.png'></p>` },
        { param: 'brm_footer', value: getTemplateFooter() }
      ];

      this.notificationContent = generateContentString(this.notificationContent, params);
      var re = 'responsible_person';
      this.notificationContent = this.notificationContent.replace(re, this.sender.email);
      this.processing = true;

      var notificationReceiverAssignment: NotificationReceiverDTO = {
        notificationReceiverFirstName: "",
        notificationReceiverLastName: "",
        notificationReceiverEmail: this.recipientAssignmentResponsible.email
      };

      var notificationReceiverOrg: NotificationReceiverDTO = {
        notificationReceiverFirstName: "",
        notificationReceiverLastName: "",
        notificationReceiverEmail: this.recipientOrganizationResponsible.email
      };

      this.notificationReceivers = [notificationReceiverAssignment, notificationReceiverOrg];
      this.notificationSubject = this.config.data.notificationSubject;

      this.form = this.formBuilder.group({
        recipients: [''],
        notificationSubject: [this.notificationSubject, [Validators.required]],
        notificationContent: [this.notificationContent, [Validators.required]],
        interviewtime: [''],
      });

      this.processing = false;
    })
  }

  onSubmit() {
    if (this.form.invalid) {
      this.invalidForm = true;
      return;
    }

    var subject = this.form.get('notificationSubject');
    var content = this.form.get('notificationContent');

      if (subject && content) {
        const notification: Notification = {
          notificationSubject: subject.value,
          notificationContent: content.value,
          notificationData: {},
          notificationReceivers: this.notificationReceivers,
        }
        this.notificationService.postNotification(notification).subscribe(
          (res: any) => {
            this.showCreatedToast();
            this.onCreate();
          },
          (error: any) => {
            console.log(error);
            this.messageService.add({
              key: 'main',
              severity: 'error',
              detail: 'Notifikationen kunde inte skapas.'

            });
          }
        );
       }

  }

  onCancel() {
    this.modalRef.close(false);
  }

  onCreate(): void {
    this.modalRef.close(true);
  }

  showCreatedToast() {
    this.messageService.add({
      key: 'main',
      severity: 'success',
      detail: 'Epost har skickats'
    })
  }
}
