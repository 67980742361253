<div *ngIf="isBrokerUser">
    <p-dropdown 
    [options]="customers"
    optionLabel="organizationName"
    [(ngModel)]="selectedOrganizationID"
    (onChange)="onChange()"
    optionValue="organizationID"
    [filter]="true">

    </p-dropdown>
</div>
<p-card header="Offerter">
    <p-table [value]="tenders" dataKey="brokerCallOffID"
        [globalFilterFields]="['brokerUserName', 'lastModified', 'assignmentReference','header', 'startDate']">
        <ng-template pTemplate="header">
            <tr>
                <th style="width: 20%" pSortableColumn="header">Anbudnummer <p-sortIcon field="header"></p-sortIcon>
                </th>
                <th style="width: 20%" pSortableColumn="header">Titel <p-sortIcon field="header"></p-sortIcon>
                </th>
                <th style="width: 20%" pSortableColumn="header">Uppdragsreferens <p-sortIcon field="header"></p-sortIcon>
                </th> 
                <th style="width: 20%" pSortableColumn="header">Leverantörspris <p-sortIcon field="header"></p-sortIcon>
                </th>
                <th style="width: 20%" pSortableColumn="assignmentReference">Kundsnamn <p-sortIcon field="assignmentReference"></p-sortIcon>
                </th>  
                <th style="width: 20%" pSortableColumn="startDate">Publiceringsdatum <p-sortIcon field="startDate"></p-sortIcon>
                </th>
                <th style="width: 20%" pSortableColumn="customerName">Redigerad <p-sortIcon field="lastModified"></p-sortIcon>
                </th>
                <th style="width: 8rem" class="p-text-right"></th>
                <th></th>
            </tr>
            
        </ng-template>
        <ng-template pTemplate="body" let-co>
            <tr [pContextMenuRow]="co">
                <td>{{co.supplierTenderID}}</td>
                <td>{{co.header}}</td>
                <td>{{co.assignmentReference}}</td>   
                <td>{{co.supplierTenderPrice}}</td>
                <td>{{co.customerName}}</td>
                <td>{{co.startDate | date:'medium'}}</td>
                <td>{{co.modified | date:'medium'}}</td>
               
            </tr>
        </ng-template>
    </p-table>
</p-card>