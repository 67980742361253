import {NgModule} from '@angular/core';
import {InputTextModule} from 'primeng/inputtext';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {ButtonModule} from "primeng/button";
import {MenuModule} from 'primeng/menu';
import { AccordionModule } from 'primeng/accordion';
import {ConfirmationService, SharedModule} from 'primeng/api';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { FocusTrapModule } from 'primeng/focustrap';
import { ListboxModule } from 'primeng/listbox';
import { MenubarModule } from 'primeng/menubar';
import { PanelModule } from 'primeng/panel';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { ToolbarModule } from 'primeng/toolbar';
import { TreeModule } from 'primeng/tree';
import { TreeTableModule } from 'primeng/treetable';
import { ContextMenuModule } from 'primeng/contextmenu';
import {TieredMenuModule} from "primeng/tieredmenu";
import {CardModule} from "primeng/card";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {DividerModule} from "primeng/divider";
import {ProgressBarModule} from "primeng/progressbar";
import {PasswordModule} from "primeng/password";
import {ToastModule} from "primeng/toast";
import {FieldsetModule} from "primeng/fieldset";
import {TooltipModule} from "primeng/tooltip";
import {DisableControlDirective} from "./directives/disable-control.directive";
import {AutoCompleteModule} from "primeng/autocomplete";
import {DragDropModule} from "@angular/cdk/drag-drop";
import { EditorModule } from "primeng/editor";
import {FileUploadModule} from 'primeng/fileupload';
import {HttpClientModule} from '@angular/common/http';
import { MessageService } from 'primeng/api';
import {ChipsModule} from 'primeng/chips';

@NgModule({
    declarations: [
        DisableControlDirective
    ],
    exports: [
        AutoCompleteModule,       
        ButtonModule,
        InputTextModule,
        InputTextareaModule,
        MenuModule,
        SharedModule,
        TableModule,
        DialogModule,
        ConfirmDialogModule,
        DropdownModule,
        MenubarModule,
        ButtonModule,
        ListboxModule,
        RadioButtonModule,
        PanelModule,
        AccordionModule,
        CalendarModule,
        TabViewModule,
        FocusTrapModule,
        CheckboxModule,
        TieredMenuModule,
        TreeTableModule,
        TreeModule,
        ToolbarModule,
        SplitButtonModule,
        ContextMenuModule,
        MenubarModule,
        CardModule,
        FormsModule,
        ReactiveFormsModule,
        DividerModule,
        ProgressBarModule,
        PasswordModule,
        ToastModule,
        FieldsetModule,
        TooltipModule,
        DisableControlDirective,
        ConfirmDialogModule,
        DragDropModule,
        EditorModule,
        FileUploadModule,
        HttpClientModule,
        ChipsModule
    ],
    providers: [
        ConfirmationService,
        MessageService
    ]
})

export class PrimeModule {}
