<div class="p-grid p-justify-center">
    <div class="p-col-12 ">
        <p-card header="Användare">
            <p-toolbar styleClass="p-mb-4">
                <ng-template styleClass="p-mb-4" pTemplate="left">
                    <button (click)="onCreateUserClicked()" pButton pTooltip="Skapa ny användare" tooltipPosition="top"
                        class="p-button-raised p-button p-text-uppercase" label="Skapa"></button>
                </ng-template>
            </p-toolbar>
            <p-toolbar styleClass="p-mb-4" *ngIf="showOrganizationDropdown">
                <ng-template styleClass="p-mb-4" pTemplate="left">
                    <p-dropdown [options]="organizations" optionLabel="organizationName"
                        [(ngModel)]="selectedOrganizationID" (onChange)="onChange()" optionValue="organizationID" [filter]="true"></p-dropdown>
                </ng-template>
            </p-toolbar>
            <p-table [value]="list" sortMode="multiple" (onLazyLoad)="onLoad($event)" [paginator]="true" [rows]="size"
                [totalRecords]="records">
                <ng-template pTemplate="header">
                    <tr>
                        <th pSortableColumn="fullName">
                            Namn
                            <p-sortIcon field="fullName"></p-sortIcon>
                        </th>
                        <th pSortableColumn="email">
                            Epost
                            <p-sortIcon field="email"></p-sortIcon>
                        </th>
                        <th pSortableColumn="phoneNumber">
                            Telefonnummer
                            <p-sortIcon field="phoneNumber"></p-sortIcon>
                        </th>
                        <th pSortableColumn="isActivated">
                            Aktiverad
                            <p-sortIcon field="isActivated"></p-sortIcon>
                        </th>
                        <th pSortableColumn="isVerified">
                            Verifierad
                            <p-sortIcon field="isVerified"></p-sortIcon>
                        </th>
                        <th>
                            Organisation
                        </th>
                        <th>
                            Roll
                        </th>
                        <th></th>
                    </tr>
                    <tr>
                        <th>
                            <p-columnFilter type="text" field="fullName" [matchModeOptions]="options"></p-columnFilter>
                        </th>
                        <th>
                            <p-columnFilter type="text" field="email" [matchModeOptions]="options"></p-columnFilter>
                        </th>
                        <th>
                            <p-columnFilter type="text" field="phoneNumber"
                                [matchModeOptions]="options"></p-columnFilter>
                        </th>
                        <th>
                            <p-columnFilter [matchModeOptions]="options" type="text" field="isActivated">
                            </p-columnFilter>
                        </th>
                        <th>
                            <p-columnFilter [matchModeOptions]="options" type="text" field="isVerified">
                            </p-columnFilter>
                        </th>
                        <th></th>
                        <th></th>
                        <th></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-item>
                    <tr>
                        <td>{{item.fullName}}</td>
                        <td>{{item.email}}</td>
                        <td>{{item.phoneNumber}}</td>
                        <td>{{item.isActivated}}</td>
                        <td>{{item.isVerified}}</td>
                        <td>{{convertOrganizationIdToName(item.organizationID)}}</td>
                        <td>
                            {{convertRoleIdToName(item.roleIDs[0])}}
                        </td>
                        <td>
                            <button pButton label="Uppdatera Roll" *ngIf="usersRoleIsHigher(item.roleIDs[0])"
                                (click)="onUpdateRoleClicked(item.userID)"></button>
                            <button pButton label="Redigera Användare" *ngIf="userIsAdmin"
                                (click)="onEditUserClicked(item)"></button>
                        </td>
                        <td>
                            <button pButton label="Ta bort" *ngIf="userIsAdmin && this.selectedOrganization.organizationTypeID === 2 || this.selectedOrganization.organizationTypeID === 3"
                            (click)="onDeleteUserClicked(item)"></button>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
            <p-toolbar *ngIf="isSupplierUser || isSupplierOrganization" styleClass="p-mb-4">
                <div>
                    <button pButton label="Hämta konsulter" (click)="onGetConsultantsClicked()"></button>
                    <button pButton label="Skapa konsult" style="margin-left:1rem;" (click)="onShowCreateConsultantClicked()"></button>
                </div>
            </p-toolbar>
            <div *ngIf="isGetConsultantsClicked">
                <app-organization-consultants [consultants]="consultants"></app-organization-consultants>
            </div>
        </p-card>
    </div>
</div>
<p-dialog [(visible)]="updateUserRole">
    <div>
        <app-update-user-role (updateSuccess)="onFetch(null)"></app-update-user-role>
    </div>
</p-dialog>