<p-card [style]="{'margin-bottom': '25px'}" header="Resumes - CV">
  <div *ngIf="!processing">
    <div style="font-size: 16px;">
      
        <div *ngIf="pdffile !=null">
          <div class="pdf-test">
            <ngx-extended-pdf-viewer [src]="pdffile" [useBrowserLocale]="true" [showToolbar]="true" [showPrintButton]="false"
              [showDownloadButton]="false">
            </ngx-extended-pdf-viewer>
          </div>
        </div>
      
        <div class="cv-container">
            <div class="cv-card" *ngFor="let resume of resumes" >
              <div (click)="SelectResume(resume.resume.fileID)">
                <span class="pi pi-file"></span>
                <p class="cv-filename"><b>{{resume.resume.displayFileName}}</b></p>
                <p class="cv-created">{{resume.resume.created | date}}</p>
              </div>
              <button pButton (click)="openEditResume(resume.resume.resumeID)"><span class="pi pi-pencil"></span></button>
            </div>
          </div>
      </div>
  </div>
  <div *ngIf="processing">
    <p-progressBar mode="indeterminate"></p-progressBar>
  </div>  
  </p-card>