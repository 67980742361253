<div class="p-grid">
    <div class="p-col-4 p-offset-4">
        <p-card header="Ansök om konto">
            <form [formGroup]="userForm" (ngSubmit)="onRegisterClicked()">
                <div class="p-mt-4">
                    <p class="p-text-bold">Mitt Konto</p>
                    <p-divider></p-divider>
                </div>
                <div class="p-fluid p-formgrid p-grid p-mt-5 p-mb-1">
                    <div class="p-field p-col">
                        <label for="firstName">Förnamn*
                            <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px"
                                pTooltip="Ditt Förnamn"></i>
                            </span>
                        </label>
                        <input id="firstName" formControlName="firstName" type="text" [disableControl]="processing" pInputText>
                    </div>
                    <div class="p-field p-col">
                        <label for="lastName">Efternamn*
                            <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px"
                                pTooltip="Ditt Efternamn"></i>
                            </span>
                        </label>
                        <input id="lastName" formControlName="lastName" type="text" [disableControl]="processing" pInputText>
                    </div>
                </div>
                <div class="p-fluid p-grid">
                    <div class="p-field p-col-6">
                        <label for="title">Titel i bolaget*
                            <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px"
                                pTooltip="Din titel i ditt bolag"></i>
                            </span>
                        </label>
                        <input id="title" formControlName="title" type="text" [disableControl]="processing" pInputText>
                    </div>
                </div>
                <div class="p-fluid p-grid">
                    <div class="p-field p-col-6">
                        <label for="email">E-postadress*
                            <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px"
                                pTooltip="Din E-postadress"></i>
                            </span>
                        </label>
                        <input id="email" formControlName="email" type="text" [disableControl]="processing" pInputText>
                    </div>
                </div>
                <div class="p-fluid p-grid">
                    <div class="p-field p-col-6">
                        <label for="password">Lösenord*
                            <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px"
                                pTooltip="Välj lösenord"></i>
                            </span>
                        </label>
                        <p-password formControlName="password" [disableControl]="processing"
                                    id="password"></p-password>
                    </div>
                </div>
                <div class="p-fluid p-grid">
                    <div class="p-field p-col-6">
                        <label for="passwordConfirm">Bekräfta lösenord*
                            <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px"
                                pTooltip="Ange samma lösenord igen"></i>
                            </span>
                        </label>
                        <p-password formControlName="passwordConfirm" [disableControl]="processing"
                                    id="passwordConfirm"></p-password>
                        <small *ngIf="userForm.hasError('mismatch')" class="p-error">Lösenorden matchar ej</small>
                    </div>
                </div>
                <div class="p-fluid p-grid p-mt-4">
                    <div class="p-field p-col-6">
                        <label for="phoneNumber">Telefonnummer
                            <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px"
                                pTooltip="Ditt telefonnummer"></i>
                            </span>
                        </label>
                        <input id="phoneNumber" formControlName="phoneNumber" type="text" [disableControl]="processing" pInputText>
                    </div>
                </div>
                <div class="p-mt-4" *ngIf="!hasOrg">
                    <p class="p-text-bold">Min Organisation</p>
                    <p-divider></p-divider>
                </div>
                <div class="p-fluid p-grid" *ngIf="!hasOrg">
                    <div class="p-field p-col-6">
                        <label for="nameOrg">
                            Namn *
                            <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px;"
                                pTooltip="Namnet på din organisation"></i>
                            </span>
                        </label>
                        <input id="nameOrg" formControlName="nameOrg" type="text" [disableControl]="processing" pInputText>
                    </div>
                </div>

                <div class="p-fluid p-grid" *ngIf="!hasOrg">
                    <div class="p-field p-col-6">
                        <label for="descriptionOrg">
                            Beskrivning *
                            <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px"
                                pTooltip="Beskrivning av din organisation"></i>
                            </span>
                        </label>
                        <textarea id="descriptionOrg" formControlName="descriptionOrg" [disableControl]="processing"
                            pInputText> </textarea>
                    </div>
                </div>


                <div class="p-fluid p-grid" *ngIf="!hasOrg">
                    <div class="p-field p-col-6">
                        <label for="emailOrg">E-post *
                            <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px"
                                pTooltip="Organisationens e-postadress"></i>
                            </span>
                        </label>
                        <input id="emailOrg" formControlName="emailOrg" type="text" [disableControl]="processing" pInputText>
                    </div>
                </div>

                <div class="p-fluid p-grid" *ngIf="!hasOrg">
                    <div class="p-field p-col-6">
                        <label for="phoneNumberOrg">Telefonnummer *
                            <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px"
                                pTooltip="Organisationens telefonnummer"></i>
                            </span>
                        </label>
                        <input id="phoneNumberOrg" formControlName="phoneNumberOrg" type="text" [disableControl]="processing"
                            pInputText>
                    </div>
                </div>

                <div class="p-fluid p-grid" *ngIf="!hasOrg">
                    <div class="p-field p-col-6">
                        <label for="websiteOrg">Hemsida *
                            <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px"
                                pTooltip="Organisationens hemsida"></i>
                            </span>
                        </label>
                        <input id="websiteOrg" formControlName="websiteOrg" type="text" [disableControl]="processing"
                            pInputText>
                    </div>
                </div>

                <div class="p-mt-2">
                    <small *ngIf="invalidForm" class="p-error">Skicka in ansökan misslyckades. Kontrollera fälten</small>
                </div>
                <div class="p-grid p-mt-4">
                    <div class="p-col-6">
                        <button pButton
                                type="button"
                                icon="pi pi-chevron-left"
                                class="p-button-secondary p-button-raised p-text-uppercase" label="Tillbaka"
                                [disabled]="processing"
                                (click)="onBackClicked()"></button>&nbsp;
                        <button pButton
                                type="submit"
                                
                                iconPos="right"
                                class="p-button p-button-raised p-text-uppercase"
                                label="Ansök"
                                [disabled]="processing"></button>
                    </div>
                </div>
                <div class="p-mt-4" [hidden]="!processing">
                    <p-progressBar mode="indeterminate"></p-progressBar>
                </div>
            </form>
        </p-card>
    </div>
</div>