<div class="p-grid p-justify-center">
  <div class="p-col-12 p-lg-7">
    <p-card [header]="isEditMode ? 'Redigera Konsult' : 'Skapa konsult'">
      <form [formGroup]="form" (ngSubmit)="onCreateClicked()">
        <div class="p-mt-4">
          <p-divider></p-divider>
        </div>
        
        <div class="p-fluid p-grid">
          <div class="p-field p-col-6">
            <label for="firstName">
              Förnamn *
              <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px"
                pTooltip="Konsultens förnamn"></i>
              </span>
            </label>
            <input id="firstName" formControlName="firstName" type="text" [disableControl]="processing" pInputText>
          </div>
        </div>
        <div class="p-fluid p-grid">
          <div class="p-field p-col-6">
            <label for="lastName">
              Efternamn *
              <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px"
                pTooltip="Konsultens efternamn"></i>
              </span>
            </label>
            <input id="lastName" formControlName="lastName" type="text" [disableControl]="processing" pInputText>
          </div>
        </div>

        <div class="p-fluid p-grid">
          <div class="p-field p-col-6">
            <label for="email">
              E-post *
              <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px"
                pTooltip="Konsultens e-postadress"></i>
              </span>
            </label>
            <input id="email" formControlName="email" type="text" [disableControl]="processing" pInputText>
          </div>
        </div>
        <div class="p-fluid p-grid">
          <div class="p-field p-col-6">
            <label for="phoneNumber">
              Telefonnummer *
              <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px"
                pTooltip="Konsultens telefonnummer"></i>
              </span>
            </label>
            <input id="phoneNumber" formControlName="phoneNumber" type="text" [disableControl]="processing" pInputText>
          </div>
        </div>

        <div class="p-mt-2">
          <small *ngIf="invalidForm" class="p-error">Misslyckades skapa konsult. Kontrollera fälten</small>
        </div>
        <div class="p-grid p-mt-4">
          <!-- <div class="p-col-6">
            <button pButton type="button" icon="pi pi-chevron-left"
              class="p-button-secondary p-button-raised p-text-uppercase" label="Tillbaka" [disabled]="processing"
              (click)="onBackClicked()"></button>
          </div> -->
          <div class="p-col-4 p-text-right">
            <button pButton type="submit" iconPos="right"
              class="p-button p-button-raised p-text-uppercase" [label]="isEditMode ? 'Spara' : 'Skapa'" [disabled]="processing"></button>
          </div>
        </div>
        <div class="p-mt-4" [hidden]="!processing">
          <p-progressBar mode="indeterminate"></p-progressBar>
        </div>
      </form>
    </p-card>
  </div>
</div>