import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FileService } from "src/app/services/file-service.service";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";

@Component({
  selector: "app-file-upload",
  templateUrl: "./file-upload.component.html",
  styleUrls: ["./file-upload.style.scss"],
})
export class FileUploadComponent implements OnInit {
  @Output() uploadSuccess = new EventEmitter<any>();
  @Input() header: string;
  uploadForm: FormGroup;
  isUploaded: boolean = false;
  isSent: boolean;
  processing: boolean;
  uploadedFile: { name: '' };
  constructor(
    private fileService: FileService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.uploadForm = this.formBuilder.group({
      file: ["", [Validators.required]],
      fileSource: ["", [Validators.required]],
    });
  }

  onFileChange(event: any) {
    if (event.files.length > 0) {
      this.isUploaded = true;
      this.uploadedFile = event.files[0];
      this.uploadForm.patchValue({
        fileSource: this.uploadedFile,
      });
    }
  }

  onDeleteFile() {
    this.uploadForm.reset();
    this.isUploaded = false;
  }

  onFileUpload() {
    this.processing = true;
    const formData = new FormData();
    formData.append("file", this.uploadForm.get("fileSource")?.value);
    this.fileService.create(formData).subscribe((res) => {
      this.isSent = true;
      this.processing = false;
      this.uploadForm.reset();
      this.isUploaded = false;
      this.uploadSuccess.emit({success: true, file: res});
    }, error => {
      this.uploadSuccess.emit({success: false});
    });
  }
}
