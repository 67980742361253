import { SupplierTender, UpdateSupplierTender, UpdateSupplierTenderStatus } from './../../../../models/assignment-children/supplier-tender.model';
import { CreateBrokerTender } from './../../../../models/assignment-children/broker-tender.model';
import { Component, OnInit } from '@angular/core';
import { BrokerTenderService } from "src/app/services/broker-tender.service";
import { SupplierTenderService } from 'src/app/services/supplier-tender.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { UserService } from 'src/app/services/user.service';
import { AssignmentChildrenStatus } from 'src/app/models/status/status.enum';
import { mergeMap } from 'rxjs/operators';
import { MessageService } from 'primeng/api';
import { ErrorModalComponent } from 'src/app/components/shared/error-modal/error-modal/error-modal.component';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-create-broker-tender',
  templateUrl: './create-broker-tender.component.html',
  styles: [
  ]
})
export class CreateBrokerTenderComponent implements OnInit {
  processing: boolean;
  supplierTenderID: number;
  supplierTender: SupplierTender;
  form: FormGroup;
  invalidForm: boolean
  bt: CreateBrokerTender;
  updateSupplierTender: UpdateSupplierTenderStatus;
  leavePageWarningEnabled: boolean = true;

  constructor(private formBuilder: FormBuilder,
    private brokerTenderService: BrokerTenderService,
    private supplierTenderService: SupplierTenderService,
    private userService: UserService,
    public config: DynamicDialogConfig,
    public modalRef: DynamicDialogRef,
    private messageService: MessageService,
    private dialogService: DialogService) { }

  ngOnInit(): void {
    this.supplierTenderID = this.config.data.assignmentChildID;
    this.processing = true;

    this.bt = {
      assignmentID: -1,
      supplierTenderID: -1,
      brokerUserID: -1,
      brokerTenderPrice: 0,
      brokerComment: "",
      statusID: -1,
    }
    this.form = this.formBuilder.group({
      assignmentID: [0, [Validators.required, Validators.pattern("^[1-9]*$")]],
      supplierTenderID: [0, [Validators.required, Validators.pattern("^[1-9]*$")]],
      brokerUserID: [0, [Validators.required, Validators.pattern("^[1-9]*$")]],
      brokerTenderPrice: [0, [Validators.required, Validators.pattern("^[0-9]+\.?[0-9]*$")]],
      brokerComment: [""],
      statusID: [0, [Validators.required, Validators.pattern("^[1-9]*$")]],
      resumes: [0],
    });

    this.supplierTenderService.get(this.supplierTenderID).subscribe(
      (supplierTender: SupplierTender | any) => {
        this.supplierTender = supplierTender;

        this.bt = {
          assignmentID: this.supplierTender.assignmentID,
          supplierTenderID: this.supplierTenderID,
          brokerUserID: JSON.parse(this.userService.getUserFromLocalStorage()).userID,
          // brokerUserID: this.supplierTender.supplierUserID,
          brokerTenderPrice: this.supplierTender.supplierTenderPrice,
          brokerComment: this.supplierTender.supplierComment,
          statusID: AssignmentChildrenStatus.BrokerTenderDraft,
          resumes: this.supplierTender.resumes.map(r => r.resumeID),
        }

      },
      (error) => {
        console.log(error);
      },
      // The 3rd callback handles the "complete" event.
      () => {
        this.form = this.formBuilder.group({
          assignmentID: [this.bt.assignmentID, [Validators.required, Validators.pattern("^[1-9][0-9]*$")]],
          supplierTenderID: [this.supplierTenderID, [Validators.required, Validators.pattern("^[1-9][0-9]*$")]],
          brokerUserID: [this.bt.brokerUserID, [Validators.required, Validators.pattern("^[1-9][0-9]*$")]],
          brokerTenderPrice: [this.bt.brokerTenderPrice.toFixed(2), [Validators.required, Validators.pattern("^[0-9]+\.?[0-9]*$")]],
          brokerComment: [this.bt.brokerComment],
          statusID: [this.bt.statusID, [Validators.required, Validators.pattern("^[1-9][0-9]*$")]],
          resumes: [this.bt.resumes],
        });
      }
    );
    this.processing = false;
  }

  get brokerTenderPrice() {
    return this.form.get('brokerTenderPrice');
  }

  onSubmit() {
    this.leavePageWarningEnabled = false;
    if (this.form.invalid) {
      this.leavePageWarningEnabled = true;
      this.invalidForm = true;
      return;
    }
    this.brokerTenderService.create(this.form.value).pipe(
      mergeMap(res => {
        this.updateSupplierTender = {
          statusId: AssignmentChildrenStatus.BrokerTenderDraft
        }
        return this.supplierTenderService.update(res.supplierTenderID, this.updateSupplierTender);
      })
    ).subscribe(res => {
      console.log(res);
      this.showCreatedToast();
    }, error => {
      this.leavePageWarningEnabled = true;
      console.log(error);
      this.dialogService.open(ErrorModalComponent, {
        header: "Något gick fel",
        data: {
          message: "Det gick inte att skapa anbudet. Vänligen försök igen."
        }
      })
    })
    this.onCreate();
  }

  onCreate(): void {
    this.modalRef.close(true);
  }

  onCancel(): void {
    this.modalRef.close(false);
  }

  showCreatedToast() {
    this.messageService.add({
      key: 'main',
      severity: 'success',
      detail: 'Anbudet har skapats'
    })
  }

  canDeactivate(confirmationMessage: string): boolean | Observable<boolean> | Promise<boolean> {
    if (this.leavePageWarningEnabled) {
      if (this.form.dirty) {
        return window.confirm(confirmationMessage);
      }
    }
    return true;
  }
  showUpdatedToast() {
    this.messageService.add({
      key: 'main',
      severity: 'success',
      detail: 'Anbudet har uppdaterats'
    })
  }
}
