<div *ngIf="isBrokerUser">
  <div class="p-grid p-justify-center">
    <div class="p-col-12 p-lg-7">
      <p-card header="Avropsmallar">
    <form [formGroup]="form" (ngSubmit)="onCreateClicked()">
      <div class="p-fluid p-grid">
        <div class="p-field p-col-6">
          <label for="templateName">
            Namn 
            <span><i pButton icon="pi pi-info-circle" class="p-button-rounded p-button-text" style="top: 8px"
              pTooltip="Titel på mallen"></i>
            </span>
          </label>
          <input id="templateName" formControlName="templateName" type="text"
            [disableControl]="processing" pInputText>
        </div>
      </div>
    <p-dropdown 
        [options]="customers"
        optionLabel="organizationName"
        [(ngModel)]="selectedOrganizationID"
        (onChange)="onChange($event)"
        optionValue="organizationID"
        placeholder="Välj en organisation"
        [showClear]="true"
        [filter]="true">
    </p-dropdown>
    <p-editor formControlName="template" [style]="{'height':'320px'}">
        <p-header>
          <span class="ql-formats">
            <select class="ql-size">
              <option value="small">Petit</option>
              <option selected></option>
              <option value="large">Sous-titre</option>
              <option value="huge">Titre</option>
            </select>
          </span>
          <span class="ql-formats">
            <button class="ql-bold" aria-label="Bold"></button>
            <button class="ql-italic" aria-label="Italic"></button>
            <button class="ql-underline" aria-label="Underline"></button>
            <button class="ql-strike" aria-label="Strike"></button>
          </span>
          <span class="ql-formats">
            <select title="Text Color" class="ql-color" defaultValue="rgb(0, 0, 0)">
              <option value="rgb(0, 0, 0)" label="rgb(0, 0, 0)"></option>
              <option value="rgb(61, 20, 102)" label="rgb(61, 20, 102)"></option>
            </select>
            <span class="ql-format-separator"></span>
            <select title="Background Color" class="ql-background" defaultValue="rgb(255, 255, 255)">
              <option value="rgb(0, 0, 0)" label="rgb(0, 0, 0)"></option>
              <option value="rgb(61, 20, 102)" label="rgb(61, 20, 102)"></option>
            </select>
          </span>
          <span class="ql-formats">
            <button class="ql-list" title="List"></button>
            <button class="ql-bullet" title="Bullet"></button>
            <select title="Text Alignment" class="ql-align">
              <option selected>Gauche</option>
              <option value="center" label="Center"></option>
              <option value="right" label="Right"></option>
              <option value="justify" label="Justify"></option>
            </select>
          </span>
          <span class="ql-formats">
            <button aria-label="Link" class="ql-link"></button>
            <button aria-label="Image" class="ql-image"></button>
          </span>
        </p-header>
      </p-editor>

      <div class="p-mt-2">
      </div>
      <div class="p-grid p-mt-4">
        <div class="p-col-6">
          <button pButton type="button" icon="pi pi-chevron-left"
              class="p-button-secondary p-button-raised p-text-uppercase" label="Tillbaka"
              [disabled]="processing" (click)="onShowBrokerCalloffs()"></button>
        </div>
        <div class="p-col-6 p-text-right">
          <button pButton type="submit" iconPos="right"
            class="p-button p-button-raised p-text-uppercase" label="Spara" [disabled]="processing"></button>
        </div>
      </div>
      <div class="p-mt-4" [hidden]="!processing">
        <p-progressBar mode="indeterminate"></p-progressBar>
      </div>
    </form>
  </p-card>
</div>
</div>
</div>
