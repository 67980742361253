import { Component, OnInit } from '@angular/core';
import { ConsoleLogger } from '@microsoft/signalr/dist/esm/Utils';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { NotificationReceiverDTO, Notification  } from 'src/app/models/notification/notification.model';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-shared-resume-email',
  templateUrl: './shared-resume-email.component.html',
  styles: [
  ]
})
export class SharedResumeEmailComponent implements OnInit {
  email: string;
  email2: string;
  receiver: NotificationReceiverDTO[] = [];
  test: string[] = [];


  constructor(
    public config: DynamicDialogConfig,
    private notificationService: NotificationService,
    private modalRef: DynamicDialogRef,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.email = "jonathan.sundqvist@sprinta.se";
    this.email2 = "jonathan.sundqvist89@gmail.com";
    console.log(this.config.data);

    // this.receiver.push({notificationReceiverEmail : this.email, notificationReceiverLastName:"", notificationReceiverFirstName: ""})
    // this.receiver.push({notificationReceiverEmail : this.email2, notificationReceiverLastName:"", notificationReceiverFirstName: ""})

  }

  cancel() {
    this.modalRef.close(false);
  }

  create() {

    this.test.forEach(element => {
      this.receiver.push({notificationReceiverEmail: element, notificationReceiverFirstName:"", notificationReceiverLastName:""});
    })

    console.log(this.test);
    console.log("submit");
    var notification: Notification = {
      notificationSubject: 'CV från Swedish Consulting Group BRM har delats med dig',
      notificationContent: `Följ länk för att se CV: <a href=${this.config.data.sharedResume.linkUrl}>Länk</a>`,
      notificationData: {
        notificationContent : "testContent"
      },
      notificationReceivers: this.receiver,
    }

    this.notificationService.postNotification(notification).subscribe(res => {
      console.log(res);
      this.modalRef.close(true);
      this.showCreatedToast();

      //Add notification receivers to Delningshistorik or store the NotificationID/Receivers FKs in a table that reflects the sharing history
    })
  }


  showCreatedToast() {
    this.messageService.add({
      key:'main',
      severity: 'success',
      detail: 'CV har delats'
    })
  }

}
