import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../../../services/auth.service';
import { DialogService } from 'primeng/dynamicdialog';
import { ErrorModalComponent } from 'src/app/components/shared/error-modal/error-modal/error-modal.component';

imports: [
  CommonModule,
]

@Component({
  selector: 'app-create-supplier-tender-redirect',
  template: ''
})
export class CreateSupplierTenderRedirectComponent implements OnInit {

  assignmentID: number | null = null;
  brokerCalloffID: number | null = null;
  redirectTo = '/supplier-tender-create';

  constructor(private authService: AuthService, private router: Router, private route: ActivatedRoute, private dialogService: DialogService) { }

  ngOnInit(): void {
    // Get the assignmentID parameter from the URL
    this.route.queryParams.subscribe(params => {
      const assignmentID = params['assignmentID'];
      const brokerCalloffID = params['brokerCalloffID'];

      if (assignmentID && !isNaN(assignmentID) && brokerCalloffID && !isNaN(brokerCalloffID)) {

        this.assignmentID = Number(assignmentID);
        this.brokerCalloffID = Number(brokerCalloffID);

        if (this.authService.isAuthenticated() && this.authService.checkIfUserHasSupplierAccess()) {
          let isCustomerSupplier = false;
          if (this.authService.checkIfUserIsCustomerSupplierAndSetOrgType()) {
            isCustomerSupplier = true;
          }
          this.router.navigate([this.redirectTo, this.assignmentID, this.brokerCalloffID], { queryParams: { refresh: isCustomerSupplier } });
        }
        else {
          this.router.navigate(['/login'], { queryParams: { redirect: true, assignmentID: this.assignmentID, brokerCalloffID: this.brokerCalloffID, redirectTo: this.redirectTo } });
        }

      } else {
        this.dialogService.open(ErrorModalComponent, { data: { message: 'Ogiltigt assignmentID eller brokerCalloffID' } });
        this.router.navigate(['/login']);
      }
    });
  }
}
