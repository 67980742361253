import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SharedResume } from 'src/app/models/resume/resume.model';
import { FileService } from 'src/app/services/file-service';
import { ResumeService } from 'src/app/services/resume.service';

@Component({
  selector: 'app-shared-resume-page',
  templateUrl: './shared-resume-page.component.html',
  styles: [
  ]
})
export class SharedResumePageComponent implements OnInit {
tokenHash = '';
fileId = '';
sharedResumeId = 0;
pdfSrc: Blob | null;
loaded = false;
sharedResume: SharedResume
  constructor(
    private route: ActivatedRoute,
    private fileService: FileService,
    private resumeService: ResumeService
  ) { }

  ngOnInit(): void {
    this.tokenHash = String(this.route.snapshot.paramMap.get('token'));
    this.fileId = String(this.route.snapshot.paramMap.get('fileId'));
    this.sharedResumeId = Number(this.route.snapshot.paramMap.get('sharedResumeId'));
    this.getFile();
    this.getSharedResume();
  }

  getSharedResume() {
    console.log(this.sharedResumeId);
    this.resumeService.getSharedResume(this.sharedResumeId).subscribe(res => {
      console.log(res);
      this.loaded = true;
      this.sharedResume = res;
    })
  }

  getFile() {
    this.pdfSrc = null;
    this.fileService.getFileByIdWithToken(this.fileId, this.tokenHash).subscribe(res => {
      this.pdfSrc = res;
    })
  }

}
